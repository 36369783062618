

<div>
  <div class="col-md-12">
    <span *ngIf="houses && houses.length > 0" class="badge badge-secondary mb-4 f-14 width">Liste des biens</span>
    <div class="row" *ngIf="view">
      <div class="col-lg-4 col-md-4" *ngFor="let item of houses">
        <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
          <div class="app-card-header">
            <div *ngIf="!item.isMandat" class="row align-items-end">
              <div class="col text-left pb-3"></div>
              <div class="col text-right pb-3">
                <span class="badge badge-light-{{item?.isMandat ? 'warning' : 'danger'}} ml-1"><small>{{item?.isMandat ? 'Mandat en cours' : 'Sans mandat'}}</small> </span>
              </div>
            </div>
            <div class="cover-img-block">
              <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'" alt="{{ item?.nom }}"
               title="{{ item?.nom }}" class="img-fluid">
            </div>
          </div>
          <div class="text-center">
            <div class="row align-items-end">
              <div class="col text-left pb-3">
                <span class="badge badge-light-{{ item.disponible === 'LOCATION' ? 'info' : 'secondary' }}">
                  {{ item?.disponible === 'LOCATION' ? 'En location' : 'En vente' }}
                </span>
              </div>
              <div class="col text-right pb-3">
                <span *ngIf="item?.disponible === 'VENTE'" class="badge" [ngClass]="{
                  'badge-success' : item?.etat === 'DISPONIBLE',
                  'badge-warning' : item?.etat === 'RESERVE',
                  'badge-danger' : item?.etat === 'VENDU'
                  }">
                  {{ item?.etat }}
                </span>
              </div>
            </div>
            <p *ngIf="item?.folderCustomer?.modalite === 'ECHEANCE'">
              <ngb-progressbar type="primary" [showValue]="true" [value]="item?.folderCustomer?.prcEcheancier"></ngb-progressbar>
            </p>
            <p *ngIf="item?.folderCustomer?.modalite === 'AVANCEMENT'">
              <ngb-progressbar type="primary" [showValue]="true" [value]="item?.folderCustomer?.prcEtatA"></ngb-progressbar>
            </p>
            <h5 *ngIf="item?.disponible === 'VENTE'" class="mb-1 mt-3">
              <small class="text-muted sz-10 font-weight-bold" *ngIf="item?.etat !== 'DISPONIBLE'">
                {{ item?.etat === 'VENDU' ? 'Acquereur' : 'Réservé par' }}: {{ item?.acquereur }}
              </small>
            </h5>
            <h5 class="mb-1 mt-3">{{ item?.nom }}</h5>
            <h6 *ngIf="proprietaire" class="mb-1 mt-3">
              Bailleur: {{ item?.owner?.searchableTitle }}
            </h6>
            <h6 class="mb-1 mt-3">
              Situé: {{ item?.quartier + ' - ' + item?.commune+ ' - ' + item?.ville }}
            </h6>
          </div>
          <div class="row align-items-end mb-2">
            <div class="col text-right">
              <p *ngIf="item?.disponible === 'VENTE'" class="mb-1 text-muted text-warning">
                Valeur: {{ !item.isMandat ? item?.valeur : item?.mandate?.valeur|number }} {{global.device}}
              </p>
              <p *ngIf="item?.disponible === 'LOCATION'" class="mb-1 text-muted text-warning">
                Type: {{ item?.type}}
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col text-left">
              <p class="mb-0">{{ item?.code }}</p>
            </div>
          </div>
          <div class="row align-content">
            <div class="col text-right">
                <button (click)="showHouse(item)" type="button" class="btn btn-icon btn-secondary"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
              <ng-template ngxPermissionsOnly="HOUSE:EDIT">
                <button *ngIf="item?.disponible === 'LOCATION' || (item?.disponible === 'VENTE' && item?.etat === 'DISPONIBLE')" (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
              </ng-template>
              <ng-template ngxPermissionsOnly="HOUSE:PRINTER:SHOW">
                <button (click)="printerHouse(item)" type="button" class="btn btn-icon btn-warning ml-1"
                  ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
              </ng-template>
              <ng-template ngxPermissionsOnly="HOUSE:DELETE">
                <button *ngIf="item?.isDelete === true && item?.disponible === 'LOCATION' || (item?.disponible === 'VENTE' && item?.etat === 'DISPONIBLE')" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </ng-template>
            </div>
          </div>
        </app-card>
      </div>
    </div>
    <div *ngIf="!view">
      <app-card [hidHeader]="true" cardClass="card-datatable" *ngIf="houses && houses.length > 0">
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th>Bien</th>
                <th> Montant </th>
                <th> Type </th>
                <th>État</th>
                <th>Crée le</th>
                <th >Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of houses">
                <td class="align-middle">
                    <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'" alt="{{ item?.libelle }}"
                    title="{{ item?.libelle }}" onerror="this.onerror=null; this.src='assets/images/house-default.png'" class="rounded mr-2 ml-0" height="48"
                  >
                  
                  <p class="m-0 d-inline-block align-middle font-16">
                    <span class="mb-1 mt-3">{{ item?.nom }}</span> <br/>
                    <span *ngIf="proprietaire" class="mb-1 mt-3"><br/>
                      Bailleur: {{ item?.owner?.searchableTitle }}
                    </span><br/>
                    <span class="mb-1 mt-3">
                      Situé: {{ item?.quartier + ' - ' + item?.commune+ ' - ' + item?.ville }}
                    </span>
                  </p>
                
                </td>
                <td>  <span class=" text-warning"> {{ item?.valeur | number}} {{global.device}}
                  </span></td>
                  <td >
                    <span class="badge badge-light-{{ item.disponible === 'LOCATION' ? 'info' : 'secondary' }}">
                      {{ item?.disponible === 'LOCATION' ? 'En location' : 'En vente' }}
                    </span>
                  </td>
                <td>
                  <span *ngIf="item?.disponible === 'VENTE'" class="badge" [ngClass]="{
                    'badge-success' : item?.etat === 'DISPONIBLE',
                    'badge-warning' : item?.etat === 'RESERVE',
                    'badge-danger' : item?.etat === 'VENDU'
                    }">
                    {{ item?.etat }}
                  </span>
                </td>
                <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
              
              
                <td class="table-action">
                  <button (click)="showHouse(item)" type="button" class="btn btn-icon btn-secondary"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
              <ng-template ngxPermissionsOnly="HOUSE:EDIT">
                <button *ngIf="item?.disponible === 'LOCATION' || (item?.disponible === 'VENTE' && item?.etat === 'DISPONIBLE')" (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
              </ng-template>
              <ng-template ngxPermissionsOnly="HOUSE:PRINTER:SHOW">
                <button (click)="printerHouse(item)" type="button" class="btn btn-icon btn-warning ml-1"
                  ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
              </ng-template>
              <ng-template ngxPermissionsOnly="HOUSE:DELETE">
                <button *ngIf="item?.isDelete === true && item?.disponible === 'LOCATION' || (item?.disponible === 'VENTE' && item?.etat === 'DISPONIBLE')" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </ng-template>
                </td>
              </tr>
            </tbody>
         
          </table>
        </div>
      </app-card>
    </div>
  </div>
</div>

