<div class="modal-header">
  <h5 class="modal-title">{{title }}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence : </span>
        <span class="title-result-show">{{ folder?.code }}</span>
      </p>
      <p>
        <span class="title-show">Client : </span>
        <span class="title-result-show">{{ folder?.customer?.nom }}</span>
      </p>
      <p>
        <span class="title-show">Chargé d'affaire : </span>
        <span class="title-result-show">
          <span class="badge badge">{{ folder?.charge?.nom }}</span>
        </span>
      </p>
      <p>
        <span class="title-show">Date de création : </span>
        <span class="title-result-show">{{ folder?.date|date: "d MMMM y" : '' : 'fr-FR' }}</span>
      </p>
    </div>
    <!-- COL MILIEU -->
    <div class="flex-fill">
      <p>
        <span class="title-show">État : </span>
        <span class="title-result-show">
          <span class="badge badge-{{folder?.etat === 'VALIDE' ? 'success' : 'danger'}}"> {{ validation(folder?.etat) }} </span>
        </span>
      </p>
      <p>
        <span class="title-show">Montant : </span>
        <span class="title-result-show text-warning">{{ folder?.montant |number }} {{ global.device }}</span>
      </p>
      <p>
        <span class="title-show">Avance : </span>
        <span class="title-result-show text-warning">{{ folder?.montantAvance |number }} {{ global.device }}</span>
      </p>
      <p>
        <span class="title-show">Frais de dossier : </span>
        <span class="title-result-show text-warning">{{ folder?.montantFrais |number }} {{ global.device }}</span>
      </p>
      <p>
        <span class="title-show">Caution : </span>
        <span class="title-result-show text-warning">{{ folder?.caution |number }} {{ global.device }}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Motif : </span>
        <span class="title-result-show">
          <span class="badge badge">{{ folder?.motif == 'PROPRIÉTAIRE' ? 'Bien en vente': folder?.motif}}</span>
        </span>
      </p>
      <p>
        <span class="title-show">Modalité : </span>
        <span class="title-result-show">{{ folder?.modalite }}</span>
      </p>
      <p *ngIf="folder?.modalite === 'ECHEANCE'" >
        <span class="title-show">Nombre de mois : </span>
        <span class="title-result-show">{{ folder?.nbrMois }}</span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{ folder?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ folder?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ folder?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ folder?.update }}</span>
      </p>
    </div>
  </div>

  <div class="row">
    <!-- TABLEAU DES LOTS ET ILOTS -->
    <div class="col-md-12">
      <div class="row mb-2">
        <span class="badge badge-secondary my-2 mr-1 f-14 formBadge width">
          Détails des lots et ilots
        </span>
      </div>
      <div class="row mr-1">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th>Lot / Ilot</th>
                <th>Prix U.</th>
                <th>Qte</th>
                <th>Tva</th>
                <th>Remise</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of folder?.houses">
                <td>{{item?.libelle -  item?.label}}</td>
                <td>{{item?.prix | number }} {{global.device}}</td>
                <td>{{item?.qte }}</td>
                <td>{{item?.tva }} %</td>
                <td>{{item?.remise }}</td>
                <td>{{item.total | number}} {{global.device}} </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" class="font-weight-bold font-size-40 text-right">Total</td>
                <td class="font-weight-bold font-size-40 text-danger"> {{ folder?.montantBien | number}} {{global.device}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="folder?.options.length > 0">
    <!-- TABLEAU DES SUPPLEMENTS -->
    <div class="col-md-12">
      <div class="row mb-2">
        <span class="badge badge-secondary my-2 mr-1 f-14 formBadge width">
          Détails sur le supplément
        </span>
      </div>
      <div class="row mr-1">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th>Désignation</th>
                <th>Prix u.</th>
                <th>Qte</th>
                <th>Tva</th>
                <th>Remise</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of folder?.options" >
                <td><p class="m-0 d-inline-block align-middle font-16">{{item?.libelle}}</p></td>
                <td>
                  <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.prix|number}} {{global.device}}</span></p>
                </td>
                <td>{{item?.qte}}</td>
                <td>{{item?.tva}} %</td>
                <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.remise|number}} {{global.device}}</span></td>
                <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{(item?.total)|number}} {{global.device}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="folder?.echeances.length > 0">
    <!-- TABLEAU DES ECHEANCIERS -->
    <div class="col-md-12">
      <div class="row mb-2">
        <span class="badge badge-secondary my-2 mr-1 f-14 formBadge width">
          Détails des échéanciers
        </span>
      </div>
      <div class="row mr-1">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th>Description</th>
                <th>Montant</th>
                <th>État</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of folder?.echeances" >
                <td>{{item?.description }}</td>
                <td>{{item.montant | number}} {{global.device}} </td>
                <td> {{item.etat}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="folder?.advances.length > 0">
    <!-- TABLEAU DES AVANCES -->
    <div class="col-md-12">
      <div class="row mb-2">
        <span class="badge badge-secondary my-2 mr-1 f-14 formBadge width">
          Détails des avancements
        </span>
      </div>
      <div class="row mr-1">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th>Libellé</th>
                <th>Pourcentage (%)</th>
                <th>État</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of folder?.advances" >
                <td>{{item?.libelle }}</td>
                <td>{{item.prc}} %</td>
                <td>{{item.etat}}</td>
                <td>{{item.montant | number}} {{global.device}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <table class="table table-responsive  invoice-table invoice-total">
        <tbody>
          <tr>
            <th>Total ht : </th>
            <td> {{ folder?.montantHt | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>Total remise : </th>
            <td> {{ folder?.remise | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>Tatal tva : </th>
            <td> {{ folder?.montantTva | number }} {{global.device}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <table class="mb-2 width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">Total</td>
        <td class="font-weight-bold font-size-default text-right">{{ folder?.montant | number }} {{global.device}}</td>
      </tr>
    </tfoot>
  </table>

  <div class="row mb-2">
    <div class="col-md">
      <span class="badge badge-secondary mb-2 f-14 width">Fiche de dossier</span>
      <div class="row">
        <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
          <app-folder-uploader (click)="showFile(folder?.folder)" [maxSize]="3"
            [folder]="folder?.folder"
            [etat]="'show'"
            [allowFileUpload]="false" [allowDownload]="true">
          </app-folder-uploader>
        </div>
        <div [ngClass]="file ? 'col-md-8' : ''"  *ngIf="file" >
          <div class="row">
            <div class="col-sm-12 mb-2">
              <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                <i class="fa fa-arrow-alt-circle-left"></i> Fermer
              </button>
            </div>
          </div>
          <ngx-doc-viewer
            [url]="file"
            viewer="url" style="width:100%;height: 64vh;">
          </ngx-doc-viewer>

        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <button *ngIf="folder?.etat === 'INVALIDE'" (click)="editFolder(folder)" class="btn btn-primary">
    Modifier <i class="feather icon-edit-2"></i>
  </button>
  <button *ngIf="folder?.etat === 'VALIDE'" (click)="printerFolder(folder)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>

  <button *ngIf="validate && folder?.etat === 'INVALIDE'" (click)="activateFolder(folder)"  class="btn btn-success">
    Valider <i class="fas fa-check"></i>
  </button>
</div>
