 import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import {ChartModule} from 'angular2-chartjs';
import { CommonModule } from '@angular/common';
import { SharedModule } from "@theme/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashCrmComponent } from './dash-crm/dash-crm.component';
import {AngularHighchartsChartModule} from 'angular-highcharts-chart';
import { DashboardRoutingModule } from '@dashboard/dashboard-routing.module';
import { DashDefaultComponent } from '@dashboard/dash-default/dash-default.component';
import { DashProspectionComponent } from './dash-prospection/dash-prospection.component';
import { DashLocataireComponent } from '@dashboard/dash-locataire/dash-locataire.component';
import { DashPromotionComponent } from '@dashboard/dash-promotion/dash-promotion.component';
import { DashLotissementComponent } from '@dashboard/dash-lotissement/dash-lotissement.component';
import { DashProprietaireComponent } from '@dashboard/dash-proprietaire/dash-proprietaire.component';
import { DashComponent } from './dash/dash.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { DashCrmVenteComponent } from './dash-crm-vente/dash-crm-vente.component';
import { DashCrmLocationComponent } from './dash-crm-location/dash-crm-location.component';
import { DashCrmEncaissementComponent } from './dash-crm-encaissement/dash-crm-encaissement.component';
import { DashCrmActionComponent } from './dash-crm-action/dash-crm-action.component';


@NgModule({
  declarations: [
    DashCrmComponent,
    DashDefaultComponent,
    DashLocataireComponent,
    DashPromotionComponent,
    DashLotissementComponent,
    DashProspectionComponent,
    DashProprietaireComponent,
    DashComponent,
    DashCrmVenteComponent,
    DashCrmLocationComponent,
    DashCrmEncaissementComponent,
    DashCrmActionComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DashboardRoutingModule,
    SharedModule,
    ChartModule,
    NgbAccordionModule,
    AngularHighchartsChartModule,
  ],
  providers: [ { provide: LOCALE_ID, useValue: "fr-FR" }],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class DashboardModule { }

