import { House } from '@model/house';
import { Rental } from '@model/rental';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { HouseService } from '@service/house/house.service';
import { FilterService } from '@service/filter/filter.service';
import { RentalService } from '@service/rental/rental.service';
import { OnBoardingService } from '@theme/utils/on-boarding.service';
import { ValidationService } from '@service/validation/validation.service';
import { HouseAddComponent } from '@proprietaire/house/house-add/house-add.component';
import { ImportationComponent } from '@agence/modal/importation/Importation.component';
import { RentalAddComponent } from '@proprietaire/rental/rental-add/rental-add.component';
import { ValidationAddComponent } from '@validation/validation-add/validation-add.component';
@Component({
  selector: 'app-house-page',
  templateUrl: './house-page.component.html',
  styleUrls: ['./house-page.component.scss']
})
export class HousePageComponent implements OnInit {
  proprietaire: boolean = true;
  bien: boolean = false;
  min: boolean = false;
  max: boolean = false;
  filter: any;
  houses: House[];
  rentals: Rental[];
  visible: boolean = false;
  userSession = Globals.user
  publicUrl = environment.publicUrl;
  global = {country: Globals.country, device: Globals.device};
  type: string = 'BIEN';
  etatRow = [
    { label: 'Aucun mandat', value: 'AUCUN' },
  ];
  typeRow = [
    { label: 'Bien', value: 'BIEN' },
    { label: 'Locative', value: 'LOCATIVE' },
  ];
  categorieRow = [
    { label: 'Particulier', value: 'PARTICULIER' },
    { label: 'Entreprise', value: 'ENTREPRISE' }
  ];
  nameTitle: string = "Nom / Raison sociale"
  userTitle: string = "Crée par"
  bienTitle: string = "Bailleur"
  minTitle: string = "Montant MIN"
  maxTitle: string = "Montant MAX"
  categorieTitle: string = "Type de bailleur"
  etatTitle: string = "Mandats"
  autorisation: any = Globals.autorisation;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 8000,
    timerProgressBar: true
  })
  etat: boolean = true

  view: boolean = false
  dtOptions: any = {};

  constructor(
    public router: Router,
    private modalService: NgbModal,
    private houseService: HouseService,
    public boarding: OnBoardingService,
    private filterService: FilterService,
    private rentalService: RentalService,
    private validationService: ValidationService,
    private permissionsService: NgxPermissionsService
  ) {
    this.houseService.getList().subscribe(res => {
      return this.houses = res;
    }, error => { });
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
  }

  ngAfterViewInit(): void {
  }

  onFilter($event) {
    this.filterService.type = this.type;
    this.filter = null
    this.houses = []
    this.rentals = []
    this.filterService.search($event, 'owner', null).subscribe(
      res => {
        this.filter = this.filterService.filter
        if (this.type === 'BIEN') {
          this.houses = res;
          return this.houses;
        } else if (this.type === 'LOCATIVE') {
          this.rentals = res;
          return this.rentals;
        }
      }, err => { })
  }
  onChangeLoad($event) {
    this.type = $event
    if ($event === 'BIEN') {
      this.bien = true;
      this.min = false;
      this.max = false;
      this.bienTitle = "Nom du bien"
      this.nameTitle = "Bailleur"
      this.categorieTitle = 'Type de bien'
      this.etatRow = [
        { label: 'Disponible', value: 'DISPONIBLE' },
        { label: 'Vendu', value: 'VENDU' }
      ];
      this.categorieRow = [
        { label: 'En location', value: 'LOCATION' },
        { label: 'Een vente', value: 'VENTE' }
      ];
      this.visible = false;
      this.houseService.getList().subscribe(res => { return this.houses = res; }, error => { });
      this.etatTitle = "Disponibilité ?"
    } else if ($event === 'LOCATIVE') {
      this.bien = true;
      this.min = true;
      this.max = true;
      this.bienTitle = "Nom du bien"
      this.nameTitle = "Bailleur"
      this.categorieTitle = 'Type de locative'
      this.categorieRow = [
        { label: 'Studio', value: 'STUDIO' },
        { label: 'Appartement', value: 'APPARTEMENT' },
        { label: 'Palier', value: 'PALIER' },
        { label: 'Villa', value: 'VILLA' },
        { label: 'Magasin', value: 'MAGASIN' },
        { label: 'Bureau', value: 'BUREAU' },
        { label: 'Surface', value: 'SURFACE' },
        { label: 'Restaurant', value: 'RESTAURANT' },
        { label: 'Hall', value: 'HALL' },
        { label: 'Salle de conference', value: 'SALLE CONFERENCE' },
        { label: 'Parking', value: 'PARKING' }
      ];
      this.etatRow = [
        { label: 'Disponible', value: 'DISPONIBLE' },
        { label: 'Reservé', value: 'RESERVE' },
        { label: 'Occupé', value: 'OCCUPE' }
      ];
      this.visible = false;
      this.rentalService.getList().subscribe(res => { return this.rentals = res; }, error => { });
    }
  }
  onPrinter() {
    if (this.type === 'BIEN') {
      this.houseService.getPrinter('LISTE', this.userSession?.agencyKey, this.userSession?.uuid, this.filter);
    } else if (this.type === 'LOCATIVE') {
      this.rentalService.getPrinter('LISTE', this.userSession?.agencyKey, this.userSession?.uuid, this.filter);
    }
  }
  onModel() {
    if (this.type === 'BIEN') {
      this.houseService.getGenerer();
    } else if (this.type === 'LOCATIVE') {
      this.rentalService.getGenerer();
    }
  }
  onExport() {
    if (this.type === 'BIEN') {
      this.houseService.getExport(this.userSession?.agencyKey, this.userSession?.uuid, this.filter);
    } else if (this.type === 'LOCATIVE') {
      this.rentalService.getExport(this.userSession?.agencyKey, this.userSession?.uuid, this.filter);
    }
  }
  onImport() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ImportationComponent);
    modalRef.componentInstance.type = this.type;
  }
  addHouse(type) {
    this.modalService.dismissAll();
    this.houseService.edit = false;
    this.houseService.house = null;
    this.houseService.disponible = type;
    this.modal(HouseAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  addRental() {
    this.modalService.dismissAll();
    this.rentalService.edit = false;
    this.rentalService.rental = null;
    this.modal(RentalAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  addValidateur() {
    this.modalService.dismissAll();
    this.validationService.type = 'HOUSE';
    this.modal(ValidationAddComponent, 'modal-basic-title', 'lg', true, 'static');
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  onChangeView() {
    this.view = !this.view
  }
}
