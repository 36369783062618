<div class="row justify-content-center">  
  <div class="col-md-12 text-center mb-4">  
      <h2 class="badge badge-primary my-2 f-14 formBadge width">Locations</h2>  
  </div>  
</div>  
<div class="row">  
  <div class="col-md-7">  
    <div class="row">  
      <!-- CHIFFRE AFFAITRE  -->  
      <div class="col-md-6">  
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">  
          <div class="row align-items-center">  
            <div class="col-8">  
              <h6 class="text-muted m-b-0">Nombre total biens</h6>  
            </div>  
            <div class="col-4 text-right">  
              <i class="fas fa-home f-28"></i> 
            </div>  
          </div>  
          <div class="app-card-footer">  
            <div class="row align-items-center">  
              <div class="col-12 text-right">  
                <p class="text-white m-b-0">{{widget?.house?.nbrH}}</p>  
              </div>  
            </div>  
          </div>  
        </app-card>  
      </div>  
      <!-- CHIFFRE AFFAITRE END -->  
      <!-- MAISON OCCUPE -->  
      <div class="col-md-6">  
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">  
          <div class="row align-items-center">  
            <div class="col-8">  
              <h6 class="text-muted m-b-0">Locatives occupées</h6>  
            </div>  
            <div class="col-4 text-right">  
              <i class="fas fa-home f-28"></i> 
            </div>  
          </div>  
          <div class="app-card-footer">  
            <div class="row align-items-center">   
              <div class="col-md-12 text-right">  
                <p class="text-white m-b-0">{{widget?.locative?.occup}}</p>  
              </div>  
            </div>  
          </div>  
        </app-card>  
      </div>  
      <!-- MAISON OCCUPE END -->  
      <!-- MAISON RESERVER -->  
      <div class="col-md-6">  
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">  
          <div class="row align-items-center">  
            <div class="col-8">  
              <h6 class="text-muted m-b-0">Locatives réservées</h6>  
            </div>  
            <div class="col-4 text-right">  
              <i class="fas fa-home f-28"></i> 
            </div>  
          </div>  
          <div class="app-card-footer">  
            <div class="row align-items-center">  
             
              <div class="col-12 text-right">  
                <p class="text-white m-b-0">{{widget?.locative?.reserve}}</p>  
              </div>  
            </div>  
          </div>  
        </app-card>  
      </div>  
      <!-- MAISON RESERVER END -->  
      <!-- MAISON DISPONIBLE -->  
      <div class="col-md-6">  
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">  
          <div class="row align-items-center">  
            <div class="col-8">  
              <h6 class="text-muted m-b-0">Locatives disponibles</h6>  
            </div>  
            <div class="col-4 text-right">  
              <i class="fas fa-home f-28"></i> 
            </div>  
          </div>  
          <div class="app-card-footer">  
            <div class="row align-items-center">  
             
              <div class="col-12 text-right">  
                <p class="text-white m-b-0">{{widget?.locative?.dispo}}</p>  
              </div>  
            </div>  
          </div>  
        </app-card>  
      </div>  
      <!-- MAISON DISPONIBLE END -->  
    </div>  
  </div>  
  <div class="col-md-5">  
      <app-card cardTitle="Diagramme circulaire de l'occupation des logements locatifs" [options]="false">  
        <div #doughnutChart style="width: 90%; height: 70%; overflow: hidden;">  
          <chart id="doughnut_chart" [type]="'doughnut'" [data]="pieChartData" style="height: 270px;"></chart>  
        </div>  
      </app-card>  
  </div>  
</div>