<div class="row justify-content-center">  
  <div class="col-md-12 text-center mb-4">  
      <h2 class="badge badge-primary my-2 f-14 formBadge width">Programmes</h2>  
  </div>  
</div>  

<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
          <div class="row align-items-center">
            <div class="col-8">
              <h6 class="text-muted m-b-0"> {{ widget?.promotion?.nbr > 1 ? 'Programmes' : 'Programme' }}</h6>
            </div>
            <div class="col-4 text-right">
              <i class="feather icon-home f-28"></i>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-12 text-right">
                <p class="text-white m-b-0">{{ widget?.promotion?.nbr }}</p>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <!-- MAISON OCCUPE -->
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
          <div class="row align-items-center">
            <div class="col-8">
              <h6 class="text-muted m-b-0">{{ widget?.houseV?.nbr > 1 ? 'Maisons occupées ' : 'Maison occuppée ' }}</h6>
            </div>
            <div class="col-4 text-right">
              <i class="feather icon-home f-28"></i>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-12 text-right">
                <p class="text-white m-b-0">{{ widget?.houseV?.nbr }}</p>
              </div>
            </div>
          
          </div>
        </app-card>
      </div>
      <!-- MAISON OCCUPE END -->
      <!-- MAISON RESERVER -->
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
          <div class="row align-items-center">
            <div class="col-8">
              <h6 class="text-muted m-b-0"> {{ widget?.houseR?.nbr > 1 ? 'Maisons réservées' : 'Maison réservée' }}
              </h6>
            </div>
            <div class="col-4 text-right">
              <i class="feather icon-home f-28"></i>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-12 text-right">
                <p class="text-white m-b-0">{{ widget?.houseR?.nbr}}</p>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <!-- MAISON RESERVER END -->
      <!-- MAISON DISPONIBLE -->
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
          <div class="row align-items-center">
            <div class="col-8">
              <h6 class="text-muted m-b-0">{{ widget?.houseD?.nbr > 1 ? 'Maisons disponibles ' : 'Maison disponible' }}
              </h6>
            </div>
            <div class="col-4 text-right">
              <i class="feather icon-home f-28"></i>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-12 text-right">
                <p class="text-white m-b-0">{{widget?.houseD?.nbr}}</p>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <!-- MAISON DISPONIBLE END -->
    </div>
  </div>
  <div class="col-md-6">
      <app-card cardTitle="Répartition de l’occupation des logements sous forme de camembert" [options]="false">
        <div class="pb-3" #doughnutChart style="width: 90%; height: 70%; overflow: hidden;">
          <chart id="doughnut_chart" [type]="'doughnut'" [data]="pieChartData" style="height: 270px;"></chart>
        </div>
      </app-card>
  </div>
</div>
<div class="row justify-content-center">  
  <div class="col-md-12 text-center mb-4">  
      <h2 class="badge badge-primary my-2 f-14 formBadge width">Projet de lotissements</h2>  
  </div>  
</div>  
<div class="row">
    <div class="col-md-7">
        <div class="row">
          <!-- CHIFFRE AFFAITRE  -->
          <div class="col-md-6">
            <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
              <div class="row align-items-center">
                <div class="col-8">
                  <h6 class="text-muted m-b-0"> {{ widget?.lot?.nbr > 1 ? 'Lotissements ' : 'Lotissement' }}</h6>
                </div>
                <div class="col-4 text-right">

                  <i class="feather icon-home f-28"></i>                </div>
              </div>
              <div class="app-card-footer">
                <div class="row align-items-center">
                  <div class="col-12 text-right">
                    <p class="text-white m-b-0">{{widget?.lot?.nbr}}</p>
                  </div>
                </div>
              </div>
            </app-card>
          </div>
          <!-- CHIFFRE AFFAITRE END -->
          <!-- MAISON OCCUPE -->
          <div class="col-md-6">
            <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
              <div class="row align-items-center">
                <div class="col-8">
                  <h6 class="text-muted m-b-0"> {{ widget?.lotV?.nbr > 1 ? 'Lots occupés ' : 'Lot occupé' }}</h6>
                </div>
                <div class="col-4 text-right">
                  <i class="feather icon-home f-28"></i>
                </div>
              </div>
              <div class="app-card-footer">
                <div class="row align-items-center">
                  <div class="col-12 text-right">
                    <p class="text-white m-b-0">{{widget?.lotV?.nbr}}</p>
                  </div>
                </div>
              </div>
            </app-card>
          </div>
          <!-- MAISON OCCUPE END -->
          <!-- MAISON RESERVER -->
          <div class="col-md-6">
            <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
              <div class="row align-items-center">
                <div class="col-8">
                  <h6 class="text-muted m-b-0"> {{ widget?.lotR?.nbr > 1 ? 'Lots réservés ' : 'Lot réservé' }}
                  </h6>
                </div>
                <div class="col-4 text-right">
                  <i class="feather icon-home f-28"></i>
                </div>
              </div>
              <div class="app-card-footer">
                <div class="row align-items-center">
               
                  <div class="col-md-12text-right">
                    <p class="text-white m-b-0">{{widget?.lotR?.nbr}}</p>
                  </div>
                </div>
              </div>
            </app-card>
          </div>
          <!-- MAISON RESERVER END -->
          <!-- MAISON DISPONIBLE -->
          <div class="col-md-6">
            <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
              <div class="row align-items-center">
                <div class="col-8">
                  <h6 class="text-muted m-b-0">{{ widget?.lotD?.nbr > 1 ? 'Lots disponibles  ' : 'Lot disponible' }}
                  </h6>
                </div>
                <div class="col-4 text-right">
                  <i class="feather icon-home f-28"></i>
                </div>
              </div>
              <div class="app-card-footer">
                <div class="row align-items-center">                 
                  <div class="col-12 text-right">
                    <p class="text-white m-b-0">{{widget?.lotD?.nbr}}</p>
                  </div>
                </div>
              </div>
            </app-card>
          </div>
          <!-- MAISON DISPONIBLE END -->
        </div>
    </div>
    <div class="col-md-5">
      <app-card cardTitle="Diagramme circulaire de l'occupation des lots" [options]="false">
        <div #doughnutChart style="width: 90%; height: 70%; overflow: hidden;">
          <chart id="doughnut_chart" [type]="'doughnut'" [data]="pieChartData1" style="height: 270px;"></chart>
        </div>
      </app-card>
    </div>
</div>
