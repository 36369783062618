
import { Owner } from '@model/owner';
import { House } from '@model/house';
import { Rental } from '@model/rental';
import { Mandate } from '@model/mandate';
import * as Highcharts from 'highcharts';
import { Repayment } from '@model/repayment';
import { environment } from '@env/environment';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import HC_drilldown from 'highcharts/modules/drilldown';
import { RendService } from '@service/rdv/rend.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnerService } from '@service/owner/owner.service';
import { HouseService } from '@service/house/house.service';
import { FilterService } from '@service/filter/filter.service';
import { RentalService } from '@service/rental/rental.service';
import { MandateService } from '@service/mandate/mandate.service';
import { UploaderService } from '@service/uploader/uploader.service';
import { ActivityService } from '@service/activity/activity.service';
import { RepaymentService } from '@service/repayment/repayment.service';
import { OwnerAddComponent } from '@proprietaire/owner/owner-add/owner-add.component';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Promotion } from '@model/promotion';
import { Subdivision } from '@model/subdivision';
import { PromotionService } from '@service/promotion/promotion.service';
import { SubdivisionService } from '@service/subdivision/subdivision.service';
import { PromotionAddComponent } from '@agence/promotion/promotion/promotion-add/promotion-add.component';
import { SubdivisionAddComponent } from '@agence/lotissement/subdivision/subdivision-add/subdivision-add.component';
HC_drilldown(Highcharts);

@Component({
  selector: 'app-owner-show',
  templateUrl: './owner-show.component.html',
  styleUrls: ['./owner-show.component.scss']
})
export class OwnerShowComponent implements OnInit {
  public activeTab: string = 'DASHBOARD';
  publicUrl = environment.publicUrl;
  userSession = Globals.user;
  global = {country: Globals.country, device: Globals.device};
  owner: Owner = null;
  houses: House[];
  rentals: Rental[];
  mandates: Mandate[];
  repayments: Repayment[];

  type: string = 'DASHBOARD';
  etatRow = [
    { label: 'Disponible', value: 'DISPONIBLE' },
    { label: 'Vendu', value: 'VENDU' }
  ];
  typeRow = [
    { label: 'Propriétaire', value: 'PROPRIETAIRE' },
    { label: 'Bien', value: 'BIEN' },
    { label: 'Locative', value: 'LOCATIVE' },
    { label: 'Mandat', value: 'MANDAT' },
    { label: 'Reversement', value: 'REVERSEMENT' }
  ];
  categorieRow = [
    { label: 'En location', value: 'LOCATION' },
    { label: 'En vente', value: 'VENTE' }
  ];
  userTitle: string = "Crée par"
  minTitle: string = "Montant MIN"
  maxTitle: string = "Montant MAX"
  categorieTitle: string = "Type de bien"
  etatTitle: string = "Disponibilité ?"
  file: any;
  rdvs = []
  notes = []
  dtOptions: any
  locatives: any[];

  public pieChartData: any;
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any

  autreTitle = "Locative";
  autre: boolean = true;
  autreType = 'ENTITY';
  autreClass= 'Rental';
  autreNamespace= 'Client';
  autreGroups= 'rental';

  bienTitle: string = "Nom du bien"
  bien: boolean = true
  bienType = 'ENTITY';
  bienClass= 'House';
  bienNamespace= 'Client';
  bienGroups= 'house';

  nameTitle: string = "Bailleur "
  name: boolean = true;
  nameType = 'TEXT';
  nameClass= 'Owner';
  nameNamespace= 'Client';
  nameGroups= 'owner';
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    autre: null,
    bien: null,
    ordre: "ASC",
    type: "DASHBOARD",
    uuid: null
  }
  widget: any
  pieRversementV = 0;
  pieRversementI = 0;
  pieRversementR = 0;

  mois = []
  rentI = []
  rentT = []
  rentP = []
  programmes: Promotion[] = []
  lotissements: Subdivision[] = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private rdvService: RendService,
    private uploader: UploaderService,
    private ownerService: OwnerService,
    private houseService: HouseService,
    private filterService: FilterService,
    private rentalService: RentalService,
    private mandateService: MandateService,
    private activityService: ActivityService,
    private promotionService: PromotionService,
    private repaymentService: RepaymentService,
    private subdivisionService: SubdivisionService,
    private permissionsService: NgxPermissionsService
  ) {
    this.owner = this.ownerService.getOwner();
    this.onChangeLoad(this.type);
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
    this.ownerService.dashboard(this.event, this.route.snapshot.params.id).subscribe((res: any) => {
      this.onSet(res)
      this.getPie();
      this.getGraph();
      this.locatives = res?.rental?.list;
      return this.widget = res;
    });
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    if (!this.owner) {
      this.ownerService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
        return this.owner = res;
      });
    }
    this.getPie()
    this.getGraph()
  }

  onSet(res){
    this.rentT = []
    this.rentP = []
    this.rentI = []
    this.mois = []
    if (res) {
      res?.facture?.forEach(el => {
        this.rentT.push(el?.total)
        this.rentP.push(el?.paye)
        this.rentI.push(el?.impaye)
        this.mois.push(el?.date)
      });
      this.pieRversementV = res?.reversement.v;
      this.pieRversementI = res?.reversement.i;
      this.pieRversementR = res?.reversement.r;
    }
    
  }

  onFilter($event) {
    this.houses = []
    this.rentals = []
    this.mandates = []
    this.repayments = []
    this.programmes = []
    this.lotissements = []
  
    $event.type = this.activeTab
      if (this.activeTab === 'DASHBOARD') {
        this.widget = null
        this.barBasicChartOptions = null
        this.pieChartData = null
        this.locatives = []
          this.ownerService.dashboard($event, this.route.snapshot.params.id).subscribe((res: any) => {
          this.onSet(res)
          this.getPie();
          this.getGraph();
          this.locatives = res?.rental?.list;
          return this.widget = res;
          });
      }else{
        this.filterService.search($event, 'owner', this.owner.uuid).subscribe(
          res => {
            if (this.activeTab === 'BIEN') {
              return this.houses = res;
            } else if (this.activeTab === 'LOCATIVE') {
              return this.rentals = res;
            } else if (this.activeTab === 'MANDAT') {
              return this.mandates = res;
            } else if (this.activeTab === 'REVERSEMENT') {
              return this.repayments = res;
            } else if (this.activeTab === 'DASHBOARD') {
              return this.repayments = res;
            } else if (this.activeTab === 'PROGRAMME') {
              return this.programmes = res;
            } else if (this.activeTab === 'LOTISSEMENT') {
              return this.lotissements = res;
            }
          }, err => { })
      }

     

  }
  onChangeLoad(type): void {
    this.activeTab = type;
    if (type === 'PROPRIETAIRE') {
      if (!this.owner) {
        this.ownerService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
          return this.owner = res;
        });
      }
    } else if (type === 'BIEN') {
      this.typeRow = [{ label: 'Biens', value: 'BIEN' }];
      this.name = false
      this.bien = true
      this.bienTitle = "Nom du bien"
      this.categorieRow = []
      this.houseService.getList(this.owner.uuid, null, null).subscribe((res) => {
        return this.houses = res;
      }, error => { }
      );
    } else if (type === 'LOCATIVE') {
      this.categorieTitle = 'Type de locative'
      this.nameTitle = 'Nom du bien'
      this.typeRow = [{ label: 'Locative', value: 'LOCATIVE' }];
      this.categorieRow = [
        { label: 'Studio', value: 'STUDIO' },
        { label: 'Appartement', value: 'APPARTEMENT' },
        { label: 'Palier', value: 'PALIER' },
        { label: 'Villa', value: 'VILLA' },
        { label: 'Magasin', value: 'MAGASIN' },
        { label: 'Bureau', value: 'BUREAU' },
        { label: 'Surface', value: 'SURFACE' },
        { label: 'Restaurant', value: 'RESTAURANT' },
        { label: 'Hall', value: 'HALL' },
        { label: 'Salle de conference', value: 'SALLE CONFERENCE' },
        { label: 'Parking', value: 'PARKING' }
      ];
      this.etatRow = [
        { label: 'Disponible', value: 'DISPONIBLE' },
        { label: 'Réservé', value: 'RESERVE' },
        { label: 'Vendu', value: 'VENDU' }
      ];
      this.rentalService.getList(this.owner.uuid).subscribe((res) => {
        return this.rentals = res;
      }, error => { }
      );
    } else if (type === 'PROGRAMME') {
      this.bienTitle= "Nom du programme"
      this.categorieTitle = 'Type de locative'
      this.nameTitle = 'Nom du programme'
      this.typeRow = [{ label: 'Programmes', value: 'PROGRAMME' }];
      this.categorieRow = [];
      this.etatRow = [];
      this.promotionService.getList(null, null, null, this.owner.uuid).subscribe((res) => {
        return this.programmes = res;
      }, error => { }
      );
    }  else if (type === 'LOTISSEMENT') {
      this.categorieTitle = 'Type de locative'
      this.nameTitle = 'Nom du projet de lotissement'
      this.bienTitle= "Nom du projet de lotissement"
      this.typeRow = [{ label: 'Projets de lotissements', value: 'LOTISSEMENT' }];
      this.categorieRow = [];
      this.etatRow = [];
      this.subdivisionService.getList(null, this.owner.uuid).subscribe((res) => {
        return this.lotissements = res;
      }, error => { }
      );
    } else if (type === 'MANDAT') {
      this.nameTitle = 'Nom du bien'
      this.typeRow = [{ label: 'Mandat', value: 'MANDAT' }];
      this.categorieRow = []
      this.mandateService.getList(this.owner.uuid).subscribe((res) => {
        return this.mandates = res;
      }, error => { }
      );
    } else if (type === 'REVERSEMENT') {
      this.typeRow = [{ label: 'Reversement', value: 'REVERSEMENT' }];
      this.categorieRow = []
      this.repaymentService.getList(this.owner.uuid, null, null).subscribe((res) => {
        return this.repayments = res;
      }, error => { }
      );
    } else if (type === 'NOTE_INTERNE'){
      this.activityService.getList(null, this.owner.uuid, null, null).subscribe((res: any) => {
        return this.notes = res
      })
      this.rdvService.getList(null, this.owner.uuid, null, null).subscribe((res: any) => {
        return this.rdvs = res
      })
    } else if (type === 'DASHBOARD'){

      this.name = false;
      this.nameTitle = 'Bailleur'
      this.nameType = 'ENTITY';
      this.nameClass= 'Owner';
      this.nameNamespace= 'Client';
      this.nameGroups= 'owner';

      this.bien = true;
      this.bienTitle = 'Nom du bien';
      this.bienType = 'ENTITY';
      this.bienClass= 'House';
      this.bienNamespace= 'Client';
      this.bienGroups= 'house';

      this.autreTitle = "Locative";
      this.autre = false;
      this.autreType = 'ENTITY';
      this.autreClass= 'Rental';
      this.autreNamespace= 'Client';
      this.autreGroups= 'rental';
    }
  }
  editOwner(row) {
    this.ownerService.setOwner(row);
    this.ownerService.edit = true;
    this.ownerService.type = row.type;
    this.modal(OwnerAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  printerOwner(row): void {
    this.ownerService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  editPromotion(row) {
    this.promotionService.setPromotion(row);
    this.promotionService.edit = true;
    this.modal(PromotionAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  showPromotion(row) {
    this.promotionService.setPromotion(row);
    this.router.navigate(['/admin/promotion/show/' + row.uuid]);
  }
  printerPromotion(row): void {
    this.promotionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  deletePromotion(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.promotionService.getDelete(item.uuid).subscribe(res => {
          if (res?.code === 200) {
            const index = this.programmes.findIndex(x => x.uuid === item.uuid);
            if (index !== -1) {
              this.programmes.splice(index, 1);
            }
            Swal.fire('', res?.message, res?.status);
          }
        }, error => {
          Swal.fire('', error.message, 'error');
        })
      }
    });
  }
  editSubdivision(row) {
    this.subdivisionService.setSubdivision(row)
    this.subdivisionService.edit = true
    this.modal(SubdivisionAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  showSubdivision(row) {
    this.subdivisionService.setSubdivision(row)
    this.router.navigate(['/admin/lotissement/show/' + row.uuid]);
  }

  printerSubdivision(row): void {
    this.subdivisionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  deleteSubdivision(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.subdivisionService.getDelete(item.uuid).subscribe(res => {
          if (res?.code === 200) {
            const index = this.lotissements.findIndex(x => x.uuid === item.uuid);
            if (index !== -1) {
              this.lotissements.splice(index, 1);
            }
            Swal.fire('', res?.message, res?.status);
          }
        }, error => {
          Swal.fire('', error.message, 'error');
        })
      }
    });
  }

  back() { this.router.navigate(['/admin/proprietaire']) }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) { }
      else {
        this.ownerService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.status === 'success') { this.router.navigate(['/admin/proprietaire']) }
        });
      }
    });
  }

  getGraph () {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['#0e9e4a', '#4680ff', '#ff5252'],
      title: {
        text: "État mensuel des paiements."
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
      {
        color: '#0e9e4a',
        name: 'Payés',
        data: this.rentP

      }, {
        color: '#4680ff',
        name: 'Total',
        data: this.rentT

      },{
        color: '#ff5252',
        name: 'Impayés',
        data: this.rentI

      }
    ]
    };
  }
 
  getPie(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChart.nativeElement as HTMLCanvasElement).children));
      this.pieChartTag = ((pieTag['doughnut_chart']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartData = {
        labels: ['Validé', 'Invalide', 'Réjeté'],
        datasets: [{
          data: [this.pieRversementV,this.pieRversementI,this.pieRversementR],
          backgroundColor: ['#4680ff', '#ff5252', '#ffa21d'],
          hoverBackgroundColor: ['#4680ff', '#ff5252', '#ffa21d']
        }]
      };
    });
  }
  resetData() {
    // this.data = this.data.slice(this.data.length - 10, this.data.length);
  }

  getNewSeries(baseval, yrange) {
    // const newDate = baseval + 86400000;
    // this.lastDate = newDate;
    // this.data.push({
    //   x: newDate,
    //   y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
    // });
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
