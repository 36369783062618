<div class="row justify-content-center">
    <!-- Filtre de recherche -->
    <div class="width btn-filter-construction">
        <ng-template
            [ngxPermissionsOnly]="['CONSTRUCTION:LIST', 'QUOTE:LIST', 'FUNDING:LIST', 'PRODUCTION:LIST', 'PAYMENT:FUNDING:LIST']">
            <app-search [type]="true" [typeRow]="typeRow" [inputs]="inputs" (typeEvent)="onChangeLoad($event)"
    (filterEvent)="onFilter($event)"></app-search>
        </ng-template>
    </div>
    <ng-template
        [ngxPermissionsExcept]="['CONSTRUCTION:LIST', 'QUOTE:LIST', 'FUNDING:LIST', 'PRODUCTION:LIST', 'PAYMENT:FUNDING:LIST']">
        <div class="col-md-12">
            <app-no-droit [title]="'intervention'"></app-no-droit>
        </div>
    </ng-template>

    <!-- BOUTONS DE GESTION -->
    <div class="col-sm-12 mb-4 btn-all-construction">
        <div class="first">
            <div class="second">
                <div class="btn-Intervention">
                    <div *ngxPermissionsOnly="['QUOTE:NEW']" class="btn-group m-1" ngbDropdown
                        ngbTooltip="Cliquez ici pour ajouter  Maintenance / Renovation / Entretien">
                        <button (click)="addConstruction('type','SYNDIC')" class="btn btn-primary"
                            type="button">
                            Créer une intervention <i class="fas fa-paint-roller"></i>
                        </button>
                    </div>
                </div>
                <div class="btn-Quote">
                    <div *ngxPermissionsOnly="['CONSTRUCTION:NEW']" class="btn-group m-1" ngbDropdown
                        ngbTooltip="Cliquez ici pour ajouter un devis">
                        <button (click)="addQuote('SYNDIC')" class="btn btn-primary" type="button">
                            Créer un  devis <i class="fas fa-clipboard"></i>
                        </button>
                    </div>
                </div>
                <div class="btn-InvoiceCo">
                    <button *ngxPermissionsOnly="['CONSTRUCTION:NEW']" (click)="addBon()" type="button"
                        class="btn btn-warning m-1" ngbTooltip="Cliquez ici pour ajouter un bon de commande">
                        Créer un  bon de commande <i class="fas fa-clipboard"></i>
                    </button>
                </div>
                <div class="btn-InvoiceCo">
                    <button *ngxPermissionsOnly="['CONSTRUCTION:NEW']" (click)="addInvoiceCo()" type="button"
                        class="btn btn-primary m-1" ngbTooltip="Cliquez ici pour ajouter une facture">
                        Créer une  facture <i class="fas fa-clipboard"></i>
                    </button>
                </div>
                <div class="btn-Funding">
                    <button *ngxPermissionsOnly="['NOTSHOW:NEW']" (click)="addFunding()" type="button"
                        class="btn btn-success m-1" ngbTooltip="Cliquez ici pour ajouter un financement">
                        Financement <i class="fas fa-wallet"></i>
                    </button>
                </div>
                <div class="btn-Realization" data-hint="" data-hintposition="top-right">
                    <button *ngxPermissionsOnly="['NOTSHOW:NEW']" (click)="addProduction()" type="button"
                        class="btn btn-warning m-1" ngbTooltip="Cliquez ici pour ajouter une realisation">
                        Réalisation <i class="feather icon-folder"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="btn-group float-right m-1 btn-outil-construction" ngbDropdown>
            <button class="btn btn-warning" ngbDropdownToggle type="button">
                Outils <i class="fas fa-tools"></i>
            </button>
            <div class="dropdown-menu-right" ngbDropdownMenu>
                <a *ngxPermissionsOnly="['CONSTRUCTION:PRINTER:LIST']" class="dropdown-item" (click)="onPrinter()"
                    ngbTooltip="Cliquez ici pour imprimer le listing">
                    Imprimer <i class="feather icon-printer"></i>
                </a>
                <a class="dropdown-item" (click)="onExport()" ngbTooltip="Cliquez ici pour exporter le listing">
                    Exporter <i class="fa fa-file-excel"></i>
                </a>
                <a class="dropdown-item" [hidden]="visible" (click)="onImport()"
                    ngbTooltip="Cliquez ici pour importer un fichier">
                    Importer <i class="feather icon-download"></i>
                </a>
                <a class="dropdown-item" [hidden]="visible" (click)="onModel()"
                    ngbTooltip="Cliquez ici pour génerer un modèle">
                    Génerer <i class="feather icon-upload"></i>
                </a>
            </div>
        </div>
    </div>

    <!-- Liste des interventions -->
    <div class="width list-construction">
        <ng-template ngxPermissionsOnly="CONSTRUCTION:LIST">
            <div class="col-md-12" *ngIf="type === 'INTERVENTION' && constructions && constructions.length === 0">
                <span class="badge badge-primary mb-4 f-14 width" ngbTooltip="(Maintenance / Renovation / Entretien)">
                    Liste des interventions
                </span>
                <app-no-data [title]="'Aucune intervention trouvée'"
                    *ngIf="type === 'INTERVENTION' && constructions && constructions.length === 0"></app-no-data>
            </div>
            <div class="col-md-12" *ngIf="type === 'INTERVENTION' && constructions && constructions.length > 0">
                <span class="badge badge-primary mb-4 f-14 width" ngbTooltip="(Maintenance / Renovation / Entretien)">
                    Liste des interventions </span>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="table-responsive">
                        <table datatable [dtOptions]="dtOptions"
                            class="table table-sm table-striped table-bordered nowrap table-hover">
                            <thead class="thead-light">
                                <tr>
                                    <th>Bien</th>
                                    <th>Libellé</th>
                                    <th>Evolution</th>
                                    <th>État</th>
                                    <th>Crée le</th>
                                    <th>Montant</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of constructions">
                                    <td class="align-middle">
                                        <p class="m-0 d-inline-block align-middle font-16">
                                            <span class="text-primary">{{item?.rental ? item?.rental?.libelle :
                                                item?.house?.searchableTitle}}</span>
                                            <span class="text-primary">{{item?.trustee ? item?.trustee?.nom :
                                                ''}}</span>
                                            <span *ngIf="item?.rental!=null || item?.house!=null">
                                                <br /> <span>
                                                    Superficie : {{item?.rental
                                                    ? item?.rental?.superficie : item?.house?.superficie}} m² -
                                                    {{item?.rental
                                                    ? item?.rental?.piece : item?.house?.nbrPiece}} pièce(s)
                                                </span>
                                            </span>
                                            <span *ngIf="item?.houseCo!=null">
                                                <br /> <span>Lot : {{item?.houseCo?.nom}}</span>
                                            </span>
                                            <span *ngIf="item?.homeCo!=null">
                                                <br /> <span>Villa ou appartement : {{item?.homeCo?.nom}}</span>
                                            </span>
                                            <span *ngIf="item?.infrastructure!=null">
                                                <br /> <span>Infrastructure : {{item?.infrastructure?.nom}}</span>
                                            </span>
                                            <span *ngIf="item?.nature!=null">
                                                <br /> <span>Nature : {{item?.nature?.libelle}}</span>
                                            </span>
                                            <span *ngIf="house!=null">
                                                <br /> <span>Bailleur
                                                    : {{item?.house?.owner?.searchableTitle}}</span>
                                            </span>
                                        </p>
                                    </td>
                                    <td class="align-middle">
                                        <p class="m-0 d-inline-block align-middle font-16">
                                            <span class="text-primary">{{item?.nom}}</span><br /> Durée:
                                            {{timelapse(item?.dateD, item?.dateF)}} <br /> Début: {{item?.dateD|date: "d
                                            MMMM y" : '' : 'fr-FR'}} - Fin: {{item?.dateF|date: "d MMMM y" : '' :
                                            'fr-FR'}}
                                        </p>
                                    </td>
                                    <td>
                                        <circle-progress [percent]="item?.pourcentage" [radius]="25" [toFixed]="1"
                                            [titleFontSize]="10" [outerStrokeWidth]="5" [innerStrokeWidth]="3"
                                            [outerStrokeColor]="item?.pourcentage >= 65 ? '#3FBD0D' : item?.pourcentage > 35 ? '#F0DD35' : '#EC0F0F'"
                                            [innerStrokeColor]="'#95cde4'" [animation]="true" [showSubtitle]="false"
                                            [animationDuration]="300"></circle-progress>
                                    </td>
                                    <td>
                                        <span class="badge" [ngClass]="{
                    'badge-danger' : item?.etat === 'STOPPER',
                    'badge-primary' : item?.etat === 'EN COURS',
                    'badge-warning' : item?.etat === 'PREVU',
                    'badge-success' : item?.etat === 'TERMINER'
                    }"> {{ item?.etat === 'EN COURS' ? 'En cous de realisation' : item?.etat }}
                                        </span>
                                    </td>
                                    <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
                                    <td>
                                        <p class="m-0 d-inline-block align-middle font-16">
                                            <span class="text-warning">{{item?.budget|number}} {{global.device}}</span>
                                        </p>
                                    </td>
                                    <td class="table-action">
                                        <button *ngxPermissionsOnly="'CONSTRUCTION:SHOW'"
                                            (click)="showConstruction(item)" type="button"
                                            class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <button *ngxPermissionsOnly="'CONSTRUCTION:EDIT'"
                                            (click)="editConstruction(item)" type="button"
                                            class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                            <i class="feather icon-edit-2"></i>
                                        </button>
                                        <button *ngxPermissionsOnly="'CONSTRUCTION:PRINTER:SHOW'"
                                            (click)="printerConstruction(item)" type="button"
                                            class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                            <i class="feather icon-printer"></i>
                                        </button>
                                        <button *ngxPermissionsOnly="'CONSTRUCTION:DELETE'" type="button"
                                            (click)="delete(item)" class="btn btn-icon btn-danger ml-1"
                                            ngbTooltip="Supprimer">
                                            <i class="feather icon-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="5" class="font-weight-bold font-size-40 text-right">Total</td>
                                    <td class="font-weight-bold font-size-40 text-warning"> {{ total | number}}
                                        {{global.device}}</td>
                                    <td *ngIf="action"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </app-card>
            </div>
        </ng-template>
    </div>
    <ng-template ngxPermissionsExcept="CONSTRUCTION:LIST"
        [ngxPermissionsOnly]="['QUOTE:LIST', 'FUNDING:LIST', 'PRODUCTION:LIST', 'PAYMENT:FUNDING:LIST']">
        <div class="col-md-12">
            <app-no-droit *ngIf="type === 'INTERVENTION'" [title]="'liste intervention'"></app-no-droit>
        </div>
    </ng-template>

    <!-- Liste des devis -->
    <ng-template ngxPermissionsOnly="QUOTE:LIST">
        <div class="col-md-12" *ngIf="type === 'DEVIS' && quotes && quotes.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
                Liste des devis
            </span>
            <app-no-data [title]="'Aucun devis trouvé'"
                *ngIf="type === 'DEVIS' && quotes && quotes.length === 0"></app-no-data>
        </div>
        <app-quote-list style="width: 100%;" [quotes]="quotes"
            *ngIf="type === 'DEVIS' && quotes && quotes.length > 0"></app-quote-list>
    </ng-template>
    <ng-template ngxPermissionsExcept="QUOTE:LIST">
        <div class="col-md-12">
            <app-no-droit *ngIf="type === 'DEVIS'" [title]="'liste devis'"></app-no-droit>
        </div>
    </ng-template>

    <!-- Liste des bons de commane -->
    <ng-template ngxPermissionsOnly="QUOTE:LIST">
        <div class="col-md-12" *ngIf="type === 'BON' && bons && bons.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
                Liste des bon de commande
            </span>
            <app-no-data [title]="'Aucun bon de commande trouvé'"
                *ngIf="type === 'BON' && bons && bons.length === 0"></app-no-data>
        </div>
        <app-quote-list style="width: 100%;" [quotes]="bons"
            *ngIf="type === 'BON' && bons && bons.length > 0"></app-quote-list>
    </ng-template>
    <ng-template ngxPermissionsExcept="QUOTE:LIST">
        <div class="col-md-12">
            <app-no-droit *ngIf="type === 'BON'" [title]="'liste bons de commande'"></app-no-droit>
        </div>
    </ng-template>

    <!-- LISTE DES FACTURE -->
    <ng-template ngxPermissionsOnly="INVOICE:CO:LIST">
        <div class="col-md-12" *ngIf="type === 'FACTURE' && invoiceCos && invoiceCos.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
                Liste des factures
            </span>
            <app-no-data [title]="'Aucune facture trouvé'"
                *ngIf="type === 'FACTURE' && invoiceCos && invoiceCos.length === 0"></app-no-data>
        </div>
        <app-invoice-co-list style="width: 100%;" [invoiceCos]="invoiceCos"
            *ngIf="type === 'FACTURE' && invoiceCos && invoiceCos.length > 0"></app-invoice-co-list>
    </ng-template>
    <ng-template ngxPermissionsExcept="INVOICE:CO:LIST">
        <div class="col-md-12">
            <app-no-droit *ngIf="type === 'FACTURE'" [title]="'liste factures'"></app-no-droit>
        </div>
    </ng-template>

    <!-- Liste des financements -->
    <ng-template ngxPermissionsOnly="FUNDING:LIST">
        <div class="col-md-12" *ngIf="type === 'FINANCEMENT' && fundings && fundings.length === 0">
            <span class="badge badge-success mb-4 f-14 width">
                Liste des financements
            </span>
            <app-no-data [title]="'Aucun financement trouvé'"
                *ngIf="type === 'FINANCEMENT' && fundings && fundings.length === 0"></app-no-data>
        </div>
        <app-funding-list style="width: 100%;" [fundings]="fundings"
            *ngIf="type === 'FINANCEMENT' && fundings && fundings.length > 0"></app-funding-list>
    </ng-template>
    <ng-template ngxPermissionsExcept="FUNDING:LIST">
        <div class="col-md-12">
            <app-no-droit *ngIf="type === 'FINANCEMENT'" [title]="'liste financement'"></app-no-droit>
        </div>
    </ng-template>

    <!-- LISTE DES PRODUCTIONS -->
    <ng-template ngxPermissionsOnly="PRODUCTION:LIST">
        <div class="col-md-12" *ngIf="type === 'REALISATION' && productions && productions.length === 0">
            <span class="badge badge-warning mb-4 f-14 width">
                Liste des réalisations
            </span>
            <app-no-data [title]="'Aucune réalisation trouvée'"
                *ngIf="type === 'REALISATION' && productions && productions.length === 0"></app-no-data>
        </div>
        <app-production-list style="width: 100%;" [productions]="productions"
            *ngIf="type === 'REALISATION' && productions && productions.length > 0"></app-production-list>
    </ng-template>
    <ng-template ngxPermissionsExcept="PRODUCTION:LIST">
        <div class="col-md-12">
            <app-no-droit *ngIf="type === 'REALISATION'" [title]="'liste production'"></app-no-droit>
        </div>
    </ng-template>
</div>