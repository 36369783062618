<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <aw-wizard #wizard class="arc-wizard">
        <aw-wizard-step stepTitle="Bien {{ disponible === 'LOCATION' ? 'en location' : 'en vente' }}">
          <!-- DÉTAILS SUR LE PROPRIETAIRE -->
          <div class="row" *ngIf="edit">
            <span class="badge badge-secondary my-2 f-14 width">
              Détails sur le bailleur
            </span>
          </div>
          <div class="form-group">
            <div class="row mb-2" *ngIf="edit">
              <div class="col-md-3">
                <label>Bailleur </label>
                <input type="text" class="form-control text-uppercase p-2 font-weight-bold"
                  value="{{ house ? house.owner?.nom : ''}}" readonly>
              </div>
              <div class="col-md-3">
                <label>Type </label>
                <input type="text" class="form-control text-uppercase p-2 font-weight-bold"
                  value="{{ house ? house.owner?.type : ''}}" readonly>
              </div>
              <div class="col-md-3">
                <label>Contact </label>
                <input type="text" class="form-control p-2 font-weight-bold"
                  value="{{ house ? house.owner?.telephone : ''}}" readonly>
              </div>
              <div class="col-md-3">
                <label>E-mail </label>
                <input type="text" class="form-control p-2 font-weight-bold"
                  value="{{ house ? house.owner?.email : ''}}" readonly>
              </div>
            </div>
          </div>

          <!-- Détails sur le bien -->
          <div class="row">
            <span class="badge badge-secondary my-2 f-14 width">
              Détails sur le bien
            </span>
          </div>
          <div class="form-group">
            <div class="row mb-2">

              <div class="col-md-3">
                <app-entity-finder [class]="'Owner'" [groups]="['owner']" [required]="true" [label]="'Bailleur'"
                  (uuid)="setOwnerUuid($event)" [selected]="ownerSelected" [disabled]="edit"
                  [placeholder]="'Sélectionnez un bailleur'">
                </app-entity-finder>
              </div>
              <div class="col-md-3">
                <label for="nom">Nom du bien <span class="asterix">*</span></label>
                <input formControlName="nom" type="text" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.nom.errors,
                    'is-valid': submit && f.nom.valid
                  }" placeholder="Nom du bien">
                <div class="invalid-feedback" *ngIf="submit && f.nom.errors">
                  <div *ngIf="f.nom.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="type">Type de bien ? <span class="asterix">*</span></label>
                <select (change)="onChange('type')" class="form-control" id="type" formControlName="type" [ngClass]="{
                    'is-invalid': submit && f.type.errors,
                    'is-valid': submit && f.type.valid
                  }">
                  <option [value]="'IMMEUBLE'">Immeuble</option>
                  <option [value]="'RESIDENCE'">Résidence</option>
                  <option [value]="'TERRAIN'">Terrain</option>
                  <option [value]="'VILLA'">Villa</option>
                  <option [value]="'DOMICILATION_VIRTUEL'">Domociliation virtuel</option>
                  <option [value]="'ENTREPOT'">Entrepot</option>
                  <option [value]="'COMMERCIAL'">Espace commercial</option>
                  <option *ngIf="disponible === 'VENTE'" [value]="'TERRAIN'">Terrain</option>
                  <option [value]="'AUTRES'">Autres</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.type.errors">
                  <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="f.type.value === 'AUTRES'">
                <label for="autreType">Autre type <span class="asterix">*</span></label>
                <input formControlName="autreType" id="autreType" type="text" class="form-control"
                  placeholder="Autre type">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'LOCATION' && !edit">
                <label for="nbrLocative">Nombre de locative ? <span class="asterix">*</span></label>
                <input (ngModelChange)="onChangeLocative()" formControlName="nbrLocative" type="number"
                  class="form-control" [ngClass]="{
                    'is-invalid': submit && f.nbrLocative.errors,
                    'is-valid': submit && f.nbrLocative.valid
                  }" placeholder="Nombre de locative ?">
                <div class="invalid-feedback" *ngIf="submit && f.nbrLocative.errors">
                  <div *ngIf="f.nbrLocative.errors.required">{{required.novide}}</div>
                  <div *ngIf="f.nbrLocative.errors.pattern">{{required.nolettre}}</div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'LOCATION'">
                <label for="numerotation">Type de numérotation <span class="asterix">*</span></label>
                <select (change)="onChangeNumerotation()" class="form-control" id="numerotation"
                  formControlName="numerotation">
                  <option *ngFor="let item of numerotationRow" [value]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="superficie">Superfice (m²)</label>
                <input formControlName="superficie" type="number" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.superficie.errors,
                    'is-valid': submit && f.superficie.valid
                  }" placeholder="Superfice (m²)">
                <div class="invalid-feedback" *ngIf="submit && f.superficie.errors">
                  <div *ngIf="f.superficie.errors.pattern">{{required.nolettre}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="valeur">Valeur vénale</label>
                <input formControlName="valeur" type="number" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.valeur.errors,
                    'is-valid': submit && f.valeur.valid
                  }" placeholder="Valeur">
                <div class="invalid-feedback" *ngIf="submit && f.valeur.errors">
                  <div *ngIf="f.valeur.errors.pattern">{{required.nolettre}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="lot">Lot </label>
                <input formControlName="lot" id="lot" type="text" class="form-control" placeholder="Lot">
              </div>
              <div class="col-md-3">
                <label for="ilot">Ilot </label>
                <input formControlName="ilot" id="ilot" type="text" class="form-control" placeholder="Ilot">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE'">
                <label for="domaine">Type de domaine <span class="asterix">*</span></label>
                <select (change)="onChange('domaine')" class="form-control" id="domaine" formControlName="domaine"
                  [ngClass]="{
                    'is-invalid': submit && f.domaine.errors,
                    'is-valid': submit && f.domaine.valid
                  }">
                  <option [value]="'URBAIN'">Urbain</option>
                  <option [value]="'RURAL'">Rural</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.domaine.errors">
                  <div *ngIf="f.domaine.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE'">
                <label for="titre">A t-il un titre de propriété ? <span class="asterix">*</span></label>
                <select (change)="onChange('titre')" class="form-control" id="titre" formControlName="titre" [ngClass]="{
                    'is-invalid': submit && f.titre.errors,
                    'is-valid': submit && f.titre.valid
                  }">
                  <option [ngValue]="false">Non</option>
                  <option [ngValue]="true">Oui</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.titre.errors">
                  <div *ngIf="f.titre.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && titre">
                <label for="numTitre">Numéro du titre de propriété <span class="asterix">*</span></label>
                <input formControlName="numTitre" id="numTitre" type="text" class="form-control"
                  placeholder="Numéro titre foncier">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && titre">
                <label for="optionTitre">Option titre de propriété <span class="asterix">*</span></label>
                <select class="form-control" id="optionTitre" formControlName="optionTitre" [ngClass]="{
                    'is-invalid': submit && f.optionTitre.errors,
                    'is-valid': submit && f.optionTitre.valid
                  }">
                  <option *ngFor="let item of optionTitreRow" [value]="item.value">{{ item.label }}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.optionTitre.errors">
                  <div *ngIf="f.optionTitre.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' ">
                <label for="bornage">Bornage effectué ?</label>
                <select class="form-control" id="bornage" formControlName="bornage">
                  <option [ngValue]="false">Non</option>
                  <option [ngValue]="true">Oui</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE'">
                <label for="viabilisation">Viabilisé ?</label>
                <select class="form-control" id="viabilisation" formControlName="viabilisation">
                  <option [ngValue]="false">Non</option>
                  <option [ngValue]="true">Oui</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && domaine === 'RURAL'">
                <label for="village">Nom du village</label>
                <input formControlName="village" id="village" type="text" class="form-control"
                  placeholder="Nom du village">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE'">
                <label for="approuve">Approuvé ?</label>
                <select (change)="onChange('approuve')" class="form-control" id="approuve" formControlName="approuve">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && approuve">
                <label for="numApprobation">N° D'approbation <span class="asterix">*</span></label>
                <input formControlName="numApprobation" id="numApprobation" type="text" class="form-control"
                  placeholder="N° D'approbation">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && approuve">
                <label for="dateApprobation">Date d'approbation <span class="asterix">*</span></label>
                <input formControlName="dateApprobation" id="dateApprobation" type="date" class="form-control">
              </div>
              <div class="col-md-6">
                <label for="isOffre">
                  Souhaitez-vous publier les offres de cette promotion sur le site web public<span class="asterix">*</span>
                </label>
                <select class="form-control" id="isOffre"  formControlName="isOffre">
                  <option [value]="'NON'">Non</option>
                  <option [value]="'OUI'">Oui</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="f.isOffre.value === 'OUI'">
                <label for="dateOffre">Date de publication<span class="asterix">*</span></label>
                <input type="date" class="form-control" formControlName="dateOffre" id="dateOffre"
                  [ngClass]="{
                    'is-invalid': submit && f.dateOffre.errors,
                    'is-valid': submit && f.dateOffre.valid
                  }">
                <div class="invalid-feedback" *ngIf="submit && f.dateOffre.errors">
                  <div *ngIf="f.dateOffre.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-{{disponible === 'LOCATION' ? 9 : disponible === 'VENTE' && approuve ? 6 : 12}}">
                <label for="description">Description <span class="asterix">*</span></label>
                <textarea formControlName="description" id="description" type="text" class="form-control"></textarea>
              </div>
              <div class="col-md-3">
                <label for="tolerance">Tolérance (%)<span class="asterix">*</span></label>
                <input (ngModelChange)="onChangeLocative()" formControlName="tolerance" type="number"
                  class="form-control" placeholder="Tolerance %">
              </div>
              <div class="col-md-3">
                <label for="titrefoncier">Numéro Titre Foncier</label>
                <input type="text" formControlName="titrefoncier" class="form-control" id="titrefoncier"
                  placeholder="Titre foncier n°">
              </div>
            </div>
          </div>

          <!-- DÉTAILS DE Localisation -->
          <div class="row">
            <span class="badge badge-secondary my-2 f-14 width">
              Détails de localisation
            </span>
          </div>
          <div class="form-group">
            <div class="row mb-2">
              <div class="col-md-3">
                <label for="ville">Ville <span class="asterix">*</span></label>
                <input formControlName="ville" type="text" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.ville.errors,
                    'is-valid': submit && f.ville.valid
                  }" placeholder="Ville">
                <div class="invalid-feedback" *ngIf="submit && f.ville.errors">
                  <div *ngIf="f.ville.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="commune">Commune <span class="asterix">*</span></label>
                <input formControlName="commune" type="text" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.commune.errors,
                    'is-valid': submit && f.commune.valid
                  }" placeholder="Commune">
                <div class="invalid-feedback" *ngIf="submit && f.commune.errors">
                  <div *ngIf="f.commune.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="quartier">Quartier <span class="asterix">*</span></label>
                <input formControlName="quartier" type="text" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.quartier.errors,
                    'is-valid': submit && f.quartier.valid
                  }" placeholder="Quartier">
                <div class="invalid-feedback" *ngIf="submit && f.quartier.errors">
                  <div *ngIf="f.quartier.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="lng">Longitude</label>
                <input type="text" class="form-control" formControlName="lng" id="lng" placeholder="Longitude">
              </div>
              <div class="col-md-3">
                <label for="lat">Latitude</label>
                <input type="text" class="form-control" formControlName="lat" id="lat" placeholder="Latitude">
              </div>
              <div class="col-md-3">
                <label for="hauteur">Hauteur</label>
                <input formControlName="hauteur" type="number" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.hauteur.errors,
                    'is-valid': submit && f.hauteur.valid
                  }" placeholder="Hauteur">
                <div class="invalid-feedback" *ngIf="submit && f.hauteur.errors">
                  <div *ngIf="f.hauteur.errors.pattern">{{required.nolettre}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="altitude">Altitude</label>
                <input formControlName="altitude" type="number" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.altitude.errors,
                    'is-valid': submit && f.altitude.valid
                  }" placeholder="Altitude">
                <div class="invalid-feedback" *ngIf="submit && f.altitude.errors">
                  <div *ngIf="f.altitude.errors.pattern">{{ required.nolettre }}</div>
                </div>
              </div>
            </div>
          </div>
          <agm-map [draggableCursor]="'default'" [mapTypeId]="'hybrid'" [latitude]="lat" [longitude]="lng" [zoom]="zoom"
            (mapClick)="updateGeo($event)" (zoomChange)="updateZoom($event)">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>

          <!-- DÉTAILS SUPLEMENTAIRES -->
          <div class="row">
            <span class="badge badge-secondary my-2 f-14 width">
              Détails suplémentaires
            </span>
          </div>
          <div class="form-group">
            <div class="row mb-2">
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && type !== 'TERRAIN' && type !== 'IMMEUBLE'">
                <label for="nbrPiece">Nombre de pièce</label>
                <input formControlName="nbrPiece" id="nbrPiece" type="number" class="form-control"
                  placeholder="Nombre de pièce">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && type !== 'TERRAIN'">
                <label for="nbrParking">Nombre de parking</label>
                <input formControlName="nbrParking" id="nbrParking" type="number" class="form-control"
                  placeholder="Nombre de parking">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && type !== 'TERRAIN' && type !== 'IMMEUBLE'">
                <label for="salleEau">Nombre de salle d'eau</label>
                <input formControlName="salleEau" id="salleEau" type="number" class="form-control"
                  placeholder="Nombre de salle d'eau">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && type !== 'TERRAIN'">
                <label for="nbrNiveau">Nombre de niveau</label>
                <input formControlName="nbrNiveau" id="nbrNiveau" type="number" class="form-control"
                  placeholder="Nombre de niveau">
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE' && type !== 'TERRAIN'">
                <label for="jardin">Jardin ?</label>
                <select class="form-control" id="jardin" formControlName="jardin">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="disponible === 'VENTE'  && type !== 'TERRAIN'">
                <label for="piscine">Piscine ?</label>
                <select class="form-control" id="piscine" formControlName="piscine">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="angle">A l'angle ?</label>
                <select class="form-control" id="angle" formControlName="angle">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="pres_eau">Près de l'eau ?</label>
                <select class="form-control" id="presEau" formControlName="presEau">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="f.presEau.value">
                <label for="distanceEau">Distance de l'eau (m) ?</label>
                <input formControlName="distanceEau" type="number" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.distanceEau.errors,
                    'is-valid': submit && f.distanceEau.valid
                  }" placeholder="Distance de l'eau">
                <div class="invalid-feedback" *ngIf="submit && f.distanceEau.errors">
                  <div *ngIf="f.distanceEau.errors.pattern">{{required.nolettre}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="piedsEau">Pieds dans l'eau ?</label>
                <select class="form-control" id="piedsEau" formControlName="piedsEau">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="bordureVoie">Bordure de grande voie ?</label>
                <select class="form-control" id="bordureVoie" formControlName="bordureVoie">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="f.bordureVoie.value">
                <label for="distanceRoute">Distance de la route (m) ?</label>
                <input formControlName="distanceRoute" type="number" class="form-control" [ngClass]="{
                    'is-invalid': submit && f.distanceRoute.errors,
                    'is-valid': submit && f.distanceRoute.valid
                  }" placeholder="Distance de la route">
                <div class="invalid-feedback" *ngIf="submit && f.distanceRoute.errors">
                  <div *ngIf="f.distanceRoute.errors.pattern">{{required.nolettre}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label for="terreFerme">Terre ferme ?</label>
                <select class="form-control" id="terreFerme" formControlName="terreFerme">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="basFond">Bas fond ?</label>
                <select class="form-control" id="basFond" formControlName="basFond">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="ecole">Ecole a proximité ?</label>
                <select class="form-control" id="ecole" formControlName="ecole">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="marche">Marché a proximité ?</label>
                <select class="form-control" id="marche" formControlName="marche">
                  <option *ngFor="let item of boolRow" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- DÉTAILS ATTRIBUTION -->
          <div class="row" *ngIf="!edit">
            <span class="badge badge-secondary my-2 f-14 width">
              Attribuer le bien à des agents
            </span>
          </div>
          <div class="form-group" *ngIf="!edit">
            <div class="row mb-2">
              <div class="col-md mt-4">
                <dual-list (destinationChange)="setData()" [sort]="keepSorted" [source]="source" [key]="key"
                  [display]="display" [filter]="true" [(destination)]="confirmed" height="265px" [format]="format"
                  [disabled]="disabled">
                </dual-list>
              </div>
            </div>
          </div>

          <!-- DÉTAILS FICHIER -->
          <div class="row">
            <span class="badge badge-secondary my-2 f-14 width">
              Ajout de fichiers
            </span>
          </div>
          <div class="form-group">
            <div class="row mb-2">
              <div class="row mb-2">
                <div class="flex-grow-0" style="min-width: 30%">
                  <app-image-uploader (filesd)="loadfile($event)" [maxSize]="2"
                    (imageUploaded)="setParam('photoUuid',$event)"
                    [photo]="house ? house.photo : null "></app-image-uploader>
                </div>
                <div class="col-md">
                  <label>Pièces et documents à joindre</label>
                  <app-folder-uploader (filesd)="files($event)" [maxSize]="3"
                    (filesUploaded)="setParam('folderUuid',$event)" [path]="'bien'" [etat]="edit ? 'edit': 'add'"
                    [folder]="edit && house ? house?.folder : null">
                  </app-folder-uploader>
                </div>
              </div>
            </div>
            <div class="col-sm-12 centered-content" *ngIf="!edit && (disponible === 'LOCATION' && f.nbrLocative.value > 0 || f.isOffre.value === 'OUI')">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-primary" awNextStep>
                  Suivant <i class="feather icon-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Locative" *ngIf="!edit && disponible === 'LOCATION' && f.nbrLocative.value > 0">
          <!-- DÉTAILS CONCERNANT LES LOCATIVES DU BIEN -->
          <div class="row">
            <span class="badge badge-secondary my-2 f-14 width">
              Détails concernant les locatives du bien
            </span>
          </div>
          <div formArrayName="rentals">
            <!-- LABEL DU TABLEAU DES LOCATIVES -->
            <div class="form-group">
              <div class="row">
                <div class="col-md-1" *ngIf="f.numerotation.value == 1">
                  <label>Numérotation <span class="asterix">*</span></label>
                </div>
                <div class="col-md-2">
                  <label>N° Porte <span class="asterix">*</span></label>
                </div>
                <div class="col-md-2">
                  <label>Type de locative <span class="asterix">*</span></label>
                </div>
                <div class="col-md-2">
                  <label>Loyer <span class="asterix">*</span></label>
                </div>
                <div class="col-md-2">
                  <label>Charge <span class="asterix">*</span></label>
                </div>
                <div class="col-md-1">
                  <label>Pièce <span class="asterix">*</span></label>
                </div>
                <div class="col-md-2">
                  <label>Superficie</label>
                </div>
              </div>
            </div>
            <div class="form-group" *ngFor="let locat of rental.controls; let i=index" [formGroupName]="i">
              <div class="row">
                <div class="col-md-1" *ngIf="f.numerotation.value == 1">
                  <select (change)="onChangeNum(locat)" class="form-control" id="numerotation{{i}}"
                    formControlName="numerotation" [ngClass]="{
                      'is-invalid': submit && locat.get('numerotation').errors,
                      'is-valid': submit && locat.get('numerotation').valid
                    }">
                    <option *ngFor="let item of numRow" [label]="item">{{item}}</option>
                  </select>
                  <div class="invalid-feedback" *ngIf="submit && locat.get('numerotation').errors">
                    <div *ngIf="locat.get('numerotation').errors.required">{{required.novide}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <input type="text" formControlName="porte" class="form-control" id="porte{{i}}" [ngClass]="{
                      'is-invalid': submit && locat.get('porte').errors,
                      'is-valid': submit && locat.get('porte').valid
                    }" placeholder="N° Porte">
                  <div class="invalid-feedback" *ngIf="submit && locat.get('porte').errors">
                    <div *ngIf="locat.get('porte').errors.required">{{required.novide}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <select (change)="onChangeNum(locat)" class="form-control" id="type{{i}}" formControlName="type"
                    [ngClass]="{
                      'is-invalid': submit && locat.get('type').errors,
                      'is-valid': submit && locat.get('type').valid
                    }">
                    <optgroup *ngFor="let item of typeRow" [label]="item.label">
                      <option *ngFor="let row of item.type" [value]="row.value">{{row.label}}</option>
                    </optgroup>
                  </select>
                  <div class="invalid-feedback" *ngIf="submit && locat.get('type').errors">
                    <div *ngIf="locat.get('type').errors.required">{{required.novide}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <input (change)="onChangeNum(locat)" type="number" step="5000" formControlName="montant"
                    class="form-control" id="montant{{i}}" [ngClass]="{
                      'is-invalid': submit && locat.get('montant').errors,
                      'is-valid': submit && locat.get('montant').valid
                    }" placeholder="Montant loyer">
                  <div class="invalid-feedback" *ngIf="submit && locat.get('montant').errors">
                    <div *ngIf="locat.get('montant').errors.required">{{required.novide}}</div>
                    <div *ngIf="locat.get('montant').errors.pattern">{{required.nolettre}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <input (change)="onChangeNum(locat)" type="number" step="5000" formControlName="charge"
                    class="form-control" id="charge{{i}}" [ngClass]="{
                      'is-invalid': submit && locat.get('charge').errors,
                      'is-valid': submit && locat.get('charge').valid
                    }" placeholder="Montant charge">
                  <div class="invalid-feedback" *ngIf="submit && locat.get('charge').errors">
                    <div *ngIf="locat.get('charge').errors.required">{{required.novide}}</div>
                    <div *ngIf="locat.get('charge').errors.pattern">{{required.nolettre}}</div>
                  </div>
                </div>
                <div class="col-md-1">
                  <input (change)="onChangeNum(locat)" type="number" formControlName="piece" class="form-control"
                    id="piece{{i}}" [ngClass]="{
                      'is-invalid': submit && locat.get('piece').errors,
                      'is-valid': submit && locat.get('piece').valid
                    }" placeholder="Pièce">
                  <div class="invalid-feedback" *ngIf="submit && locat.get('piece').errors">
                    <div *ngIf="locat.get('piece').errors.required">{{required.novide}}</div>
                    <div *ngIf="locat.get('piece').errors.pattern">{{required.nolettre}}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <input (change)="onChangeNum(locat)" type="number" step="100" class="form-control"
                    id="superficie{{i}}" placeholder="Superficie">
                  <div class="invalid-feedback" *ngIf="submit && locat.get('superficie').errors">
                    <div *ngIf="locat.get('superficie').errors.pattern">{{required.nolettre}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 centered-content">
            <div class="btn-group mt-10">
              <button type="button" class="btn btn-warning" awPreviousStep>
                <i class="feather icon-arrow-left"></i> Précédent
              </button>
              <button *ngIf="!edit && f.isOffre.value === 'OUI'" type="button" awNextStep class="btn btn-primary ml-1">
                Suivant <i class="feather icon-arrow-right"></i>
              </button>
            </div>
          </div>
        </aw-wizard-step>
        <!-- STEP 3 DU FORMULAIRE -->
        <aw-wizard-step stepTitle="Offre" *ngIf="!edit && f.isOffre.value === 'OUI'">
          <div class="row">
            <span class="badge badge-primary my-2 f-14 width"> Informations supplementaires</span>
          </div>

          <div class="row m-2 mb-2">
            <span class="badge badge-secondary my-2 f-14 width"> Les informations relatives aux pièces du bien</span>
            <form autocomplete="off" [formGroup]="pieceForm">
              <div class="form-group">
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label for="chambre">Chambres </label>
                    <input type="number" min="0" class="form-control" formControlName="chambre" id="chambre">
                  </div>
                  <div class="col-md-3">
                    <label for="douche">Douches </label>
                    <input type="number" min="0" class="form-control" formControlName="douche" id="douche">
                  </div>
                  <div class="col-md-3">
                    <label for="salon">Salons </label>
                    <input type="number" min="0" class="form-control" formControlName="salon" id="salon">
                  </div>
                  <div class="col-md-3">
                    <label for="garage">Garages </label>
                    <input type="number" min="0" class="form-control" formControlName="garage" id="garage">
                  </div>
                  <div class="col-md-3">
                    <label for="cuisine">Cuisine </label>
                    <input type="number" min="0" class="form-control" formControlName="cuisine" id="cuisine">
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="row m-2 mb-2" >
            <span class="badge badge-secondary my-2 f-14 width"> Les informations relatives aux équipements du bien</span>
            <div class="col-md-12">
              <form autocomplete="off" [formGroup]="equipementForm">
                <div class="form-group">
                  <div class="row m-4">
                    <div class="col-md-4">
                      <p>
                        <input class="form-check-input" id="wifi" type="checkbox" formControlName="wifi">
                        <label for="wifi">WiFi </label>
                      </p>
                      <p>
                        <input class="form-check-input" id="wifi" type="checkbox" formControlName="piscine">
                        <label for="piscine">Piscine </label>
                      </p>
                      <p>
                        <input class="form-check-input" id="securite" type="checkbox" formControlName="securite">
                        <label for="securite">Gardien de Sécurité</label>
                      </p>
                    </div>
                    <div class="col-md-4">
                      <p>
                        <input class="form-check-input" id="clim" type="checkbox" formControlName="clim">
                        <label for="clim">Air Conditioner </label>
                      </p>
                      <p>
                        <input class="form-check-input" id="urgence" type="checkbox" formControlName="urgence">
                        <label for="urgence">Issue d'Urgence</label>
                      </p>
                      <p>
                        <input class="form-check-input" id="incendie" type="checkbox" formControlName="incendie">
                        <label for="incendie">Borne d'incendie </label>
                      </p>
                    </div>
                    <div class="col-md-4">
                      <p>
                        <input class="form-check-input" id="parking" type="checkbox" formControlName="parking">
                        <label for="parking">Parking </label>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row m-4 mb-2">
            <span class="badge badge-secondary my-2 f-14 width"> La gallerie du bien</span>
            <div class="col-md-12">
              <app-folder-upload
                (filesUploaded)="uploadFile($event, 'GALLERY')"
                [maxSize]="3"
                [type]="['jpg', 'png', 'jpeg']"
                [etat]="'add'"
                [folder]="null" >
              </app-folder-upload>
            </div>
          </div>
          <div class="row m-2 mb-2">
            <span class="badge badge-secondary my-2 f-14 width"> Video de presentation</span>
            <div class="col-md-12">
              <form autocomplete="off" [formGroup]="videoForm">
                <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                      <label for="link">Lien de la vidéo <span class="asterix">*</span></label>
                      <input type="text" class="form-control" formControlName="link" id="link">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row m-4 mb-2">
            <span class="badge badge-secondary my-2 f-14 width"> Plan</span>
            <div class="col-md-12">
              <app-folder-upload-2
                (filesUploaded)="uploadFile($event, 'PLAN')"
                [maxSize]="3"
                [type]="['jpg', 'png', 'jpeg']"
                [etat]="'add'"
                [folder]="null" >
              </app-folder-upload-2>
            </div>
          </div>
          <div class="col-sm-12 centered-content mt-3">
            <div class="btn-group mt-10">
              <button type="button" awPreviousStep class="btn btn-warning">
                <i class="feather icon-arrow-left"></i> Précedent
              </button>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="onReset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
