
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { environment } from '@env/environment';
import { Globals } from '@theme/utils/globals';
import HC_drilldown from 'highcharts/modules/drilldown';
import { FilterService } from '@service/filter/filter.service';
import { Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-dash-crm-location',
  templateUrl: './dash-crm-location.component.html',
  styleUrls: ['./dash-crm-location.component.scss']
})
export class DashCrmLocationComponent implements OnInit {

  pieHouseVe = 0
  pieHouseRe = 0
  pieHouseDi = 0

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any;
  public lineBasicChartOptions: any;

  widget : any
  listing : any
  echeanciers : []
  global = {country: Globals.country, device: Globals.device}
  dtOptions: any = {};
  userSession = Globals.user;
  etat: boolean = false
  isPrint: boolean = true;
  publicUrl = environment.publicUrl;
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  @Input() datas: any;
  pieChartData: any;
  public pieChartTag: CanvasRenderingContext2D;

  constructor(
    private filterService: FilterService,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.widget = this.datas?.widget
    this.dtOptions = Globals.dataTable;
    this.pieChartData = {  
      labels: ['Locatives disponibles', 'Locatives occupées', 'Locatives réservées'],  
      datasets: [{  
        data: [this.widget?.locative?.dispo, this.widget?.locative?.occup, this.widget?.locative?.reserve], 
        backgroundColor: ['#4caf50', '#f44336', '#ffeb3b'],  
        hoverBackgroundColor: ['#66bb6a', '#e57373', '#fff176']  
      }]  
    };
  }

 
}