
import { Tenant } from '@model/tenant';
import { Payment } from '@model/payment';
import { Invoice } from '@model/invoice';
import { Contract } from '@model/contract';
import { ToastrService } from 'ngx-toastr';
import { Treasury } from '@model/treasury';
import { Globals } from '@theme/utils/globals';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { PaymentService } from '@service/payment/payment.service';
import { InvoiceService } from '@service/invoice/invoice.service';
import { EmitterService } from '@service/emitter/emitter.service';
import { TreasuryService } from '@service/treasury/treasury.service';
import { ContractService } from '@service/contract/contract.service';
import { UploaderService } from '@service/uploader/uploader.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UpdateComponent } from '@agence/modal/update/update.component';
import { TenantService } from '@service/tenant/tenant.service';
import { InvoiceCoService } from '@service/invoice-co/invoice-co.service';
import { InvoicePaymentService } from '@service/invoice-payment/invoice-payment.service';

@Component({
  selector: 'app-trustee-provider-payment-add',
  templateUrl: './trustee-provider-payment-add.component.html',
  styleUrls: ['./trustee-provider-payment-add.component.scss']
})
export class TrusteeProviderPaymentAddComponent implements OnInit {
  ESCAPE_KEYCODE = 27;
  title: string = "";
  sourceTitle: string = "";
  numeroTitle: string = "";
  treasuryUuid: string = null;
  treasury: Treasury;
  tenantSelected?: any;
  contract: Contract;
  invoices: any[];
  autres: Invoice[];
  isHidden: boolean = false;
  entree: boolean = false;
  contracts: Array<Contract>;
  isLoading = false;
  montantTotal:any = 0;
  montantRegle: any = 0;
  montantRestant: any = 0;
  form: FormGroup;
  submit: boolean = false;
  edit: boolean = false;
  payment: any;
  required = Globals.required;
  userSession = Globals.user;
  global = {country: Globals.country, device: Globals.device};
  tenants: Array<Tenant> = [];
  modeRow: any[] = [
    { label: "Espèce", value: "ESPECE" },
    { label: "Chèque", value: "CHEQUE" },
    { label: "Mobile money", value: "MOBILE MONEY" },
    { label: "Wave", value: "WAVE" },
    { label: "Versement", value: "VERSEMENT" },
    { label: "Virement", value: "VIREMENT" }
  ];
  typeFactureRow = [
    { label: "Contrat", value: "CONTRAT" },
    { label: "Direct", value: "SANS_CONTRAT" },
  ];
  selectedProvider: any
  selectedInvoice: any
  isLoadingInvoices: boolean = false;

  constructor(
    private modalService: NgbModal,
    public modalActive: NgbActiveModal,
    private formBuild: FormBuilder,
    public paymentService: PaymentService,
    private tenantService: TenantService,
    private invoiceService: InvoiceService,
    private treasuryService: TreasuryService,
    private contractService: ContractService,
    public uploadService: UploaderService,
    public toastr: ToastrService,
    private emitter: EmitterService,
    private invoiceCoService: InvoiceCoService,
    private invoicePaymentService: InvoicePaymentService
  ) {
    this.edit = this.invoicePaymentService.edit;
    this.treasuryUuid = this.invoicePaymentService.treasury;
    this.payment = this.invoicePaymentService.getInvoicePayment();
    this.title = (!this.edit) ? "Ajouter un paiement" : "Modifier le paiement";
    this.newForm();
    this.setTreasury();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      folderUuid: [null],
      treasury: [null],
      effectue: [null, [Validators.required]],
      montant: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      date: [null, [Validators.required]],
      mode: ['ESPECE', [Validators.required]],
      source: [null],
      numero: [null],
      tiers: [null],
      provider: [null, [Validators.required]],
      files: [null, FileUploadValidators.filesLimit(3)],
      folders: this.formBuild.array([]),
      invoice: [null]
    });
  }
  editForm() {
    if (this.edit) {
      const data = {...this.invoicePaymentService.getInvoicePayment()};
      console.log(data)
      this.setCurrentProvider(data?.provider);
      data.date = DateHelperService.fromJsonDate(data.date)
      let invoice = data.invoice
      data.invoice = data?.invoice?.uuid
      this.form.patchValue(data);
      this.f.folderUuid.setValue(data?.folder?.uuid)

      this.montantRestant = invoice.impaye
      this.montantTotal = invoice.montant
      this.montantRegle = invoice.paye
    }
  }

  setTreasury(){
    if(this.treasuryUuid){
      this.f.treasury.setValue(this.treasuryUuid)
      this.treasuryService.getSingle(this.treasuryUuid).subscribe((res: any) => {
        if (res) {
          this.treasury = res;
          if (this.treasury.type === "CAISSE") {
            this.modeRow = [
              { label: "Espèce", value: "ESPECE" },
              { label: "Mobile money", value: "MOBILE MONEY" },
              { label: "Wave", value: "WAVE" }
            ];
          }
          if (this.treasury.type === "BANQUE") {
            this.modeRow = [
              { label: "Chèque", value: "CHEQUE" },
              { label: "Versement", value: "VERSEMENT" },
              { label: "Virement", value: "VIREMENT" }
            ];
          }
          return this.treasury
        }
      });
    }
  }

  setProviderUuid(uuid) {
    if (uuid) {
      this.f.provider.setValue(uuid);
      this.loadInvoices()
    } else {
      this.invoices = [];
      this.f.provider.setValue(null);
      this.f.invoice.setValue(null);
    }
  }

  setCurrentProvider(provider): void {
    this.setProviderUuid(provider?.uuid);
    this.selectedProvider = {
      photoSrc: provider?.photoSrc,
      title: provider?.searchableTitle,
      detail: provider?.searchableDetail
    };
  }

  loadInvoices() {
    console.log('here')
    this.isLoadingInvoices = true;
    if (!this.f.provider.value) {
      this.isLoadingInvoices = false;
      this.invoices = [];
      return;
    }
    this.invoiceCoService.getList(this.f.provider.value, 'FACTURE', null, "VALIDE").subscribe(res => {
      console.log(res)
      this.isLoadingInvoices = false;
      return this.invoices = res;
    }, error => {
      this.isLoadingInvoices = false;
    });
  }

  setInvoiceUuid(event) {
    var uuid = event.target.value
    if (uuid) {
      this.f.invoice.setValue(uuid);
      let filtered = this.invoices.filter((invoice) => invoice.uuid === uuid)
      this.selectedInvoice = filtered && filtered.length > 0 ? filtered[0] : null;
      if (this.selectedInvoice) {
        this.calculTotal()
      }
    } else {
      this.f.invoice.setValue(null);
    }
  }

  calculTotal() {
    let total = 0
    let paye = 0
    let impaye = 0
    if (this.selectedInvoice) {
      console.log(this.selectedInvoice)
      total += this.selectedInvoice.montant
      paye += this.selectedInvoice.paye
      impaye += this.selectedInvoice.impaye
      // this.isHidden = i > 1 ? true : false
    }
    this.montantTotal = total
    this.montantRestant = impaye
    this.montantRegle = paye
    this.f.montant.setValue(impaye)
  }

  onChangeLibelle() {
    if(this.f.mode.value === 'VIREMENT' || this.f.mode.value === 'VERSEMENT'){
      this.numeroTitle = "N° virement"
      this.sourceTitle = "Banque"
    } else if(this.f.mode.value === 'CHEQUE'){
      this.sourceTitle = "Banque"
      this.numeroTitle = "N° cheque"
    } else if(this.f.mode.value === 'MOBILE MONEY' || this.f.mode.value === 'WAVE'){
      this.sourceTitle = "N° Téléphone"
      this.numeroTitle = "N° Transaction"
    }
    this.f.source.setValue(null)
    this.f.numero.setValue(null)
  }

  onChangeEffectue() {
    this.f.tiers.setValue(null)
  }

  onChangeMontant(){
    console.log(this.f.montant.value > parseFloat(this.montantRestant))
    console.log(this.montantRestant)
    if(this.f.montant.value > parseFloat(this.montantRestant)){
      this.f.montant.setValue(0)
    }
  }

  onSubmit() {
    this.submit = true;
    if (this.f.montant.value < 0) {
      this.toast('Le montant du paiement ne peut être inferieur à 0.', 'Montant erroné', 'warning');
      return
    }
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.invoicePaymentService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modalActive.close('ferme');
          if (data?.uuid) {
            // this.invoicePaymentService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, res?.data?.uuid);
            this.emitter.emit({action: 'PAYMENT_UPDATED', payload: res?.data});
          } else {
            // res.data.forEach(item => {
            //   this.invoicePaymentService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, item?.uuid);
            // })
            this.emitter.emit({action: 'PAYMENT_ADD', payload: res?.data});
          }
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  files(data) {
    if(data && data !== null){
      data.forEach(item => {
        this.folder.push(
          this.formBuild.group({
            uuid: [item?.uuid, [Validators.required]],
            name: [item?.name],
            path: [item?.path]
          })
        );
      });
    }
  }
  upload(files): void {
    for (const file of files) {
      this.uploadService.upload(file);
    }
  }
  setParam(property, value): void {
    if (value) {
      if (this.form.value.hasOwnProperty(property)) {
        Object.assign(this.form.value, {[property]: value});
      }
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].setValue(value);
      }
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      this.onClose()
    }
  }

  onClose(){
    if (!this.edit && this.form.value.folderUuid) {
      var data = {uuid: this.form.value.folderUuid, path: 'paiement_locataire'}
      this.uploadService.getDelete(data).subscribe((res: any) => {
        if (res) {
          if (res?.status === 'success') {
            this.form.reset()
            this.modalActive.close('ferme');
          }
        }
        return res
      });
    }else{
      this.form.reset()
      this.modalActive.close('ferme');
    }
  }
  onReset(){
    if (this.form.value.folderUuid) {
      this.toast('Impossible de de vider le formulaire lorsque un upload a été éffectué', 'Une erreur a été rencontrée', 'warning');
    }else{
      this.form.reset()
    }
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  modal(component, type, size, center, backdrop) {
    return this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {}, (reason) => {});
  }
  get f() { return this.form.controls; }
  get option() { return this.form.get('options') as FormArray; }
  get folder() { return this.form.get('folders') as FormArray; }

  checkForm() {
    console.log(this.form.valid)
    console.log(this.form.getRawValue())
  }
}
