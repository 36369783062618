<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
        <tr>
          <th *ngIf="owner">Bailleur</th>
          <th>N° facture</th>
          <th>État</th>
          <th>Date</th>
          <th>Crée le</th>
          <th>Montant</th>
          <th *ngIf="action">Action</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of payments">
            <td *ngIf="owner">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.invoice?.repayment?.owner?.searchableTitle}}</span> <br />
                Type : {{item?.invoice?.repayment?.owner?.type}}<br />
                Téléphone : {{item?.invoice?.repayment?.owner?.telephone}}
              </p>
            </td>
            <td>{{ item?.invoice?.code}}</td>
            <td>
              <span class="badge" [ngClass]="{
              'badge-success' : item?.etat === 'VALIDE',
              'badge-danger' : item?.etat === 'INVALIDE'
              }">{{validation(item?.etat)}}</span> <br />
              <span class="badge " [ngClass]="{
              'badge-light-success' : item?.status === 'CONFIRMER',
              'badge-light-danger' : item?.status === 'ATTENTE'
              }"><small>{{confirmation(item?.status)}}</small></span>
            </td>
            <td>{{item?.date|date: "d MMMM y" : '' : 'fr-FR'}}</td>
            <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td><p class="m-0 d-inline-block align-middle font-16"><span class="text-warning">{{item?.montant|number}} {{global.device}}</span> <br /></p></td>
            <td class="table-action">
              <div class="overlay-edit">
                  <button (click)="showPayment(item)" type="button" class="btn btn-icon btn-secondary ml-1"
                    ngbTooltip="Détails">
                    <i class="fas fa-eye"></i>
                  </button>
                <ng-template ngxPermissionsOnly="PAYMENT:REPAYMENT:EDIT">
                  <button *ngIf="item?.etat === 'INVALIDE'" (click)="editPayment(item)" type="button" class="btn btn-icon btn-primary ml-1"
                    ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="PAYMENT:REPAYMENT:VALIDATE">
                  <button *ngIf="item?.etat === 'INVALIDE'" (click)="validatePayment(item)" type="button" class="btn btn-icon  btn-success ml-1" ngbTooltip="Valider">
                    <i class="fas fa-check"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="PAYMENT:REPAYMENT:PRINTER:SHOW">
                  <button *ngIf="item?.etat === 'VALIDE'" (click)="printerPayment(item)" type="button" class="btn btn-icon btn-warning ml-1"
                    ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="PAYMENT:REPAYMENT:DELETE">
                  <button *ngIf="item?.status !== 'CONFIRMER'" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td [attr.colspan]="owner ? '5' : '4'" class="font-weight-bold font-size-40 text-right">TOTAL</td>
            <td class="font-weight-bold font-size-40 text-success"> {{total | number}} {{global.device}}</td>
            <td *ngIf="action"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </app-card>
</div>
