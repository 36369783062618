<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence : </span>
        <span class="title-result-show">{{electricite?.code }}</span>
      </p>
      <p>
        <span class="title-show">Mois : </span>
        <span class="title-result-show">{{ electricite?.rent?.mois }}</span>
      </p>
      <p>
        <span class="title-show">Locataire : </span>
        <span class="title-result-show"> {{ electricite?.rent?.contract?.tenant?.searchableTitle }} </span>
      </p>
      <p>
        <span class="title-show">Bailleur : </span>
        <span class="title-result-show"> {{ electricite?.rent?.contract?.rental?.house?.owner?.searchableTitle}}</span>
      </p>
      <p>
        <span class="title-show">Bien : </span>
        <span class="title-result-show"> {{ electricite?.rent?.contract?.rental?.house?.nom }}</span>
      </p>
      <p>
        <span class="title-show">Locative : </span>
        <span class="title-result-show"> {{ electricite?.rent?.contract?.rental?.porte }}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Type contrat : </span>
        <span class="title-result-show"> {{ electricite?.rent?.contract?.type }} </span>
      </p>
      <p>
        <span class="title-show text-warning">Loyer : </span>
        <span class="title-result-show text-warning">{{ electricite?.rent?.loyer| number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show text-warning">Charge : </span>
        <span class="title-result-show text-warning">{{ electricite?.rent?.charge| number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show text-warning">Loyer et charge : </span>
        <span class="title-result-show text-warning">{{ electricite?.montant| number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{electricite?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ electricite?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{electricite?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{electricite?.update }}</span>
      </p>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Période</th>
          <th>Index de départ </th>
          <th>Index de fin</th>
          <th>Pu</th>
          <th>consommation</th>
          <th>Montant</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{electricite?.rent?.mois}}</td>
          <td>{{electricite?.debut}}</td>
          <td>{{electricite?.fin}}</td>
          <td>{{electricite?.prix|number}} {{global.device}}</td>
          <td>{{electricite?.conso}}</td>
          <td>{{electricite?.montant|number}} {{global.device}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <table class="width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">TOTAL</td>
        <td class="font-weight-bold font-size-default text-right">{{ electricite?.montant | number }}
          {{global.device}}</td>
      </tr>
    </tfoot>
  </table>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template ngxPermissionsOnly="ELECTRICITE:PRINTER:SHOW">
    <button (click)="printerRent(electricite)" class="btn btn-warning">
      Imprimer <i class="feather icon-printer"></i>
    </button>
  </ng-template>
</div>
