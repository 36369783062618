<div class="row justify-content-center">
  <!-- Filtre de recherche -->
  <app-filter class="width"
    [name]="name"
    [nameTitle]="nameTitle"
    [nameType]="nameType"
    [nameClass]="nameClass"
    [nameNamespace]="nameNamespace"
    [nameGroups]="nameGroups"

    [autre]="autre"
    [autreTitle]="autreTitle"
    [autreType]="autreType"
    [autreClass]="autreClass"
    [autreNamespace]="autreNamespace"
    [autreGroups]="autreGroups"

    [bien]="bien"
    [bienTitle]="bienTitle"
    [bienType]="bienType"
    [bienClass]="bienClass"
    [bienNamespace]="bienNamespace"
    [bienGroups]="bienGroups"

    [isPrint]="isPrint"
    [dateD]="true"
    [dateF]="true"
    [type]="true"
    [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)" (printerEvent)="onPrinter($event)">
  </app-filter>


  <ng-container *ngIf="type === 'TOUT'">

    <!-- ANALYTICS -->
    <div class="col-xl-6 col-sm-12">
      <div class="row">
        <!-- PROPRIETAIRE  -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-yellow">{{ widget?.proprietaire?.tout }}</h4>
                <h6 class="text-muted m-b-0">{{ widget?.proprietaire?.tout > 1 ? 'Bailleurs' : 'Bailleur' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-user f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-7">
                  <p class="text-white m-b-0">{{ widget?.proprietaire?.actif }} {{ widget?.proprietaire?.actif > 1 ? 'Actifs' : 'Actif' }}</p>
                </div>
                <div class="col-5 text-right">
                  <p class="text-white m-b-0">{{ widget?.proprietaire?.inactif }} {{ widget?.proprietaire?.inactif > 1 ? 'Inactifs' : 'Inactif' }}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- PROPRIETAIRE END -->

        <!-- LOCATAIRE -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-red">{{ widget?.locataire?.tout }}</h4>
                <h6 class="text-muted m-b-0">{{ widget?.locataire?.tout > 1 ? 'Locataires' : 'Locataire' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-user f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-7">
                  <p class="text-white m-b-0">{{ widget?.locataire?.actif }} {{ widget?.locataire?.actif > 1 ? 'Actifs' : 'Actif' }}</p>
                </div>
                <div class="col-5 text-right">
                  <p class="text-white m-b-0">{{ widget?.locataire?.inactif }} {{ widget?.locataire?.inactif > 1 ? 'Inactifs' : 'Inactif' }}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- LOCATAIRE END -->

        <!-- CLIENT -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-blue">{{ widget?.client?.tout }}</h4>
                <h6 class="text-muted m-b-0">{{ widget?.client?.tout > 1 ? 'Clients' : 'Client' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-file-text f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-7">
                  <p class="text-white m-b-0">{{ widget?.client?.actif }} {{ widget?.client?.actif > 1 ? 'Actifs' : 'Actif' }}</p>
                </div>
                <div class="col-5 text-right">
                  <p class="text-white m-b-0">{{ widget?.client?.inactif }} {{ widget?.client?.inactif > 1 ? 'Inactifs' : 'Inactif' }}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- CLIENT END -->

        <!-- LOCATIVE -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-green">{{ widget?.locative?.tout }}</h4>
                <h6 class="text-muted m-b-0">{{ widget?.locative?.tout > 1 ? 'Locatives' : 'Locative' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-7">
                  <p class="text-white m-b-0">{{ widget?.locative?.dispo }} {{ widget?.locative?.dispo > 1 ? 'Disponibles' : 'Disponible' }}</p>
                </div>
                <div class="col-5 text-right">
                  <p class="text-white m-b-0">{{ widget?.locative?.occup }} {{ widget?.locative?.occup > 1 ? 'Occupées' : 'Occupée' }}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- LOCATIVE END -->
      </div>
    </div>

    <!-- DEBUT WIDGET CONTRAT ET MANDAT -->
    <div class="col-xl-6 col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <app-card [hidHeader]="true" cardClass="support-bar overflow-hidden" blockClass="p-0" [isCardFooter]="true" footerClass="bg-primary text-white">
            <div class="p-20 pb-0">
              <h2 class="m-0">{{ widget?.contrat?.tout }}</h2>
              <h6 class="text-muted m-b-0">{{ widget?.contrat?.tout > 1 ? 'Contrats' : 'Contrat' }}</h6>
              <p class="mb-3 mt-3">Total contrat par mois.</p>
            </div>
            <app-apex-chart chartID="support-chart-1" [chartConfig]="this.bandeC"></app-apex-chart>
            <div class="app-card-footer">
              <div class="row text-center">
                <div class="col">
                  <h4 class="m-0 text-white">{{ widget?.contrat?.actif }}</h4>
                  <span>{{ widget?.contrat?.actif > 1 ? 'Actifs' : 'Actif' }}</span>
                </div>
                <div class="col">
                  <h4 class="m-0 text-white">{{ widget?.contrat?.inactif }}</h4>
                  <span>{{ widget?.contrat?.inactif > 1 ? 'Inactifs' : 'Inactif' }}</span>
                </div>
                <div class="col">
                  <h4 class="m-0 text-white">{{ widget?.contrat?.resilie }}</h4>
                  <span>{{ widget?.contrat?.resilie > 1 ? 'Résiliés' : 'Résilié' }}</span>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-sm-6">
          <app-card [hidHeader]="true" cardClass="support-bar overflow-hidden" blockClass="p-0" [isCardFooter]="true" footerClass="bg-success text-white">
            <div class="p-20 pb-0">
              <h2 class="m-0">{{ widget?.mandat?.tout }}</h2>
              <h6 class="text-muted m-b-0">{{ widget?.mandat?.tout > 1 ? 'Mandats' : 'Mandat' }}</h6>
              <p class="mb-3 mt-3">Total mandat par mois.</p>
            </div>
            <app-apex-chart chartID="support-chart-2" [chartConfig]="this.bandeM"></app-apex-chart>
            <div class="app-card-footer">
              <div class="row text-center">
                <div class="col">
                  <h4 class="m-0 text-white">{{ widget?.mandat?.valide }}</h4>
                  <span>{{ widget?.mandat?.valide > 1 ? 'Validés' : 'Validé' }}</span>
                </div>
                <div class="col">
                  <h4 class="m-0 text-white">{{ widget?.mandat?.invalide }}</h4>
                  <span>{{ widget?.mandat?.invalide > 1 ? 'Invalides' : 'Invalide' }}</span>
                </div>
                <div class="col">
                  <h4 class="m-0 text-white">{{ widget?.mandat?.resilie }}</h4>
                  <span>{{ widget?.mandat?.resilie > 1 ? 'Résiliés' : 'Résilié' }}</span>
                </div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>
    <!-- FIN WIDGET CONTRAT ET MANDAT -->

    <!-- GRAPHE -->
    <div class="col-xl-12 col-sm-12 mb-4">
      <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions"
        style="width: 100%; height: 450px; display: block;"></lib-highcharts-angular>
    </div>
    <!-- GRAPHE END -->

    <!-- BIENS -->
    <div class="col-xl-12 col-sm-12">
      <div class="row">
        <!-- BIENS -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" cardClass="bg-c-yellow order-card">
            <h6 class="text-white">{{ widget?.bien > 1 ? 'Biens immobiliers' : 'Bien immobilier' }}</h6>
            <h2 class="text-white">{{ widget?.bien }} </h2>
            <i class="card-icon feather icon-home"></i>
          </app-card>
        </div>
        <!-- BIENS END -->

        <!-- TICKET -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" cardClass="bg-c-green order-card">
            <h6 class="text-white">{{ widget?.ticket > 1 ? 'Tickets' : 'Ticket' }}</h6>
            <h2 class="text-white"> {{ widget?.ticket }}</h2>
            <i class="card-icon feather icon-clipboard"></i>
          </app-card>
        </div>
        <!-- TICKET END -->

        <!-- INTERVENTION -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" cardClass="bg-c-red order-card">
            <h6 class="text-white">{{ widget?.chantier > 1 ? 'Interventions' : 'Intervention' }}</h6>
            <h2 class="text-white">{{ widget?.chantier }}</h2>
            <i class="card-icon feather icon-tag"></i>
          </app-card>
        </div>
        <!-- INTERVENTION END -->

        <!-- FOURNISSEUR -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" cardClass="bg-c-blue order-card">
            <h6 class="text-white">{{ widget?.fournisseur > 1 ? 'Fournisseurs' : 'Fournisseur' }}</h6>
            <h2 class="text-white">{{ widget?.fournisseur}}</h2>
            <i class="card-icon feather icon-user"></i>
          </app-card>
        </div>
        <!-- FOURNISSEUR END -->
      </div>
    </div>
    <!--BIENS END -->

    <!-- 10 DERNIERS LOCATAIRES -->
    <div class="col-xl-6 col-md-12">
      <app-card [hidHeader]="true">
        <div class="col-md-12">
          <span class="badge badge-success my-2 f-14 formBadge width"> Liste des 10 derniers locataires</span>
        </div>
        <div class="col-md-12">
          <div *ngIf="etat && listing?.tenant.length > 0" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Nom et prenoms/Raison sociale</th>
                  <th>Statut</th>
                  <th>Solde</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tenant of listing?.tenant" (click)="showTenant(tenant)">
                  <td class="align-middle">
                    <div class="d-inline-block align-middle">
                      <img *ngIf="tenant?.type === 'PARTICULIER' && tenant?.sexe === 'Masculin'" class="img-radius wid-40 align-top m-r-15" [src]="tenant.photoSrc ? publicUrl+'/'+tenant.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                      <img *ngIf="tenant?.type === 'PARTICULIER' && tenant?.sexe === 'Féminin'" class="img-radius wid-40 align-top m-r-15" [src]="tenant.photoSrc ? publicUrl+'/'+tenant.photoSrc : 'assets/images/avatar-mlle.jpeg'"  onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'"/>
                      <img *ngIf="tenant?.type === 'ENTREPRISE' && tenant?.photoSrc" class="img-radius wid-40 align-top m-r-15" [src]="tenant.photoSrc ? publicUrl+'/'+tenant.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                      <i *ngIf="tenant?.type === 'ENTREPRISE' && !tenant?.photoSrc" class="fas fa-building fa-3x"></i>
                      <div class="d-inline-block">
                        <h6>{{ tenant?.searchableTitle }}</h6>
                        <span class="m-0 d-inline-block align-middle">
                          <i class="fa fa-phone"></i>{{ tenant?.telephone }} - {{ tenant?.type }}
                        </span><br/>
                        <span> Code : {{ tenant?.code }}</span>
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-{{ tenant?.etat === 'ACTIF' ? 'success' : 'danger' }}">{{ tenant?.etat }}</span>
                  </td>
                  <td class="text-warning">{{ tenant?.impaye|number }} {{ global.device }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                  <td class="font-weight-bold font-size-40 text-success"> {{ totalTenant |number}} {{global.device}}</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <app-no-data [title]="'Aucun locataire trouvée'" *ngIf="listing?.tenant && listing?.tenant?.length === 0"></app-no-data>
        </div>
      </app-card>
    </div>
    <!-- 10 DERNIERS LOCATAIRES END-->

    <!-- 10 DERNIERS PROPRIETAIRE -->
    <div class="col-xl-6 col-md-12">
      <app-card [hidHeader]="true">
        <div class="col-md-12">
          <span class="badge badge-warning my-2 f-14 formBadge width"> Liste des 10 derniers bailleurs</span>
        </div>
        <div class="col-md-12">
          <div *ngIf="etat && listing?.owner.length > 0" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Nom et prenoms/Raison sociale</th>
                  <th>Statut</th>
                </tr>
              </thead>
              <tbody>
                <tr  *ngFor="let owner of listing?.owner" (click)="showOwner(owner)">
                  <td class="align-middle">
                    <div class="d-inline-block align-middle">
                      <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Masculin'" class="img-radius wid-40 align-top m-r-15" [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                      <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Féminin'" class="img-radius wid-40 align-top m-r-15" [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-mlle.jpeg'"  onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'"/>
                      <img *ngIf="owner?.type === 'ENTREPRISE' && owner?.photoSrc" class="img-radius wid-40 align-top m-r-15" [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                      <i *ngIf="owner?.type === 'ENTREPRISE' && !owner?.photoSrc" class="fas fa-building fa-3x"></i>
                      <div class="d-inline-block">
                        <h6>{{ owner?.searchableTitle }}</h6>
                        <span class="m-0 d-inline-block align-middle">
                          <i class="fa fa-phone"></i>{{ owner?.telephone }} - {{ owner?.type }}
                        </span><br/>
                        <span> Code : {{ owner?.code }}</span>
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-{{ owner?.etat === 'ACTIF' ? 'success' : 'danger' }}">{{ tenant?.etat }}</span>
                </tr>
              </tbody>
            </table>
          </div>
          <app-no-data class="width" [title]="'Aucun locataire trouvé'" *ngIf="listing?.owner && listing?.owner?.length === 0"></app-no-data>
        </div>
      </app-card>
    </div>
    <!-- 10 DERNIERS PROPRIETAIRE END-->

    <!-- 10 DERNIERS QUITTANCE DE LOYER SOLDE -->
    <div class="col-xl-6 col-md-12">
      <app-card [hidHeader]="true">
        <div class="col-md-12">
          <span class="badge badge-success my-2 f-14 formBadge width"> Liste des 10 dernières factures clients soldées</span>
        </div>
        <div class="col-md-12">
          <div *ngIf="etat && listing?.rentS?.length > 0" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Locataires</th>
                  <th>Locatives</th>
                  <th>Période</th>
                  <th>Montant</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rentS of listing.rentS" (click)="showRent(rentS)">
                  <td>
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-warning">{{rentS?.contract?.tenant?.searchableTitle}}</span> <br />
                      Type : {{rentS?.contract?.tenant?.type}}<br />
                      Téléphone : {{rentS?.contract?.tenant?.telephone}}
                    </p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-warning">{{rentS?.contract?.rental?.libelle}}</span> <br />
                      Superficie : {{rentS?.contract?.rental?.superficie}} m² - {{rentS?.contract?.rental?.piece}} pièce(s): <br />
                      Bailleur : {{rentS?.contract?.rental?.house?.owner?.searchableTitle}}
                    </p>
                  </td>
                  <td>
                    {{rentS?.mois}} <br />
                    <span *ngIf="rentS?.type === 'AVANCE'" class="badge badge-primary">{{rentS?.type}}</span>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-primary font-weight-blod">{{rentS?.invoice?.montant|number}} {{global.device}}</span>
                    </p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" class="font-weight-bold font-size-40 text-right">Total</td>
                  <td class="font-weight-bold font-size-40 text-success"> {{ totalRentS |number}} {{global.device}}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <app-no-data class="width" [title]="'Aucune quittance trouvée'" *ngIf="listing?.rentS && listing?.rentS?.length === 0"></app-no-data>
        </div>
      </app-card>
    </div>
    <!-- 10 DERNIERS QUITTANCE DE LOYER SOLDE END -->

    <!--10 DERNIERS QUITTANCE EN ATTENTE DE PAIEMENT -->
    <div class="col-xl-6 col-md-12">
      <app-card [hidHeader]="true">
        <div class="col-md-12">
          <span class="badge badge-primary my-2 f-14 formBadge width"> Liste des 10 dernières quittances en attente de règlement</span>
        </div>
        <div class="col-md-12">
          <div *ngIf="etat && listing?.rentA?.length > 0" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Locataires</th>
                  <th>Locatives</th>
                  <th>Période</th>
                  <th>Montant</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rentA of listing.rentA" (click)="showRent(rentA)">
                  <td>
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-warning">{{rentA?.contract?.tenant?.searchableTitle}}</span> <br />
                      Type : {{rentA?.contract?.tenant?.type}}<br />
                      Téléphone : {{rentA?.contract?.tenant?.telephone}}
                    </p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-warning">{{rentA?.contract?.rental?.libelle}}</span> <br />
                      Superficie : {{rentA?.contract?.rental?.superficie}} m² - {{rentA?.contract?.rental?.piece}} pièce(s): <br />
                      Bailleur : {{rentA?.contract?.rental?.house?.owner?.searchableTitle}}
                    </p>
                  </td>
                  <td>
                    {{rentA?.mois}} <br />
                    <span *ngIf="rentA?.type === 'AVANCE'" class="badge badge-primary">{{rentA?.type}}</span>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">
                      <span class="text-primary font-weight-blod">{{rentA?.invoice?.montant|number}} {{global.device}}</span>
                    </p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                  <td class="font-weight-bold font-size-40 text-success"> {{ totalRentA |number}} {{global.device}}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <app-no-data class="width" [title]="'Aucune quittance trouvée'" *ngIf="listing?.rentA && listing?.rentA?.length === 0"></app-no-data>
        </div>
      </app-card>
    </div>
    <!-- 10 DERNIERS QUITTANCE EN ATTENTE DE PAIEMENT END -->
  </ng-container>

  <ng-container *ngIf="type === 'OWNER'">
    <!-- WIDGET DES NOMBRES -->
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto"> <i class="icon feather icon-users f-30 text-c-red"></i> </div>
          <div class="col-auto">
            <h6 class="text-muted m-b-10">{{ widget?.nbrP > 1 ? 'Bailleurs' : 'Bailleur' }}</h6>
            <h2 class="m-b-0">{{ widget?.nbrP }}</h2>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto">
            <i class="fas fa-home f-30 text-c-green"></i>
          </div>
          <div class="col-auto">
          <h6 class="text-muted m-b-10">{{ widget?.nbrL > 1 ? 'Locatives' : 'Locative' }}</h6>
          <h2 class="m-b-0">{{ widget?.nbrL }}</h2>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto">
            <i class="icon feather icon-file-text f-30 text-c-yellow"></i>
          </div>
          <div class="col-auto">
            <h6 class="text-muted m-b-10">{{ widget?.nbrM > 1 ? 'Mandats' : 'Mandat' }}</h6>
            <h2 class="m-b-0">{{ widget?.nbrM }}</h2>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto">
            <i class="icon feather icon-credit-card f-30 text-c-blue"></i>
          </div>
          <div class="col-auto">
            <h6 class="text-muted m-b-10">{{ widget?.nbrR > 1 ? 'Reversements' : 'Reversement' }}</h6>
            <h2 class="m-b-0">{{ widget?.nbrR }}</h2>
          </div>
        </div>
      </app-card>
    </div>
    <!-- WIDGET DES NOMBRES END-->

    <!-- WIDGET SECONDAIRES -->
    <div class="col-md-7">
      <div class="row">
        <div class="col-md-12">
          <app-card [hidHeader]="true">
            <div class="row align-items-center m-l-0">
              <div class="col-auto">
                <i class="feather icon-home f-30 text-c-blue"></i>
              </div>
              <div class="col-auto">
                <h6 class="text-muted m-b-10">{{ widget?.nbrH > 1 ? 'Biens' : 'Bien' }}</h6>
                <h2 class="m-b-0">{{ widget?.nbrH }}</h2>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-sm-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-red">{{ widget?.bien?.vente }}</h4>
                <h6 class="text-muted m-b-0">{{ widget?.bien?.vente > 1 ? 'Ventes' : 'Vente' }}</h6>
              </div>
              <div class="col-4 text-right"></div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.bien?.venteO }} {{ widget?.bien?.venteO > 1 ? 'Vendus' : 'Vendu' }}</p>
                </div>
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.bien?.venteR }} {{ widget?.bien?.venteR > 1 ? 'Réservées' : 'Réservée' }}</p>
                </div>
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.bien?.venteD }} {{ widget?.bien?.venteD > 1 ? 'Disponibles' : 'Disponible' }}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-sm-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-yellow">{{ widget?.bien?.loca }}</h4>
                <h6 class="text-muted m-b-0">{{ widget?.bien?.loca > 1 ? 'Locations' : 'Location' }}</h6>
              </div>
              <div class="col-4 text-right"> </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.bien?.locaO }} {{ widget?.bien?.locaO > 1 ? 'Occupées' : 'Occupée' }}</p>
                </div>
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.bien?.locaR }} {{ widget?.bien?.locaR > 1 ? 'Réservées' : 'Réservée' }}</p>
                </div>
                <div class="col-4 ">
                  <p class="text-white m-b-0">{{ widget?.bien?.locaD }} {{ widget?.bien?.locaD > 1 ? 'Disponibles' : 'Disponible' }}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>
    <!-- WIDGET SECONDAIRES -->

    <!-- DIAGRAMME CIRCULAIRE -->
    <div class="col-md-5">
      <app-card cardTitle="DIAGRAMME CIRCULAIRE DES DISPONIBILITÉS" [options]="false">
        <div #doughnutChart>
          <chart id="doughnut_chart" [type]="'doughnut'" [data]="pieChartData" style="height: 345px;"></chart>
        </div>
      </app-card>
    </div>
    <!-- DIAGRAMME CIRCULAIRE -->

    <!-- DIFERRENTS TABLEAU DE LISTING PART 2 -->
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <div class="col-md-12">
              <span class="badge badge-warning my-2 f-14 formBadge width">Liste des 10 derniers propriétaires actifs </span>
            </div>
            <div class="col-md-12">
              <div *ngIf="etat && listing?.ownerA?.length > 0" class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                  <thead class="thead-light">
                  <tr>
                    <th>Nom Prénoms/Raison sociale</th>
                    <th>Profession</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let owner of listing?.ownerA" (click)="showOwner(owner)">
                      <td>
                        <div class="d-inline-block align-middle">
                          <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Masculin'" class="img-radius wid-40 align-top m-r-15" [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                          <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Féminin'" class="img-radius wid-40 align-top m-r-15" [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-mlle.jpeg'"  onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'"/>
                          <img *ngIf="owner?.type === 'ENTREPRISE' && owner?.photoSrc" class="img-radius wid-40 align-top m-r-15" [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                          <i *ngIf="owner?.type === 'ENTREPRISE' && !owner?.photoSrc" class="fas fa-building fa-3x"></i>
                          <div class="d-inline-block">
                            <h6>{{ owner?.searchableTitle }}</h6>
                            <span class="m-0 d-inline-block align-middle">
                              <i class="fa fa-phone"></i>{{ owner?.telephone }} - {{ owner?.type }}
                            </span><br/>
                            <span> Code : {{ owner?.code }}</span>
                          </div>
                        </div>
                      </td>
                      <td>{{ owner?.profession }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-no-data class="width" [title]="'Aucun proprietaire trouvé'" *ngIf="listing?.ownerA && listing?.ownerA?.length === 0"></app-no-data>
            </div>
          </app-card>
        </div>
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <div class="col-md-12">
              <span class="badge badge-danger my-2 f-14 formBadge width"> Liste des 10 derniers mandats validés </span>
            </div>
            <div class="col-md-12">
              <div *ngIf="etat && listing?.mandateV?.length > 0" class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>Bien</th>
                      <th>Type</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let mandate of listing?.mandateV" (click)="showMandate(mandate)">
                      <td>
                        <p class="m-0 d-inline-block align-middle font-16">
                        <p class="m-0 d-inline-block align-middle font-16">
                          <span class="text-warning">{{mandate?.house?.nom}}</span> <br />
                          Superficie : {{mandate?.house?.superficie}} m² <br />
                          Bailleur : {{mandate?.house?.owner?.nom}}
                        </p>
                      </td>
                      <td>
                        <span *ngIf="mandate.type === 'VENTE'" class="badge badge-primary">Vente</span>
                        <span *ngIf="mandate.type === 'LOCATION'" class="badge badge-warning">Location</span>
                      </td>
                      <td class="align-middle">
                        <span *ngIf="mandate?.montantCom" class="text-warning">
                        {{ mandate?.montantCom|number }} {{global.device}}
                        </span>
                        <span *ngIf="!mandate?.montantCom" class="text-warning">Non défini</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-no-data class="width" [title]="'Aucun mandat trouvé'" *ngIf="listing?.mandateV && listing?.mandateV?.length === 0"></app-no-data>
          </div>
          </app-card>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'LOCATION'">
    <!-- WIDGET DES NOMBRES -->
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto"> <i class="icon feather icon-users f-30 text-c-red"></i> </div>
          <div class="col-auto">
            <h6 class="text-muted m-b-10">{{ widget?.info?.nbrL > 1 ? 'Locataires' : 'Locataire' }}</h6>
            <h2 class="m-b-0">{{ widget?.info?.nbrL }}</h2>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto">
            <i class="icon feather icon-file-text f-30 text-c-purple"></i>
          </div>
          <div class="col-auto">
            <h6 class="text-muted m-b-10">{{ widget?.info?.nbrC > 1 ? 'Contrats' : 'Contrat' }}</h6>
            <h2 class="m-b-0">{{ widget?.info?.nbrC }}</h2>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto">
            <i class="icon feather icon-package f-30 text-c-green"></i>
          </div>
          <div class="col-auto">
            <h6 class="text-muted m-b-10">{{ widget?.info?.nbrF > 1 ? 'Factures' : 'Facture' }}</h6>
            <h2 class="m-b-0">{{ widget?.info?.nbrF }}</h2>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
          <div class="col-auto">
            <i class="icon feather icon-credit-card f-30 text-c-blue"></i>
          </div>
          <div class="col-auto">
            <h6 class="text-muted m-b-10">{{ widget?.info?.nbrP > 1 ? 'Paiements' : 'Paiement' }}</h6>
            <h2 class="m-b-0">{{ widget?.info?.nbrP }}</h2>
          </div>
        </div>
      </app-card>
    </div>


    <!-- WIDGET SECONDAIRES -->
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-purple">
            <div class="row align-items-blue">
              <div class="col">
                <h6 class="text-muted m-b-0">{{ widgetTotalTitle }}</h6>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-9">
                  <h4 class="text-white m-b-0">{{ widget?.invoice?.total?.mtnT |number}} {{global.device}}</h4>
                </div>
                <div class="col-3">
                  <h4 class="text-white m-b-0">{{ widget?.invoice?.total?.nbrT }}</h4>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
            <div class="row align-items-blue">
              <div class="col">
                <h6 class="text-muted m-b-0">{{ widgetAttenteTitle }}</h6>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-9">
                  <h5 class="text-white m-b-0">{{ widget?.invoice?.attente?.mtnA |number}} {{global.device}}</h5>
                </div>
                <div class="col-3">
                  <h4 class="text-white m-b-0">{{ widget?.invoice?.attente?.nbrA }}</h4>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="text-muted m-b-0">{{ widgetImpayeTitle }}</h6>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-9">
                  <h5 class="text-white m-b-0">{{ widget?.invoice?.impaye?.mtnI |number}} {{global.device}}</h5>
                </div>
                <div class="col-3">
                  <h4 class="text-white m-b-0">{{ widget?.invoice?.impaye?.nbrI }}</h4>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
            <div class="row align-items-blue">
              <div class="col">
                <h6 class="text-muted m-b-0">{{ widgetCoursTitle }}</h6>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-9">
                  <h5 class="text-white m-b-0">{{ widget?.invoice?.cours?.mtnE | number}} {{global.device}}</h5>
                </div>
                <div class="col-3">
                  <h4 class="text-white m-b-0">{{ widget?.invoice?.cours?.nbrE }}</h4>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="text-muted m-b-0">{{ widgetSoldeTitle }}</h6>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-9">
                  <h5 class="text-white m-b-0">{{ widget?.invoice?.solde?.mtnS |number}} {{global.device}}</h5>
                </div>
                <div class="col-3">
                  <h4 class="text-white m-b-0">{{ widget?.invoice?.solde?.nbrS }}</h4>
                </div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>

    <!-- DIAGRAMME CIRCULAIRE -->
    <div class="col-md-6">
      <app-card cardTitle="DIAGRAMME CIRCULAIRE DES MONTANTS : TOTAL, IMPAYÉ ET PAYÉ" [options]="false">
        <div #doughnutChartLocation>
          <chart id="doughnut_chart_location" [type]="'doughnut'" [data]="pieChartDataLocation" style="height: 290px;"></chart>
        </div>
      </app-card>
    </div>

    <!-- DIAGRAMME EN BAR -->
    <div class="col-md-12 mb-4">
      <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions" style="width: 100%; height: 400px; display: block;"></lib-highcharts-angular>
    </div>

    <!-- DIFERRENTS TABLEAU DE LISTING PART 1 -->
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <div class="col-md-12">
              <span class="badge badge-danger my-2 f-14 formBadge width"> Liste des 10 derniers contrats actifs </span>
            </div>
            <div class="col-md-12">
              <div *ngIf="etat && contractA &&  contractA?.length > 0" class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>Références</th>
                      <th>Locataires</th>
                      <th>Locatives</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let contract of contractA" (click)="showContract(contract)">
                      <td class="align-middle">
                        <p class="m-0 d-inline-block align-middle font-16">{{ contract?.code }}</p>
                      </td>
                      <td>
                        <p class="m-0 d-inline-block align-middle font-16">
                          <span>{{ contract?.tenant?.searchableTitle }}</span>
                        </p>
                      </td>
                      <td>{{ contract?.rental?.libelle }}</td>
                      <td>{{ contract?.type }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-no-data class="width" [title]="'Aucun contrat trouvé'" *ngIf="contractA && contractA?.length === 0"></app-no-data>
            </div>
          </app-card>
        </div>
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <div class="col-md-12">
              <span class="badge badge-warning my-2 f-14 formBadge width"> Liste des 10 prochains contrats à renouveler</span>
            </div>
            <div class="col-md-12">
              <div *ngIf="etat && contractRe &&  contractRe?.length > 0" class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>Références</th>
                      <th>Locataires</th>
                      <th>Locatives</th>
                      <th>Période</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let itemCre of contractRe" (click)="showContract(itemCre)">
                      <td class="align-middle">
                        <p class="m-0 d-inline-block align-middle font-16">{{ itemCre?.code }}</p>
                      </td>
                      <td>
                        <p class="m-0 d-inline-block align-middle font-16">
                          <span>{{ itemCre?.tenant?.searchableTitle }}</span>
                        </p>
                      </td>
                      <td>{{ itemCre?.rental?.libelle }}</td>
                      <td>{{ itemCre?.periode }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-no-data class="width" [title]="'Aucun contrat trouvé'" *ngIf="contractRe && contractRe?.length === 0"></app-no-data>
          </div>
          </app-card>
        </div>
      </div>
    </div>

    <!-- DIFERRENTS TABLEAU DE LISTING PART 2 -->
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <div class="col-md-12">
              <span class="badge badge-success my-2 f-14 formBadge width"> Liste des locataires ayant au moins un impayé </span>
            </div>
            <div class="col-md-12">
              <div *ngIf="etat && tenantI?.length > 0" class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>Nom</th>
                      <th>Impayés</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of tenantI" (click)="showTenant(item)">
                      <td class="align-middle">
                        <p class="m-0 d-inline-block align-middle font-16">
                          <span>{{ item?.searchableTitle }}</span>
                          <span class="m-0 d-inline-block align-middle">
                            <i class="fa fa-phone"></i>{{ item?.telephone }} - {{ item?.type }}
                          </span><br/>
                          <span> Code : {{ item?.code }}</span>
                        </p>
                      </td>
                      <td>{{ item?.impaye|number }} {{ global.device }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="font-weight-bold font-size-40 text-right">Total</td>
                      <td class="font-weight-bold font-size-40"> {{totalTenantI |number}} {{global.device}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <app-no-data class="width" [title]="'Aucun locataire trouvé'" *ngIf="tenantI && tenantI?.length === 0"></app-no-data>
            </div>
          </app-card>
        </div>
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <div class="col-md-12">
              <span class="badge badge-primary my-2 f-14 formBadge width"> Liste des 10 derniers contrats résiliés</span>
            </div>
            <div class="col-md-12">
              <div class="table-responsive">
                <table *ngIf="etat && contractR?.length > 0" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                  <thead class="thead-light">
                  <tr>
                    <th>Référence</th>
                    <th>Nom</th>
                    <th>Type</th>
                    <th>Impayés</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let itemCr of contractR" (click)="showContract(itemCr)">
                      <td class="align-middle">
                        <p class="m-0 d-inline-block align-middle font-16">{{ itemCr?.code }}</p>
                      </td>
                      <td>
                        <p class="m-0 d-inline-block align-middle font-16">
                          <span>{{ itemCr?.searchableTitle }}</span>
                        </p>
                      </td>
                      <td>{{ itemCr?.type }}</td>
                      <td>{{ itemCr?.impaye | number }} {{ global.device }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-no-data class="width" [title]="'Il n\'y a aucun contrat résilié dans notre base de données'" *ngIf="contractR && contractR?.length === 0"></app-no-data>
            </div>
          </app-card>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'PROGRAMME'">
    <!-- ANALYTICS -->
    <div class="col-xl-12 col-sm-12">
      <div class="row">
        <!-- PROMOTION  -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.promotion?.nbr > 1 ? 'Programmes' : 'Programme' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.promotion?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.promotion?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- PROMOTION END -->
        <!-- MAISON OCCUPE -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.houseV?.nbr > 1 ? 'Maisons occupées' : 'Maison occupée' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.houseV?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.houseV?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- MAISON OCCUPE END -->
        <!-- MAISON RESERVER -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.houseR?.nbr > 1 ? 'Maisons réservées' : 'Maison réservée' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-sm-4">
                  <p class="text-white m-b-0">{{ widget?.houseR?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.houseR?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- MAISON OCCUPE END -->
        <!-- MAISON DISPONIBLE -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.houseD?.nbr > 1 ? 'Maisons disponibles' : 'Maison disponible' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-sm-4">
                  <p class="text-white m-b-0">{{ widget?.houseD?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.houseD?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- MAISON DISPONIBLE END -->
        <div class="col-xl-6 col-sm-12 ">
          <div class="row">
            <!-- CHIFFRE AFFAITRE -->
            <div class="col-md-6">
              <app-card [hidHeader]="true" cardClass="bg-c-blue order-card">
                <h6 class="text-white">Chiffre d'affaire</h6>
                <h5 class="text-white">{{ widget?.montantCa |number}} {{global.device}}</h5>
                <i class="card-icon fas fa-piggy-bank"></i>
              </app-card>
            </div>
            <!-- CHIFFRE AFFAITRE END -->
            <!-- MARGE -->
            <div class="col-md-6">
              <app-card [hidHeader]="true" cardClass="bg-c-red order-card">
                <h6 class="text-white">Marge</h6>
                <h5 class="text-white">{{ widget?.montantMa |number}} {{global.device}}</h5>
                <i class="card-icon fas fa-random"></i>
              </app-card>
            </div>
            <!-- MARGE END -->
            <!-- COUT DE CONSTRUCTION -->
            <div class="col-md-6">
              <app-card [hidHeader]="true" cardClass="bg-c-yellow order-card">
                <h6 class="text-white">Coût de construction</h6>
                <h5 class="text-white">{{ widget?.montantCc |number}} {{global.device}}</h5>
                <i class="card-icon fas fa-chevron-up"></i>
              </app-card>
            </div>
            <!-- COUT DE CONSTRUCTION END -->
            <!-- COUT DE REVIEINT -->
            <div class="col-md-6">
              <app-card [hidHeader]="true" cardClass="bg-c-green order-card">
                <h6 class="text-white">Coût de revient</h6>
                <h5 class="text-white">{{ widget?.montantCr |number}} {{global.device}}</h5>
                <i class="card-icon fas fa-chevron-down"></i>
              </app-card>
            </div>
            <!-- COUT DE REVIEINT END -->
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 ">
          <app-card cardTitle="DIAGRAMME CIRCULAIRE D'OCCUPATION DES MAISONS" [options]="false">
            <div #doughnutChartProgramme>
              <chart id="doughnut_chart_programme" [type]="'doughnut'" [data]="pieChartDataProgramme" style="height: 290px;"></chart>
            </div>
          </app-card>
      </div>
      </div>
    </div>

    <!-- GRAPHE -->
    <div class="col-xl-12 col-sm-12 mb-4">
      <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions"
        style="width: 100%; height: 450px; display: block;"></lib-highcharts-angular>
    </div>
    <!-- GRAPHE END -->

    <!--DIAGRAMME DES ECHEANCIERS -->
    <div class="col-xl-12">
      <app-card cardTitle="STATISTIQUES DES ÉCHÉANCIERS" [options]="false">
        <lib-highcharts-angular [Highcharts]="Highcharts" [options]="lineBasicChartOptions" style="width: 100%; height: 350px; display: block;"></lib-highcharts-angular>
      </app-card>
    </div>
    <!-- DIAGRAMME DES ECHEANCIERS END -->

    <!--TABLEAU DES ECHEANCIERS -->
    <div class="col-xl-12 col-md-12">
      <app-card [hidHeader]="true">
        <div class="col-md-12">
          <span class="badge badge-primary my-2 f-14 formBadge width"> Tableau des échéanciers</span>
        </div>
        <div class="col-md-12">
          <div *ngIf="etat && echeanciers?.length > 0" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Périodes</th>
                  <th>Échéanciers (prévisionnel)</th>
                  <th>Échéanciers réglés</th>
                  <th>Pourcentage (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let echeancier of echeanciers">
                  <td>
                    <p class="m-0 d-inline-block align-middle font-16">Échéancier de {{ echeancier?.periode}}</p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">{{ echeancier?.prev |number}} {{global.device}}</p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16"> {{ echeancier?.reg |number}} {{global.device}}</p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16"> {{ echeancier?.prc}} %</p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr></tr>
              </tfoot>
            </table>
          </div>
          <app-no-data class="width" [title]="'Aucun echéancier trouvée'" *ngIf="echeanciers && echeanciers.length === 0"></app-no-data>
        </div>
      </app-card>
    </div>
    <!-- TABLEAU DES ECHEANCIERS END -->
  </ng-container>

  <ng-container *ngIf="type === 'LOTISSEMENT'">
    <!-- ANALYTICS -->
    <div class="col-xl-6 col-sm-12">
      <div class="row">
        <!-- PROMOTION  -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.lotissement?.nbr > 1 ? 'Lotissements' : 'Lotissement' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.lotissement?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.lotissement?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- PROMOTION END -->
        <!-- MAISON OCCUPE -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.lotV?.nbr > 1 ? 'Lots occupés' : 'Lot occupé' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-4">
                  <p class="text-white m-b-0">{{ widget?.lotV?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.lotV?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- MAISON OCCUPE END -->
        <!-- MAISON RESERVER -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.lotR?.nbr > 1 ? 'Lots reservés' : 'Lot reservé' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-sm-4">
                  <p class="text-white m-b-0">{{ widget?.lotR?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.lotR?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- MAISON OCCUPE END -->
        <!-- MAISON DISPONIBLE -->
        <div class="col-md-6">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="text-muted m-b-0">{{ widget?.lotD?.nbr > 1 ? 'Lots disponibles' : 'Lot disponible' }}</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-home f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="row align-items-center">
                <div class="col-sm-4">
                  <p class="text-white m-b-0">{{ widget?.lotD?.nbr }}</p>
                </div>
                <div class="col-8 text-right">
                  <p class="text-white m-b-0">{{ widget?.lotD?.mtn |number}} {{global.device}}</p>
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <!-- MAISON DISPONIBLE END -->
      </div>
    </div>

    <!-- DIAGRAMME CIRCULAIRE -->
    <div class="col-xl-6 col-sm-12">
      <app-card cardTitle="DIAGRAMME CIRCULAIRE D'OCCUPATION DES LOTS" [options]="false">
        <div #doughnutChartLotissement>
          <chart id="doughnut_chart_lotissement" [type]="'doughnut'" [data]="pieChartDataLotissement" style="height: 290px;"></chart>
        </div>
      </app-card>
    </div>

    <!-- GRAPHE -->
    <div class="col-xl-12 col-sm-12 mb-4">
      <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions"
        style="width: 100%; height: 450px; display: block;"></lib-highcharts-angular>
    </div>
    <!-- GRAPHE END -->

    <!--DIAGRAMME DES ECHEANCIERS -->
    <div class="col-xl-12">
      <app-card cardTitle="STATISTIQUES DES ÉCHÉANCIERS" [options]="false">
        <lib-highcharts-angular [Highcharts]="Highcharts" [options]="lineBasicChartOptions" style="width: 100%; height: 350px; display: block;"></lib-highcharts-angular>
      </app-card>
    </div>
    <!-- DIAGRAMME DES ECHEANCIERS END -->

    <!--TABLEAU DES ECHEANCIERS -->
    <div class="col-xl-12 col-md-12">
      <app-card [hidHeader]="true">
        <div class="col-md-12">
          <span class="badge badge-primary my-2 f-14 formBadge width"> Tableau des échéanciers</span>
        </div>
        <div class="col-md-12">
          <div *ngIf="etat && echeanciers?.length > 0" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Période</th>
                  <th>Échéancier (prévisionnel)</th>
                  <th>Échéancier reglé</th>
                  <th>Pourcentage (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let echeancier of echeanciers">
                  <td>
                    <p class="m-0 d-inline-block align-middle font-16">Échéancier de {{ echeancier?.periode}}</p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16">{{ echeancier?.prev |number}} {{global.device}}</p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16"> {{ echeancier?.reg |number}} {{global.device}}</p>
                  </td>
                  <td class="align-middle">
                    <p class="m-0 d-inline-block align-middle font-16"> {{ echeancier?.prc}} %</p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr></tr>
              </tfoot>
            </table>
          </div>
          <app-no-data class="width" [title]="'Aucun echéancier trouvée'" *ngIf="echeanciers && echeanciers.length === 0"></app-no-data>
        </div>
      </app-card>
    </div>
    <!-- TABLEAU DES ECHEANCIERS END -->
  </ng-container>

  <ng-container *ngIf="type === 'CRM'">
  </ng-container>
</div>
