import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '@theme/utils/globals';
import * as Highcharts from 'highcharts';
import HC_drilldown from 'highcharts/modules/drilldown';
HC_drilldown(Highcharts);



@Component({
  selector: 'app-dash-crm-encaissement',
  templateUrl: './dash-crm-encaissement.component.html',
  styleUrls: ['./dash-crm-encaissement.component.scss']
})
export class DashCrmEncaissementComponent implements OnInit {

  public pieChartTag1: CanvasRenderingContext2D;
  public pieChartTag2: CanvasRenderingContext2D;
  global = { country: Globals.country, device: Globals.device };
  widget: any;
  dtOptions: any = {};
  @Input() datas: any;
  encaissements: any;
  encaissementsNo: any;
  barOptions: any;
  
  public Highcharts = Highcharts;
  public pieChartData: any;
  mois: any[];

  constructor() { }

  ngOnInit(): void {
    this.widget = this.datas?.widget
    this.mois = this.datas?.graph?.mois
    this.encaissements = this.datas?.graph?.encaissements
    this.encaissementsNo = this.datas?.graph?.encaissementsNo
    this.dtOptions = Globals.dataTable;
    this.getGraph ()
  }

  getGraph () {

    this.barOptions = {  
      chart: {  
        type: 'column',  
        events: {  
          load: function () {  
            // Désactiver les interactions  
            this.update({  
              tooltip: {  
                enabled: false // Désactive les info-bulles  
              },  
              plotOptions: {  
                series: {  
                  allowPointSelect: false // Désactive la sélection des points  
                }  
              },  
              exporting: {  
                buttons: {  
                  contextButton: {  
                    enabled: false // Désactive le bouton d'exportation  
                  }  
                }  
              },  
            });  
          }  
        },  
      },  
      title: {  
        text: 'Encaissements par mois',  
      },  
      xAxis: {  
        categories: this.mois,
      },  
      yAxis: {  
        min: 0,  
        title: {  
          text: 'Montant (en unités)',  
        },  
      },  
      series: [  
        {
          color: '#f44336',
          name: 'Non encaissé',
          data:this.encaissementsNo,
        },
        {  
          color: '#0e9e4a',
          name: 'Encaissé',  
          data:  this.encaissements,  
        },  
      ],  
      tooltip: {  
        enabled: false, // Additional safety to ensure tooltips are not shown  
      },  
      credits: {  
        enabled: false, // Disable Highcharts credits  
      },  
    }; 
  }

 
}
