<div class="col-md-12" *ngIf="type === 'FINANCEMENT'">
  <span class="badge badge-success mb-4 f-14 width"> Liste des financements </span>
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table  *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th *ngIf="construction">Intervention</th>
            <th>Financeur</th>
            <th>État</th>
            <th>Apport bailleur</th>
            <th>Apport agence</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of fundings">
            <td *ngIf="construction" class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.construction?.searchableTitle}}</span> <br />
                Bien : {{item?.construction?.rental ? item?.construction?.rental?.libelle : item?.construction?.house?.searchableTitle}}
              </p>
            </td>
            <td>{{ item.financeur }}</td>
            <td>
              <span class="badge" [ngClass]="{
              'badge-danger' : item?.etat === 'INVALIDE',
              'badge-success' : item?.etat === 'VALIDE'
              }">{{validation(item?.etat)}}</span>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning font-weight-blod">{{ item?.montantP|number }} {{ global.device }}</span>
              </p>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-primary font-weight-blod">{{ item?.montantA|number }} {{ global.device }}</span>
              </p>
            </td>
            <td class="table-action">
              <button *ngxPermissionsOnly="'FUNDING:SHOW'" (click)="showFunding(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <ng-template ngxPermissionsOnly="FUNDING:EDIT">
                <button *ngIf="item?.etat === 'INVALIDE'" (click)="editFunding(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
              </ng-template>
              <ng-template ngxPermissionsOnly="FUNDING:VALIDATE">
                <button *ngIf="item?.etat === 'INVALIDE'" (click)="validateFunding(item)" type="button" class="btn btn-icon btn-success ml-1" ngbTooltip="Valider">
                  <i class="fas fa-check"></i>
                </button>
              </ng-template>
              <ng-template ngxPermissionsOnly="FUNDING:PRINTER:SHOW">
                <button *ngIf="item?.etat === 'VALIDE'" (click)="printerFunding(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
              </ng-template>
              <button *ngxPermissionsOnly="'FUNDING:DELETE'" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                <i class="feather icon-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td [attr.colspan]="construction? '3' : '2'" class="font-weight-bold font-size-40 text-right">TOTAL</td>
            <td class="font-weight-bold font-size-40 text-warning"> {{ totalP | number }} {{global.device}}</td>
            <td class="font-weight-bold font-size-40 text-primary"> {{ totalA | number }} {{global.device}}</td>
            <td *ngIf="action"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </app-card>
</div>
