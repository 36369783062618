<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence : </span>
        <span class="title-result-show">{{renew?.code }}</span>
      </p>
      <p>
        <span class="title-show">Locataire : </span>
        <span class="title-result-show"> {{ renew?.contract?.tenant?.searchableTitle }} </span>
      </p>
      <p>
        <span class="title-show">Bien : </span>
        <span class="title-result-show"> {{ renew?.contract?.rental?.house?.nom }}</span>
      </p>
      <p>
        <span class="title-show">Locative : </span>
        <span class="title-result-show"> Porte {{ renew?.contract?.rental?.porte}}</span>
      </p>
      <p>
        <span class="title-show">Bailleur : </span>
        <span class="title-result-show"> {{ renew?.contract?.rental?.house?.owner?.searchableTitle }}</span>
      </p>
      <p>
        <span class="title-show">Type contrat : </span>
        <span class="title-result-show"> {{ renew?.contract?.type }}</span>
      </p>
      <p>
        <span class="title-show">État : </span>
        <span class="badge title-show" [ngClass]="{
          'badge-success' : renew?.etat === 'ACTIF',
          'badge-warning' : renew?.etat ===  'INACTIF',
          'badge-danger' : renew?.etat ===  'RESILIE'
          }">{{ renew?.etat}}
        </span>
      </p>
    </div>
    <!-- COL MILLIEU -->
    <div class="flex-fill">
      <p>
        <span class="title-show ">Montant : </span>
        <span class="title-result-show text-warning">{{ renew?.invoice?.montant|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">Loyer : </span>
        <span class="title-result-show text-warning">{{ renew?.loyer|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">Charge : </span>
        <span class="title-result-show text-warning">{{ renew?.charge|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">Date : </span>
        <span class="title-result-show">{{ renew?.date|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
      </p>
      <p>
        <span class="title-show">Date fin : </span>
        <span class="title-result-show">{{ renew?.dateFin|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
      </p>
      <p>
        <span class="title-show">Pourcentage de retard : </span>
        <span class="title-result-show">{{ renew?.prcRetard }}%</span>
      </p>
      <p>
        <span class="title-show">Date limite de paiement : </span>
        <span class="title-result-show">{{ renew?.limite}}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Loyer precedent : </span>
        <span class="title-result-show text-warning">{{ renew?.pLoyer|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">Charge precedente: </span>
        <span class="title-result-show text-warning">{{ renew?.pCharge|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">Date fin precedente: </span>
        <span class="title-result-show">{{ renew?.pDateFin|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
      </p>
      <p>
        <span class="title-show">Pourcentage de retard precedent: </span>
        <span class="title-result-show">{{ renew?.pPrcRetard }}%</span>
      </p>
      <p>
        <span class="title-show">Date limite de paiement : </span>
        <span class="title-result-show">{{ renew?.limite}}</span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{renew?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ renew?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ renew?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{renew?.update }}</span>
      </p>
      <p>
        <span class="title-show">Validé le : </span>
        <span class="title-result-show">{{ renew?.validateAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{renew?.validate }}</span>
      </p>
    </div>
  </div>


  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Désignation</th>
          <th>Prix u.</th>
          <th>Qte</th>
          <th>Tva</th>
          <th>Remise</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of renew?.invoice?.options" >
          <td><p class="m-0 d-inline-block align-middle font-16">{{item?.libelle}}</p></td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.prix|number}} {{global.device}}</span></p>
          </td>
          <td>{{item?.qte}}</td>
          <td>{{item?.tva}} %</td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.remise|number}} {{global.device}}</span></td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{(item?.total)|number}} {{global.device}}</span></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <table class="table table-responsive  invoice-table invoice-total">
        <tbody>
          <tr>
            <th>TOTAL HT : </th>
            <td> {{ renew?.invoice?.montantHt | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL REMISE : </th>
            <td> {{ renew?.invoice?.montantRemise | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL TVA : </th>
            <td> {{ renew?.invoice?.montantTva | number }} {{global.device}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <table class="width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">TOTAL</td>
        <td class="font-weight-bold font-size-default text-right">{{ renew?.invoice?.montant | number }} {{global.device}}</td>
      </tr>
    </tfoot>
  </table>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template *ngxPermissionsOnly="'RENEW:EDIT'">
    <button *ngIf="renew.etat ==='INACTIF'" (click)="editRenew(renew)" class="btn btn-primary">
      Modifier <i class="feather icon-edit-2"></i>
    </button>
  </ng-template>
  <button *ngxPermissionsOnly="'RENEW:PRINTER:SHOW'" (click)="printerRenew(renew)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>

  <button *ngIf="validate && renew?.etat === 'INACTIF'" (click)="activateRenew(renew)"  class="btn btn-success">
    Valider <i class="fas fa-check"></i>
  </button>
</div>
