<div class="col-md-12">
  <div *ngIf="view">
    <div class="row">
      <div class="col-md-4" *ngFor="let item of folders">
          <app-card [hidHeader]="true">
              <div class="row my-2 p-2">
                <div class="text-left">
                    <i class="fas fa-folder-open f-60 text-secondary"></i>
                </div>
                <h5>{{ item.customer?.nom }} - {{ item.customer?.code }}</h5>
                <div class="text-right">
                  <span class="mr-2 badge" [ngClass]="{
                    'badge-light-danger' : item?.invoice?.etat === 'IMPAYÉ',
                    'badge-light-primary' : item?.invoice?.etat === 'ATTENTE',
                    'badge-light-warning' : item?.invoice?.etat === 'EN COURS',
                    'badge-light-success' : item?.invoice?.etat === 'SOLDE'
                    }" title="État de la facture">Facture {{PAYMENT(item?.invoice?.etat)}}
                  </span>
                  <span class="badge badge-{{item.etat === 'VALIDE' ? 'success' : 'danger'}}" title="État du dossier">
                    {{VALIDATION(item.etat)}}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-sm mb-0">
                    <tbody>
                      <tr><td class="bold">Code : N°{{ item.code }}</td></tr>
                      <tr><td class="bold">N° du dossier{{ item.numero }}</td></tr>
                      <tr>
                        <td class="bold">Montant : <span class="text-danger font-weight-bold"><b>{{ item.montant | number }} {{global.device}} </b></span></td>
                      </tr>
                      <tr>
                        <td class="bold">Date : {{ item.date|date: "d MMMM y" : '' : 'fr-FR'}} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="text-right">
                <button (click)="showFolder(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <ng-template >
                  <button *ngIf="item?.etat ==='INVALIDE'" (click)="editFolder(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="FOLDER:ACTIVATE">
                  <button *ngIf="item?.etat === 'INVALIDE'" (click)="activateFolder(item)" type="button" class="btn btn-icon ml-1 btn-success" ngbTooltip="Activer">
                    <i class="fas fa-check"></i>
                  </button>
                </ng-template>
                <button (click)="printerFolder(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
                <button  (click)="certificateFolder(item)" type="button" class="btn btn-icon btn-info ml-1" ngbTooltip="Attestation de solde">
                  <i class="feather icon-file"></i>
                </button>
                <!--<button *ngIf="!item?.invoice?.isStandardized" type="button" (click)="normalize(item?.invoice)" class="btn btn-icon btn-dark ml-1" ngbTooltip="Normaliser la facture">
                  <i class="fas fa-receipt"></i>
                </button>
                <button *ngIf="item?.invoice?.isStandardized" (click)="printerStandardizedInvoice(item?.invoice?.standardized)" type="button" class="btn btn-icon btn-dark ml-1"
                        ngbTooltip="Imprimer la facture normalisée">
                  <i class="feather icon-printer"></i>
                </button>-->
                <ng-template ngxPermissionsOnly="FOLDER:DELETE">
                  <!-- <button *ngIf="item?.etat === 'INVALIDE'" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer"> -->
                    <button  (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
          </app-card>
      </div>
  </div>
  </div>
  <div *ngIf="!view">
    <app-card [hidHeader]="true" cardClass="card-datatable" *ngIf="folders && folders.length > 0">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
            <tr>
              <th>Dossier</th>
              <th>Facture</th>
              <th>Montant</th>
              <th>Date</th>
              <th>Cree le</th>
              <th >Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of folders">
              <td class="align-middle">
                <i class="fas fa-folder-open fa-2x text-secondary"></i>
                <div class="m-0  ml-1 d-inline-block align-middle font-16">
                  <span class="mb-1 mt-3">{{item.customer?.nom }}</span>
                  <p class="mb-1 text-muted">
                    <b><i class="fas fa-qrcode"></i> {{ item?.code }}</b>
                  </p>
                  <p class="mb-1 text-muted">
                    <b>  N° du dossier{{ item.numero }}</b>
                  </p>

                </div>
              </td>
              <td>
                <div class="">
                  <span class=" badge" [ngClass]="{
                    'badge-light-danger' : item?.invoice?.etat === 'IMPAYÉ',
                    'badge-light-primary' : item?.invoice?.etat === 'ATTENTE',
                    'badge-light-warning' : item?.invoice?.etat === 'EN COURS',
                    'badge-light-success' : item?.invoice?.etat === 'SOLDE'
                    }" title="État de la facture"> {{PAYMENT(item?.invoice?.etat)}}
                  </span>
                  <span class="badge badge-{{item.etat === 'VALIDE' ? 'success' : 'danger'}}" title="État du dossier">
                    {{VALIDATION(item.etat)}}
                  </span>
                </div>
              </td>
              <td class="bold"> <span class="text-danger font-weight-bold"><b>{{ item.montant | number }} {{global.device}} </b></span></td>
              <td class="align-middle">{{ item.date|date: "d MMMM y" : '' : 'fr-FR'}} </td>
              <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>


              <td class="table-action">
                <div class="">
                  <button (click)="showFolder(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                    <i class="fas fa-eye"></i>
                  </button>
                  <ng-template >
                    <button *ngIf="item?.etat ==='INVALIDE'" (click)="editFolder(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                      <i class="feather icon-edit-2"></i>
                    </button>
                  </ng-template>
                  <ng-template ngxPermissionsOnly="FOLDER:ACTIVATE">
                    <button *ngIf="item?.etat === 'INVALIDE'" (click)="activateFolder(item)" type="button" class="btn btn-icon ml-1 btn-success" ngbTooltip="Activer">
                      <i class="fas fa-check"></i>
                    </button>
                  </ng-template>
                  <button (click)="printerFolder(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                  <button *ngIf="item?.invoice?.etat === 'SOLDE'" (click)="certificateFolder(item)" type="button" class="btn btn-icon btn-info ml-1" ngbTooltip="Attestation de solde">
                    <i class="feather icon-file"></i>
                  </button>
                  <button (click)="contractFolder(item)" type="button" class="btn btn-icon btn-dark ml-1" ngbTooltip="Contrat">
                    <i class="fas fa-file-contract"></i>
                  </button>
                  <!--
                    <button (click)="engagementFolder(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Engagement">
                    <i class="fas fa-handshake"></i>
                    </button>
                    <button (click)="reglementFolder(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Reglement">
                      <i class="fas fa-file-signature"></i>
                    </button>
                   -->
                  <button *ngIf="item?.type === 'LOYER' && item?.invoice?.etat === 'SOLDE' && !item?.invoice?.isStandardized" type="button" (click)="normalize(item?.invoice)" class="btn btn-icon btn-dark ml-1" ngbTooltip="Normaliser la facture">
                    <i class="fas fa-receipt"></i>
                  </button>
                  <button *ngIf="item?.type === 'LOYER' && item?.invoice?.etat === 'SOLDE' && item?.invoice?.isStandardized" (click)="printerStandardizedInvoice(item?.invoice?.standardized)" type="button" class="btn btn-icon btn-dark ml-1"
                          ngbTooltip="Imprimer l facture normalisée">
                    <i class="feather icon-printer"></i>
                  </button>
                  <ng-template ngxPermissionsOnly="FOLDER:DELETE">
                    <!-- <button *ngIf="item?.etat === 'INVALIDE'" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer"> -->
                      <button  (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                      <i class="feather icon-trash"></i>
                    </button>
                  </ng-template>
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </app-card>


  </div>

</div>
