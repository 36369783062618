import { UserService } from '@service/user/user.service';
import { CategoryService } from '@service/category/category.service';
import { TenantService } from '@service/tenant/tenant.service';
import { Tenant } from '@model/tenant';
import { User } from '@model/user';
import { TicketService } from '@service/ticket/ticket.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Ticket } from '@model/ticket';
import { Component, HostListener, OnInit } from '@angular/core';
import { Globals } from '@theme/utils/globals';
import { UploaderService } from '@service/uploader/uploader.service';
import { ToastrService } from 'ngx-toastr';
import { EmitterService } from '@service/emitter/emitter.service';

@Component({
  selector: 'app-ticket-add',
  templateUrl: './ticket-add.component.html',
  styleUrls: ['./ticket-add.component.scss']
})
export class TicketAddComponent implements OnInit {

  ESCAPE_KEYCODE = 27;
  title: string = ""
  type: string = ""
  edit: boolean = false
  isHidden: boolean = false
  ticket: Ticket
  users: User[]
  user: User
  tenant: Tenant
  form: FormGroup
  emitterSelected?: any;
  serviceSelected?: any;
  categorySelected?: any;
  selectedType?: any = { className: 'Customer', groupName: 'customer', label: 'Client' };
  submit: boolean = false
  required = Globals.required;

  urgenceRow = [
    { label: 'URGENT', value: 'URGENT' },
    { label: 'IMPORTANT', value: 'IMPORTANT' },
    { label: 'INTERMEDIAIRE', value: 'INTERMEDIAIRE' }
  ]
  categoriesRow = [
    { label: 'RENOVATION', value: 'RENOVATION' },
    { label: 'REPARATION', value: 'REPARATION' }
  ]
  typesRow = [
    { label: 'Clients', value: 'CLIENT' },
    { label: 'Bailleurs', value: 'PROPRIETAIRE' },
    { label: 'Locataires', value: 'LOCATAIRE' }
  ]
  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    public userService: UserService,
    public ticketService: TicketService,
    public tenantService: TenantService,
    private uploadService: UploaderService,
    public categoryService: CategoryService
  ) {
    this.edit = this.ticketService.edit
    this.ticket = this.ticketService.getTicket()
    this.title = (!this.edit) ? "Ajouter un ticket" : "Modifier le ticket "
    this.type = this.ticketService.type
    this.newForm()
  }

  ngOnInit(): void {
    this.editForm()
  }
  onTypeChange(val): void {
    switch (val) {
      case 'CLIENT':
        this.selectedType.className = 'Customer';
        this.selectedType.groupName = 'customer';
        this.selectedType.label = 'Client';
        break;

      case 'PROPRIETAIRE':
        this.selectedType.className = 'Owner';
        this.selectedType.groupName = 'owner';
        this.selectedType.label = 'Bailleur';
        break;
      case 'LOCATAIRE':
        this.selectedType.className = 'Tenant';
        this.selectedType.groupName = 'tenant';
        this.selectedType.label = 'Locataire';
        break;
      default:
        break;
    }
  }
  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      urgence: [null, [Validators.required]],
      type: [null, [Validators.required]],
      service: [null, [Validators.required]],
      category: [null, [Validators.required]],
      objet: [null, [Validators.required]],
      description: [null, [Validators.required]],
      emitter: [null, [Validators.required]],
      user: [null, [Validators.required]],
      date: [null, [Validators.required]],
      folders: this.formBuild.array([]),
      folderUuid: [null],
    });
  }
  editForm() {
    if (this.edit) {
      const data = { ...this.ticketService.getTicket() }
      this.form.patchValue(data)
      this.f.folderUuid.setValue(data?.folder ? data?.folder?.uuid : null);
    }
  }
  setEmitterUuid(uuid) {
    this.f.emitter.setValue(uuid);
  }
  setCategoryUuid(uuid) {
    this.f.category.setValue(uuid);
    if (uuid) {
      this.loadCategories();
    } else {

    }
  }
  setServiceUuid(uuid) {
    this.f.service.setValue(uuid);
    if (uuid) {
      this.loadUsers();
    } else {
      this.users = [];
    }
  }

  loadUsers() {
    if (!this.edit) {
      this.userService.getList(null, this.f.service.value).subscribe(res => {
        this.users = res;
        return this.users;
      }, error => { });
    }
  }
  loadCategories() {
    this.categoryService.getList().subscribe(res => {
    }, error => { });
  }
  selectUser(value) {
    this.users = [];
    this.user = null;
    this.f.user.setValue(null);
    if (!this.edit) {
      this.user = this.users.find(item => {
        if (item.uuid === value) {
          this.f.user.setValue(item.uuid);
          return item;
        }
      });
    }
    this.f.user.setValue(value);
  }

  onSearch() {
    this.isHidden = true
  }
  onSubmit() {
    this.submit = true
    if (!this.form.invalid) {
      this.ticketService.add(this.form.value).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          this.emitter.emit({ action: 'TICKET_ADD', payload: res?.data });
        }
      }, error => { });
    } else {
      return
    }
  }
  files(data) {
    if (data && data !== null) {
      data.forEach(item => {
        this.folder.push(
          this.formBuild.group({
            uuid: [item?.uuid, [Validators.required]],
            name: [item?.name],
            path: [item?.path]
          })
        );
      });
    }
  }
  upload(files) {
    for (const file of files) {
      this.uploadService.upload(file);
    }
  }
  setParam(property, value) {
    if (value) {
      if (this.form.value.hasOwnProperty(property)) {
        Object.assign(this.form.value, { [property]: value });
      }
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].setValue(value);
      }
    }
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      this.onClose()
    }
  }
  onClose() {
    if (!this.edit && this.form.value.folderUuid) {
      var data = { uuid: this.form.value.folderUuid, path: 'locative' }
      this.uploadService.getDelete(data).subscribe((res: any) => {
        if (res) {
          if (res?.status === 'success') {
            this.form.reset()
            this.modal.close('ferme');
          }
        }
        return res
      });
    } else {
      this.form.reset()
      this.modal.close('ferme');
    }
  }
  onReset() {
    if (this.form.value.folderUuid) {
      this.toast('Impossible de de vider le formulaire lorsque un upload a été éffectué', 'Une erreur a été rencontrée', 'warning');
    } else {
      this.form.reset()
      this.form.controls['folderUuid'].setValue(null);
    }
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls; }
  get folder() { return this.form.get('folders') as FormArray; }
}
