import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from '@service/filter/filter.service';
import { Globals } from '@theme/utils/globals';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-dash-crm-action',
  templateUrl: './dash-crm-action.component.html',
  styleUrls: ['./dash-crm-action.component.scss']
})
export class DashCrmActionComponent implements OnInit {
  //variable du graph
  public Highcharts = Highcharts;
  public barOptions: any
  public barOptions2: any
  
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;
  dtOptions: { pagingType: string; pageLength: number; lengthMenu: number[]; processing: boolean; language: { processing: string; search: string; lengthMenu: string; info: string; infoEmpty: string; infoFiltered: string; infoPostFix: string; loadingRecords: string; zeroRecords: string; emptyTable: string; paginate: { sFirst: string; sPrevious: string; sNext: string; sLast: string; }; aria: { sSortAscending: string; sSortDescending: string; }; }; searching: boolean; ordering: boolean; };
  pieRow: any;
  @Input() datas: any;
  @Input() data: string;
  table1: any;
  table2: any;
  widget: any;
  contact: any[];
  bureau: any[];
  chantier: any[];
  contrat: any[];
  annulation: any[];
  mois: any[];

  constructor(
    public router: Router,
    private filterService: FilterService,
  ) {
 
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    this.widget = this.datas?.widget
    this.contact = this.datas?.graph1?.contacts
    this.bureau = this.datas?.graph1?.bureau
    this.chantier = this.datas?.graph1?.chantier
    this.contrat = this.datas?.graph1?.contrats
    this.annulation = this.datas?.graph1?.annulations
    this.mois = this.datas?.graph1?.mois
    this.table2 = this.datas?.table2
    console.log("datas",this.datas);
    this.getPie();
    this.getGraph();
   
    
  }
  
  updateCharts() {
      this.getPie(); // Appelle la méthode pour mettre à jour le graphique à secteurs
      this.getGraph(); // Appelle la méthode pour mettre à jour le graphique à barres
  }

  getPie(){
     
  }
  pieChartData = {
    labels: ['Contacts', 'Visite des bureaux', 'Visite des chantiers', 'Contrats envoyés', 'Contrats Signés', 'Annulations'],
    datasets: [{
      data:[3, 2, 1],
      backgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a','#7C94C7FF', '#9A7575FF'],
      hoverBackgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a','#7C94C7FF', '#9A7575FF']
    }]
  };

  getGraph () {

    this.barOptions = {
      chart: {
        type: 'column'
      },
      colors: ['#0e9e4a'],
      title: {
        text: "Diagramme des actions commerciales"
      },
      xAxis: {
        categories: this.mois,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: "Nombre"
        },
        min: 0, // Définit le minimum à 0 pour éviter les valeurs négatives sur l'axe Y
        max: 1000, // Définit le minimum à 0 pour éviter les valeurs négatives sur l'axe Y
        allowDecimals: false, // Évite les décimales si les nombres sont des entiers
        tickInterval: 25
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          color: '#4680ff',
          name: 'Contacts',
          data:this.contact,
        },
        {
          color: '#0e9e4a',
          name: 'Visite des Bureaux',
          data: this.bureau,
        },
        {
          color: '#ffa21d',
          name: 'Visite des chantiers',
          data:this.chantier,
        },
        {
          color: '#7C94C7FF',
          name: 'Contrats envoyés',
          data: this.contrat,
        },
        {
          color: '#9A7575FF',
          name: 'Annulations',
          data: this.annulation,
        }
      ]
    };
    this.barOptions2 = {
      chart: {
        type: 'column'
      },
      colors: ['#0e9e4a'],
      title: {
        text: "Diagramme des encaissements"
      },
      xAxis: {
        categories: (["Janvier","Fevrier","Mars","Avril","Mai","Juin","Juillet"]),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: "XOF"
        },
        min: 0, // Définit le minimum à 0 pour éviter les valeurs négatives sur l'axe Y
        allowDecimals: false, // Évite les décimales si les nombres sont des entiers
        tickInterval: 100000
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
     
    };
  }
}
