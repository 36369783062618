import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { NavigationItem } from '../navigation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextConfig } from '../../../../../app-config';
import { AuthService } from '@service/auth/auth.service';
import { UserService } from '@service/user/user.service';
import { UserEditPasswordComponent } from '@utilisateur/user/user-edit-password/user-edit-password.component';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, AfterViewInit {
  public nextConfig: any = NextConfig.config;
  public navigation: any[];
  public scrollWidth = 0;
  public contentWidth = 0;
  public wrapperWidth: any;
  public prevDisabled = 'disabled';
  public nextDisabled = '';
  public windowWidth: number = window.innerWidth;
  publicUrl = environment.publicUrl;
  user: any;
  isRoot = false
  routes: any = [];

  @Output() onNavMobCollapse = new EventEmitter();
  @ViewChild('navbarContent', { static: false }) navbarContent: ElementRef;
  @ViewChild('navbarWrapper', { static: false }) navbarWrapper: ElementRef;

  constructor(
    public nav: NavigationItem,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal,
    private auth: AuthService,
    private location: Location,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.user = this.auth.getDataToken();
    this.routes = this.auth.getMenuPath() || [];
    this.isRoot = this.auth.getIsRoot();
    //  on verifie si le user est root alors on lui affiche tous les menu sinon on lui affiche les menu dont il a accès
    this.navigation = this.isRoot  ?  this.nav.get() : this.filterMenu(this.nav.get(), this.routes);    
  }

  ngOnInit() {
    if (this.windowWidth < 992) {
      this.nextConfig.layout = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar')?.classList.add('menupos-static');
        (document.querySelector('#nav-ps-next') as HTMLElement).style.maxHeight = '100%';
      }, 500);
    }
  }

  ngAfterViewInit() {
    if (this.nextConfig.layout === 'horizontal') {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }
  filterMenu(menu: any[], accessibleUrls: { url: string }[]): any[] {
    return menu
      .map((item) => {
        // # est mis pour faire la diffrences entre les menus automatique
        if (item.children && item.url !== "#") {
          const filteredChildren = this.filterMenu(item.children, accessibleUrls);          
          return filteredChildren.length ? { ...item, children: filteredChildren } : null;
        } else if (item.url === "#") {          
          return item;
        }
         else if (accessibleUrls.find(route => route.url === item.url)) {          
          return item;
        }
        return null;
      })
      .filter(Boolean);
  }

  scrollPlus() {
    this.scrollWidth += this.wrapperWidth - 80;
    if (this.scrollWidth > this.contentWidth - this.wrapperWidth) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
      this.nextDisabled = 'disabled';
    }
    this.prevDisabled = '';
    (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = `-${this.scrollWidth}px`;
  }

  scrollMinus() {
    this.scrollWidth = Math.max(0, this.scrollWidth - this.wrapperWidth);
    this.prevDisabled = this.scrollWidth === 0 ? 'disabled' : '';
    this.nextDisabled = '';
    (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = `-${this.scrollWidth}px`;
  }

  logout() {
    this.auth.removeDataToken();
    this.auth.removePermissionToken();
    this.router.navigate(['/auth/login']);
    this.document.location.reload();
  }

  profilUser() {
    this.userService.profile = false;
    this.router.navigate([`/admin/user/show/${this.user.uuid}`]);
  }

  editPassword() {
    this.modalService.open(UserEditPasswordComponent, {
      backdrop: 'static',
      size: 'lg'
    });
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }

  navMob() {
    if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
      this.onNavMobCollapse.emit();
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if(up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

}
