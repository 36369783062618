
import { User } from '@model/user';
import { ToastrService } from 'ngx-toastr';
import { Menu } from '@model/menu';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@service/auth/auth.service';
import { DualListComponent } from 'angular-dual-listbox';
import { UserService } from '@service/user/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { EmitterService } from '@service/emitter/emitter.service';
import { UploaderService } from '@service/uploader/uploader.service';
import { MenuService } from '@service/menu/menu.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-menu-assign-to-user',
  templateUrl: './menu-assign-to-user.component.html',
  styleUrls: ['./menu-assign-to-user.component.scss']
})
export class MenuAssignToUserComponent implements OnInit {
  tab = 1;
  keepSorted = true;
  key: string;
  display: string;
  filter = false;
  source: Array<any>;
  confirmed: Array<any>;
  userAdd = '';
  disabled = false;
  sourceLeft = true;
	private DEFAULT_FORMAT = {
    add: 'Ajouter',
    remove: 'Supprimer',
    all: 'Tout sélectionner',
    none: 'Annuler',
    direction:
    DualListComponent.LTR,
    draggable: true
  };
  format: any = this.DEFAULT_FORMAT;
  private sourceStations: Array<any>;
  private confirmedStations: Array<any>;
  private stations: Array<any> = [];

  title: string = ""
  type: string = ""
  edit: boolean = false
  user: User
  form: FormGroup
  menus: Menu[]
  pathRow: any[]
  submit: boolean = false
  required = Globals.required
  serviceSelected?: any;
  civilityRow = [
    {label: 'Monsieur', value: 'Mr'},
    {label: 'Madame', value: 'Mme'},
    {label: 'Mademoiselle', value: 'Mlle'}
  ];

  constructor(
    public toastr: ToastrService,
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    public authService: AuthService,
    private userService: UserService,
    public uploadService: UploaderService,
    public menuService: MenuService
  ) {
    this.edit = this.userService.edit
    this.user = this.userService.getUser()
    this.title = "Attribution des menus à "+ this.user?.nom
    this.newForm()
  }

  ngOnInit(): void {
    this.menuService.getList().subscribe(res => {
      if(res){
        res?.forEach(item => {
          this.stations.push({
            key: item?.id,
            station: item?.libelle,
            state: item?.uuid
          })
        });
        this.editForm()
        this.doReset();
      }
    })
  }

  newForm() {
    const defaults = {
      uuid: [null],
      id: [null],
      type: ["MENU"],
      username: [null, [Validators.required, Validators.pattern(ValidatorsEnums.email)]],
      nom: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(ValidatorsEnums.email)]],
      contact: [null, [Validators.required]],
      roles: this.formBuild.array([]),      
    };
    this.form = this.formBuild.group(defaults);
  }

  editForm() {
    if (this.edit) {
      const data = { ...this.userService.getUser() }
      this.form.patchValue(data)
      this.f.contact.setValue(data?.telephone);
      this.f.type.setValue('MENU');
      this.pathRow = data.menus
    }
  }

  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      const data = this.form.getRawValue();
        this.userService.add(data).subscribe(res => {
          if (res?.status === 'success') {
            this.modal.close('ferme');
            if (data?.uuid) {
              this.authService.setPermissionToken(res)
              this.emitter.emit({action: 'MENU_UPDATED', payload: res?.data});
            } else {
              this.emitter.emit({action: 'MENU_ADD', payload: res?.data});
            }
          }
        });
    } else { return; }
  }
  setData(){
    this.roles.clear();
    this.confirmed.forEach(item =>{
      this.roles.controls.push(
        this.formBuild.group({
          uuid: [item?.state],
          libelle: [item?.station],
        })
      );
    })
  }
  private useStations() {
    this.key = 'key';
    this.display = 'station';
    this.keepSorted = true;
    this.source = this.sourceStations;
    this.confirmed = this.confirmedStations;
  }
  doReset() {
    this.sourceStations = JSON.parse(JSON.stringify(this.stations));
    this.confirmedStations = new Array<any>();
    if(this.edit){
      if(this.pathRow.length > 0){
        this.pathRow.forEach(item => {
          this.stations.forEach((key, i) => {
            if(item.id === key.key){ this.confirmedStations.push(this.stations[i]); }
          })
          this.roles.controls.push(
            this.formBuild.group({
              uuid: [item?.uuid],
              libelle: [item?.libelle],
            })
          );
        })
      }
    }
    this.useStations();
  }
  filterBtn() { return (this.filter ? 'Hide Filter' : 'Show Filter'); }
  doDisable() { this.disabled = !this.disabled; }
  disableBtn() { return (this.disabled ? 'Enable' : 'Disabled'); }
  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }  
  setParam(property, value): void {
    if (value) {
      if (this.form.value.hasOwnProperty(property)) {
        Object.assign(this.form.value, {[property]: value});
      }
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].setValue(value);
      }
    }
  }
  onClose(){
      this.form.reset()
      this.modal.close('ferme');    
  }
  onReset(){
    this.form.reset()
    this.formBuild.array([])
  }
  toast(msg, title, type): void {
    if (type === 'info') {
      this.toastr.info(msg, title);
    } else if (type === 'success') {
      this.toastr.success(msg, title);
    } else if (type === 'warning') {
      this.toastr.warning(msg, title);
    } else if (type === 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls }
  get roles() { return this.form.get('roles') as FormArray; }

}
