<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <div class="d-flex">
      <!-- COL GAUCHE -->
      <div class="flex-fill">
        <p *ngIf="quote?.trustee">
          <span class="title-show">Syndic : </span>
          <span class="title-result-show">
            <span class="badg badge-light-warninge" >{{ quote?.trustee?.nom}}
            </span>
          </span>
        </p>
        <p>
          <span class="title-show">Référence : </span>
          <span class="title-result-show">{{ quote?.code }}</span>
        </p>
        <p>
          <span class="title-show">Libellé : </span>
          <span class="title-result-show">{{ quote.libelle }}</span>
        </p>
        <p>
          <span class="title-show">Intervention : </span>
          <span class="title-result-show">{{ quote?.construction?.nom }}</span>
        </p>
        <p>
          <span class="title-show">{{quote?.type === 'PRESTATION'?'Prestataire':'Fournisseur'}} : </span>
          <span class="title-result-show">{{ quote?.provider?.searchableTitle}}</span>
        </p>
        <p>
          <span class="title-show">Type : </span>
          <span class="title-result-show">
            <span class="badge" [ngClass]="{
              'badge-light-warning' : quote?.type === 'PRESTATION',
              'badge-light-primary' : quote?.type === 'FOURNITURE'
              }">{{ quote?.type}}
            </span>
          </span>
        </p>
        <p *ngIf="quote?.type === 'PRESTATION'">
          <span class="title-show">Montant prestation : </span>
          <span class="title-result-show"> {{ quote?.prestation |number }} {{ global.device }}</span>
        </p>
      </div>
      <!-- COL DROITE -->
      <div class="flex-fill">
        <p *ngIf="quote?.houseCo || quote?.homeCo">
          <span class="title-show">Lot : </span>
          <span class="title-result-show">{{ quote?.houseCo?.nom }}  {{ quote?.homeCo?.nom }}</span>
        </p>
        <p *ngIf="quote?.infrastructure">
          <span class="title-show">Infrastructure : </span>
          <span class="title-result-show">{{ quote?.infrastructure?.nom }}</span>
        </p>
        <p>
          <span class="title-show">Échéance : </span>
          <span class="title-result-show">{{ quote.echeance|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        </p>
        <p>
          <span class="title-show">Date : </span>
          <span class="title-result-show">{{ quote?.date|date: "d MMMM y" : '' : 'fr-FR'}}</span>
        </p>
        <p>
          <span class="title-show">État: </span>
          <span class="title-result-show">
            <span class="badge" [ngClass]="{
              'badge-danger' : quote?.etat === 'INVALIDE',
              'badge-success' : quote?.etat === 'VALIDE'
              }">{{ validation(quote?.etat)}}
            </span>
          </span>
        </p>
        <p>
          <span class="title-show">Crée le : </span>
          <span class="title-result-show">{{ quote?.createdAt | date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{ quote?.create }}</span>
        </p>
        <p>
          <span class="title-show">Modifié le : </span>
          <span class="title-result-show">{{ quote?.updatedAt | date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{ quote?.update }}</span>
        </p>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Désigantion</th>
            <th>Prix u.</th>
            <th>Qte</th>
            <th>Tva</th>
            <th>Remise</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of quote?.options">
            <td>
              <p class="m-0 d-inline-block align-middle font-16">{{item?.libelle}}</p>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.prix|number}}
                  {{global.device}}</span></p>
            </td>
            <td>{{item?.qte}}</td>
            <td>{{item?.tva}} %</td>
            <td>
              <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.remise|number}}
                  {{global.device}}</span>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{(item?.total)|number}}
                  {{global.device}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <table class="table table-responsive  invoice-table invoice-total">
          <tbody>
            <tr>
              <th>Total ht: </th>
              <td> {{ quote?.montantHt | number }} {{global.device}}</td>
            </tr>
            <tr>
              <th>Total remise : </th>
              <td> {{ quote?.montantRemise | number }} {{global.device}}</td>
            </tr>
            <tr>
              <th>Total tva : </th>
              <td> {{ quote?.montantTva | number }} {{global.device}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md" *ngIf="quote && !quote.isBon">
      <span class="badge badge-primary mb-4 f-14 width">Pièces jointes</span>
      <div class="row">
        <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
          <app-folder-uploader (click)="showFile(quote.folder)" [folder]="quote.folder"
            [allowFileUpload]="false" [allowDownload]="true">
          </app-folder-uploader>
        </div>
        <div [ngClass]="file ? 'col-md-8' : ''" *ngIf="file">
          <div class="row">
            <div class="col-sm-12 mb-2">
              <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                <i class="fa fa-arrow-alt-circle-left"></i> Fermer
              </button>
            </div>
          </div>
          <ngx-doc-viewer [url]="file" viewer="url" style="width:100%;height: 64vh;">
          </ngx-doc-viewer>
        </div>
      </div>
    </div>
    <table class="width">
      <tbody>
        <tr class="border-bottom-success">
          <td></td>
          <td colspan="5" class="text-right font-weight-bold"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border border-success">
          <td colspan="5" class="font-weight-bold font-size-default">Total</td>
          <td class="font-weight-bold font-size-default text-right">{{ quote?.montant | number }} {{global.device}}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template *ngxPermissionsOnly="'QUOTE:EDIT'">
    <button *ngIf="quote?.etat === 'INVALIDE'" (click)="editQuote(quote)" class="btn btn-primary">
      Modifier <i class="feather icon-edit-2"></i>
    </button>
  </ng-template>
  <button *ngxPermissionsOnly="'QUOTE:PRINTER:SHOW'" (click)="printerQuote(quote)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>