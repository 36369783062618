<div class="row justify-content-center">  
    <div class="col-md-12 text-center mb-4">  
        <h2 class="badge badge-primary badge badge-primary my-2 f-14 formBadge width">Encaissement</h2>  
    </div>  
</div>  

<div class="row justify-content-center">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-4">  
                <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">  
                    <div class="badge badge-warning position-absolute" style="top: 10px; left: 10px;">  
                        <i class="fas fa-cash-register mr-1"></i> Total  
                    </div>  
                    <div class="row align-items-center p-3">  
                        <div class="col-8">  
                            <h6 class="text-muted m-b-0">Montant a encaissé</h6>  
                        </div>  
                        <div class="col-4 text-right">  
                            <i class="fas fa-money-bill-wave fa-2x"></i>  
                        </div>  
                    </div>  
                    <div class="app-card-footer">  
                        <div class="row align-items-center">  
                            <div class="col-7">  
                                <h5 class="text-white m-0">{{widget?.encaissement?.tout|number}} {{global.device}}</h5>  
                            </div>  
                        </div>  
                    </div>  
                </app-card>  
            </div>  

            <div class="col-md-4">  
                <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">  
                    <div class="badge badge-success position-absolute" style="top: 10px; left: 10px;">  
                        <i class="fas fa-money-check-alt mr-1"></i> Encaissé  
                    </div>  
                    <div class="row align-items-center p-3">  
                        <div class="col-8">  
                            <h6 class="text-muted m-b-0">Montant encaissé</h6>  
                        </div>  
                        <div class="col-4 text-right">  
                            <i class="fas fa-coins fa-2x text-c-blue"></i>  
                        </div>  
                    </div>  
                    <div class="app-card-footer">  
                        <div class="row align-items-center">  
                            <div class="col-7">  
                                <h5 class="text-white m-0">{{widget?.encaissement?.val|number}} {{global.device}}</h5>  
                            </div>  
                        </div>  
                    </div>  
                </app-card>  
            </div>  

            <div class="col-md-4">  
                <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">  
                    <div class="badge badge-danger position-absolute" style="top: 10px; left: 10px;">  
                        <i class="fas fa-receipt mr-1"></i> Non encaissé  
                    </div>  
                    <div class="row align-items-center p-3">  
                        <div class="col-8">  
                            <h6 class="text-muted m-b-0">Montant non encaissé</h6>  
                        </div>  
                        <div class="col-4 text-right">  
                            <i class="fas fa-file-invoice-dollar fa-2x"></i>  
                        </div>  
                    </div>  
                    <div class="app-card-footer">  
                        <div class="row align-items-center">  
                            <div class="col-7">  
                                <h5 class="text-white m-0">{{widget?.encaissement?.nonVal|number}} {{global.device}}</h5>  
                            </div>  
                        </div>  
                    </div>  
                </app-card>  
            </div> 
        </div>
    </div>  

    <div class="col-md-12 mb-4">  
        <h3 class="text-center mt-4">Diagramme des encaissements</h3>  
        <div class="border p-3 bg-light">  
            <lib-highcharts-angular   
                [Highcharts]="Highcharts"   
                [options]="barOptions"   
                style="width: 100%; height: 400px; display: block;">  
            </lib-highcharts-angular>  
        </div>  
    </div>  
</div>