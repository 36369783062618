import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { FilterService } from '@service/filter/filter.service';
import { Globals } from '@theme/utils/globals';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-dash-crm-vente',
  templateUrl: './dash-crm-vente.component.html',
  styleUrls: ['./dash-crm-vente.component.scss']
})
export class DashCrmVenteComponent implements OnInit {

  pieHouseVe = 0
  pieHouseRe = 0
  pieHouseDi = 0

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any;
  public lineBasicChartOptions: any;

  widget : any
  listing : any
  echeanciers : []
  global = {country: Globals.country, device: Globals.device}
  dtOptions: any = {};
  userSession = Globals.user;
  etat: boolean = false
  isPrint: boolean = true;
  publicUrl = environment.publicUrl;
  @Input() datas: any;
  lotV: number = 0
  lotR:number = 0
  lotD:number = 0

  houseV: number = 0
  houseR:number = 0
  houseD:number = 0
  pieChartData: any
  pieChartData1: any

 

  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;

  constructor(
    private filterService: FilterService,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.widget = this.datas?.widget
    this.dtOptions = Globals.dataTable;
    setTimeout(() => {
      this.pieChartData = {  
        labels: ['Maisons disponibles' ,'Maisons réservés','Maisons occupées'], 
        datasets: [{  
          data: [this.datas?.widget?.houseD?.nbr, this.datas?.widget?.houseR?.nbr, this.datas?.widget?.houseV?.nbr], 
          backgroundColor: ['#4caf50', '#ffeb3b','#f44336'],  
          hoverBackgroundColor: ['#66bb6a',  '#fff176','#e57373']  
        }]  
     
      }; 
      
      this.pieChartData1 = {  
        labels: [  'Lots disponibles','Lots réservés ','Lots occupés',],  
        datasets: [{  
          data: [this.datas?.widget?.lotD?.nbr, this.datas?.widget?.lotR?.nbr, this.datas?.widget?.lotV?.nbr], 
          backgroundColor: ['#4caf50', '#ffeb3b','#f44336'],  
          hoverBackgroundColor: ['#66bb6a',  '#fff176','#e57373']  
        }]  
      };
    },300);
 
  }

 
}
