<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence : </span>
        <span class="title-result-show">{{rent?.invoice?.code }}</span>
      </p>
      <p>
        <span class="title-show">Mois : </span>
        <span class="title-result-show">{{ rent?.mois }}</span>
      </p>
      <p>
        <span class="title-show">Type : </span>
        <span class="title-result-show">
          <span class="badge badge-{{ rent?.type === 'AVANCE' ? 'primary': 'success'}}">
            {{rent?.type}}
          </span>
        </span>
      </p>
      <p>
        <span class="title-show">Locataire : </span>
        <span class="title-result-show"> {{ rent?.contract?.tenant?.searchableTitle }} </span>
      </p>
      <p>
        <span class="title-show">Bailleur : </span>
        <span class="title-result-show"> {{ rent?.contract?.rental?.house?.owner?.searchableTitle}}</span>
      </p>
      <p>
        <span class="title-show">Bien : </span>
        <span class="title-result-show"> {{ rent?.contract?.rental?.house?.nom }}</span>
      </p>
      <p>
        <span class="title-show">Locative : </span>
        <span class="title-result-show"> {{ rent?.contract?.rental?.porte }}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Type contrat : </span>
        <span class="title-result-show"> {{ rent?.contract?.type }} </span>
      </p>
      <p>
        <span class="title-show text-warning">Loyer : </span>
        <span class="title-result-show text-warning">{{ rent?.loyer| number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show text-warning">Electricité : </span>
        <span class="title-result-show text-warning">{{ rent?.conso| number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show text-warning">Charge : </span>
        <span class="title-result-show text-warning">{{ rent?.charge| number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show text-warning">Loyer et charge : </span>
        <span class="title-result-show text-warning">{{ rent?.montant| number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">État: </span>
        <span class="title-result-show">
          <span class="badge" [ngClass]="{
            'badge-danger' : rent?.invoice?.etat === 'IMPAYÉ',
            'badge-primary' : rent?.invoice?.etat === 'ATTENTE',
            'badge-warning' : rent?.invoice?.etat === 'EN COURS',
            'badge-success' : rent?.invoice?.etat === 'SOLDE'
            }">{{payment(rent?.invoice?.etat)}}
          </span>
          <span class="ml-1 badge" [ngClass]="{
            'badge-danger' : rent?.contract.etat === 'RESILIE',
            'badge-warning' : rent?.contract.etat === 'INACTIF',
            'badge-primary' : rent?.contract.etat === 'ACTIF'
            }">{{rent?.invoice?.statut}}</span>
        </span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{rent?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ rent?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{rent?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{rent?.update }}</span>
      </p>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Désignation</th>
          <th>Prix u.</th>
          <th>Qte</th>
          <th>Tva</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p class="m-0 d-inline-block align-middle font-16">{{rent?.invoice?.libelle}}</p>
          </td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span
                class="font-weight-blod">{{rent?.invoice?.montant|number}} {{global.device}}</span></p>
          </td>
          <td>1</td>
          <td>0</td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span
                class="font-weight-blod">{{(rent?.invoice?.montant)|number}} {{global.device}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="rent?.invoice?.transactions?.length > 0" class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Référence</th>
          <th>Operateur</th>
          <th>État</th>
          <th>Montant</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of rent?.invoice?.transactions">
          <td>{{item?.reference}}</td>
          <td>
            <span class="badge" [ngClass]="{
                'badge-info' : item?.operator === 'DEBITCARD',
                'badge-primary' : item?.operator === 'WAVE',
                'badge-light-warning' : item?.operator === 'MTN',
                'badge-success' : item?.operator=== 'MOOV',
                'badge-warning' : item?.operator === 'ORANGE'
                }">
              {{item?.operator}}
            </span>
          </td>
          <td>
            <span class="badge" [ngClass]="{
                'badge-primary' : item?.status === 'PROCESSING',
                'badge-danger' : item?.status === 'FAILED',
                'badge-success' : item?.status === 'SUCCEEDED'
                }">
              {{payment(item?.status)}}
            </span>
          </td>
          <td>{{item?.amount|number}} {{global.device}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <table class="table table-responsive invoice-table invoice-total">
        <tbody>
          <tr>
            <th>Total ht: </th>
            <td> {{ rent?.invoice?.montant | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>Total tva : </th>
            <td> {{ rent?.invoice?.montantTva | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>Total payé : </th>
            <td> {{ rent?.invoice?.paye | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>Total impayé : </th>
            <td class="text-danger"> {{ rent?.invoice?.impaye | number }} {{global.device}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <table class="width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">Total</td>
        <td class="font-weight-bold font-size-default text-right">{{ rent?.invoice?.montant | number }}
          {{global.device}}</td>
      </tr>
    </tfoot>
  </table>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template ngxPermissionsOnly="RENT:PRINTER:SHOW">
    <button *ngIf="rent?.type === 'LOYER' && rent?.invoice?.etat === 'SOLDE'" (click)="printerRent(rent)"
      class="btn btn-warning">
      Imprimer <i class="feather icon-printer"></i>
    </button>
  </ng-template>
</div>
