import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { HouseService } from '@service/house/house.service';
import { House } from '@model/house';
import { Owner } from '@model/owner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Mandate } from '@model/mandate';
import { MandateService } from '@service/mandate/mandate.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmitterService } from '@service/emitter/emitter.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { PromotionService } from '@service/promotion/promotion.service';
import { SubdivisionService } from '@service/subdivision/subdivision.service';

@Component({
  selector: 'app-mandate-add',
  templateUrl: './mandate-add.component.html',
  styleUrls: ['./mandate-add.component.scss']
})
export class MandateAddComponent implements OnInit {
  title: string = '';
  type = '';
  form: FormGroup;
  submit: boolean = false;
  edit: boolean = false;
  mandate: Mandate;
  owners?: Owner[];
  isLoadingHouse = false;
  owner: Owner;
  ownerUuid?: null;
  houses?: House[];
  house: House;
  ownerSelected?: any;
  telephoneSelected: string = null;
  required = Globals.required;
  taxeRow = [
    { label: 'Bailleur', value: 'PROPRIETAIRE' },
    { label: 'Agence', value: 'AGENCE' }
  ];
  facturationRow = [
    { label: 'Pourcentage (%)', value: 'PRC' },
    { label: 'Montant fixe (XOF)', value: 'MTN' }
  ];
  commissionRow = [
    {label: 'Après toutes les taxes', value: 'TAXES'},
    {label: 'Sur le total des loyers', value: 'LOYERS'}
  ];
  verseRow = [
    {label: 'Commisson retiré au premier paiement', value: 'TOTALITE'},
    {label: 'Commison retiré au prorata des paiements', value: 'PRORATA'}
  ];
  booleanRow = [
    {label: 'Non', value: false},
    {label: 'Oui', value: true}
  ];

  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private houseService: HouseService,
    private mandateService: MandateService,
    private promotionService: PromotionService,
    private subdivisionService: SubdivisionService,
  ) {
    this.edit = this.mandateService.edit;
    this.mandate = this.mandateService.getMandate();
    this.title = (!this.edit) ? 'Ajouter un mandat' : 'Modifier le mandat ' + this.mandate.type;
    this.newForm();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      ownerUuid: [null],
      isSigned: [false, [Validators.required]],
      type: ['LOCATION', [Validators.required]],
      facturation: ['PRC'],
      house: [null, [Validators.required]],
      date: [null, [Validators.required]],
      dateD: [null, [Validators.required]],
      dateF: [null, [Validators.required]],
      opportunite: ['true', [Validators.required]],
      taxe: [null, [Validators.required]],
      valeur: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number)]],
      commission: [0, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern(ValidatorsEnums.number)]],
      taxeCommission: [false, [Validators.required]],
      montantCom: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number)]],
      intermediaire: [null],
      contactInter: [null],
      verseCom: ['PRORATA'],
      total: [0, [Validators.pattern(ValidatorsEnums.number)]]
    });


    this.form.get('type').valueChanges.subscribe(val => {
      this.setRequirements();
    });
    this.form.get('intermediaire').valueChanges.subscribe(val => {
      this.setIntermediaireContact();
    });
    this.setRequirements();
  }
  editForm() {
    if (this.edit) {
      const data = { ...this.mandateService.getMandate() };
      data.date = DateHelperService.fromJsonDate(data.date);
      data.dateD = DateHelperService.fromJsonDate(data.dateD);
      data.dateF = DateHelperService.fromJsonDate(data.dateF);
      this.setOwnerUuid(data?.house?.owner?.uuid);
      this.selectHouse(data?.house.uuid)
      this.house = data.house;
      this.telephoneSelected = data?.contactInter
      this.ownerSelected = {
        photoSrc: data?.house?.owner?.photoSrc,
        title: data?.house?.owner?.searchableTitle,
        detail: data?.house?.owner?.searchableDetail
      };
      this.form.patchValue(data);
    }
  }

  setContact(event, type: string) {
    let value = null;
    if (type === 'contactInter') {
      value = this.form.get('contactInter').value
    } else if (this.type === 'ENTREPRISE' && type === 'telResponsable') {
      value = this.form.get('telResponsable').value
    } else if (this.type === 'PARTICULIER' && type === 'contactUrgence') {
      value = this.form.get('contactUrgence').value
    }
    let valeur = (this.edit && event === null) ? value : event;
    if (type === 'contactInter') {
      this.form.get('contactInter').setValue(valeur)
    } else if (this.type === 'ENTREPRISE' && type === 'telResponsable') {
      this.form.get('telResponsable').setValue(valeur)
    } else if (this.type === 'PARTICULIER' && type === 'contactUrgence') {
      this.form.get('contactUrgence').setValue(valeur)
    }
  }

  setOwnerUuid(uuid) {
    this.f.ownerUuid.setValue(uuid);
    if (!this.edit) {
      this.loadHouses();
    }
  }
  loadHouses() {
    this.isLoadingHouse = true;
    this.houses = [];
    this.f.commission.setValue(0)
    this.house = null;
    if (!this.f.ownerUuid.value || !this.form.get('type').value) {
      this.isLoadingHouse = false;
      return;
    }
    if (this.f.type.value === "LOCATION" || this.f.type.value === "VENTE") {
      this.houseService.getList(this.f.ownerUuid.value, this.form.get('type').value).subscribe((res: any) => {
        this.isLoadingHouse = false;
        this.houses = res?.filter(res => { if (res?.mandate === null) { return res } });
        return this.houses;
      }, error => {
        this.isLoadingHouse = false;
      });
    }
    if (this.f.type.value === "PROGRAMME") {
      this.promotionService.getList(null, null, null, this.f.ownerUuid.value).subscribe((res ) => {
        this.isLoadingHouse = false;
        this.houses = res?.filter(res => { if (res?.mandate === null) { return res } });
        return this.houses;
      }, error => {
        this.isLoadingHouse = false;
      });
    }
    if (this.f.type.value === "LOTISSEMENT") {
        this.subdivisionService.getList(null, this.f.ownerUuid.value).subscribe((res) => {
        this.isLoadingHouse = false;
        this.houses = res?.filter(res => { if (res?.mandate === null) { return res } });
        return this.houses;
      }, error => {
        this.isLoadingHouse = false;
      });
    }
  }
  selectHouse(value) {
    if (!this.edit) {
      this.house = this.houses.find(item => {
        if (item.uuid === value) {
          this.f.house.setValue(item.uuid);
          return item;
        }
      });
    }
    this.f.house.setValue(value);
  }
  setRequirements() {
    this.form.get('type').setValidators(Validators.required);
    this.form.get('house').setValidators(Validators.required);
    this.form.get('date').setValidators(Validators.required);
    this.form.get('dateD').setValidators(Validators.required);
    this.form.get('dateF').setValidators(Validators.required);
    this.form.get('commission').setValidators([Validators.required, Validators.pattern(ValidatorsEnums.number)]);
    this.clearConditionalRequirements();
    this.setTypeRequirements();
    this.updateValididties();
  }
  clearConditionalRequirements() {
    this.form.get('valeur').clearValidators();
    this.form.get('montantCom').clearValidators();
    this.form.get('taxe').clearValidators();
    this.form.get('taxeCommission').clearValidators();
    this.form.get('contactInter').clearValidators();
  }
  updateValididties() {
    this.form.controls.valeur.updateValueAndValidity();
    this.form.controls.montantCom.updateValueAndValidity();
    this.form.controls.taxe.updateValueAndValidity();
    this.form.controls.taxeCommission.updateValueAndValidity();
    this.form.controls.contactInter.updateValueAndValidity();
  }
  setTypeRequirements() {
    if (this.form.get('type').value === 'VENTE') {
      this.setVenteRequirements();
    }
    if (this.form.get('type').value === 'LOCATION') {
      this.setLocationRequirements();
    }
  }
  setVenteRequirements() {
    this.form.get('valeur').setValidators([Validators.required, Validators.pattern(ValidatorsEnums.number)]);
    this.form.get('montantCom').setValidators([Validators.required, Validators.pattern(ValidatorsEnums.number)]);
  }
  setLocationRequirements() {
    this.form.get('taxe').setValidators([Validators.required]);
    this.form.get('taxeCommission').setValidators([Validators.required]);
  }
  setIntermediaireContact() {
    if (this.form.get('intermediaire').value && this.form.get('intermediaire').value.trim() !== '') {
      this.form.get('contactInter').setValidators([Validators.required]);
    } else {
      this.form.get('contactInter').clearValidators();
    }
    this.form.controls.contactInter.updateValueAndValidity();
  }
  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      this.mandateService.add(this.form.value).subscribe(
        res => {
          if (res?.status === 'success') {
            this.modal.dismiss();
            this.modal.close('ferme');
            this.emitter.emit({ action: this.edit ? 'MANDATE_UPDATED' : 'MANDATE_ADD', payload: res?.data });
          }
          this.emitter.stopLoading();
        },
        error => {
          this.toast(error.message, 'Une erreur a été rencontrée', error.status);
        }
      );
    } else {
      return;
    }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onChangeTotal(champ) {
    var total = 0;
    var montantT = 0;
    var commission = 0;
    if (champ === 'commission') {
      total = ((this.f.commission.value < 0 || this.f.commission.value > 100) && this.f.valeur.value >= 0) ? 0 :
        Math.round((this.f.commission.value / 100) * this.f.valeur.value);
      this.f.montantCom.setValue(total);
      this.f.total.setValue(total);
      return
    } else if (champ === 'montantCom' || champ === 'montantCom') {
      if (parseInt(this.f.montantCom.value) >= 0 && parseInt(this.f.valeur.value) >= 0) {
        if (parseFloat(this.f.valeur.value) > 0) {
          commission = this.round((this.f.montantCom.value * 100) / this.f.valeur.value, 2);
          montantT = Math.round(this.f.montantCom.value + this.f.valeur.value);
          this.f.total.setValue(montantT)
        }
      }
      return this.f.commission.setValue(commission);
    } else if (champ === 'valeur') {
      if (parseFloat(this.f.commission.value) >= 0 && parseFloat(this.f.valeur.value) >= 0) {
        try {
          const current = parseFloat(this.f.montantCom.value);
          const should = Math.round((parseFloat(this.f.valeur.value) * parseFloat(this.f.commission.value)) / 100);
          if (current === 0 && isFinite(should)) {
            this.f.montantCom.setValue(should);

          } else {
          }
        } catch (e) {
        }
      }
      if (parseFloat(this.f.montantCom.value) >= 0 && parseFloat(this.f.valeur.value) >= 0) {
        try {
          const montantCom = parseFloat(this.f.montantCom.value);
          const valeur = parseFloat(this.f.valeur.value);
          const should = (montantCom / valeur) * 100;
          this.f.commission.setValue(should);
          montantT = Math.round(montantCom + valeur);
          this.f.total.setValue(montantT);
        } catch (e) {
        }
      } else {
        this.f.commission.setValue(0);
        this.f.montantCom.setValue(0);
      }
    }
  }
  round(number: number, decimalPlaces: number) {
    const factorOfTen = Math.pow(10, decimalPlaces);
    return Math.round(number * factorOfTen) / factorOfTen;
  }
  onChangeOwner(event) {
    this.owner = this.owners.find(item => {
      if (item.uuid == event) {
        return item;
      }
    });
  }
  isRequired(property): boolean {
    if (this.form.get(property).validator) {
      const validator = this.form.get(property).validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }
  onClose() {
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls; }
}
