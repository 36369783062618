import { Injectable } from '@angular/core';
import { AuthService } from '@service/auth/auth.service';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'gestion',
    title: 'Gestion immobilière',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Tableau de bord',
        type: 'collapse',
        icon: 'fas fa-chart-line',
        children: [
          {
            id: 'default',
            title: 'Principal',
            type: 'item',
            url: '/admin/dashboard/dash',
            breadcrumbs: true,
          },
          {
            id: 'proprietaire',
            title: 'Bailleurs',
            type: 'item',
            url: '/admin/dashboard/proprietaire',
            breadcrumbs: true,
          },
          {
            id: 'locataire',
            title: 'Locataires',
            type: 'item',
            url: '/admin/dashboard/locataire',
            breadcrumbs: true,
          },
          {
            id: 'promotion',
            title: 'Programmes',
            type: 'item',
            url: '/admin/dashboard/promotion',
            breadcrumbs: true,
          },
          {
            id: 'lotissement',
            title: 'Lotissements',
            type: 'item',
            url: '/admin/dashboard/lotissement',
            breadcrumbs: true,
          },
          {
            id: 'crm',
            title: 'CRM',
            type: 'item',
            url: '/admin/dashboard/crm',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'validation',
        title: 'Mes Validations',
        type: 'item',
        icon: 'feather icon-check-square',
        url: '/admin/validation/mes-validation',
        breadcrumbs: true,
        classes: 'nav-item',
      },
      {
        id: 'crm',
        title: 'CRM',
        type: 'collapse',
        icon: 'feather icon-share-2',
        children: [
          {
            id: 'prospect-vente',
            title: 'Prospect Vente',
            type: 'item',
            url: '/admin/prospection/vente',
            breadcrumbs: true,
          },
          {
            id: 'prospect-location',
            title: 'Prospect Location',
            type: 'item',
            url: '/admin/prospection/location',
            breadcrumbs: true,
          },
          {
            id: 'parametre',
            title: 'Paramètres',
            type: 'item',
            url: '/admin/prospection/parametre',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'tiers',
        title: 'Locations et Ventes',
        type: 'collapse',
        icon: 'fas fa-child',
        children: [
          {
            id: 'bien',
            title: 'Biens',
            type: 'collapse',
            breadcrumbs: true,
            url: '#',
            children: []
          },
          {
            id: 'proprietaire',
            title: 'Bailleurs',
            type: 'item',
            url: '/admin/proprietaire',
            breadcrumbs: true,
          },
          {
            id: 'locataire',
            title: 'Locataires',
            type: 'item',
            url: '/admin/locataire',
            breadcrumbs: true,
          },
          {
            id: 'client',
            title: 'Clients',
            type: 'item',
            url: '/admin/gestion/client',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'patrimoines',
        title: 'Programmes immobiliers',
        type: 'collapse',
        icon: 'fas fa-city',
        children: [
          {
            id: 'client',
            title: 'Clients',
            type: 'item',
            url: '/admin/client',
            breadcrumbs: true,
          },
          {
            id: 'promotion',
            title: 'Programmes',
            type: 'collapse',
            breadcrumbs: true,
            url: '#',
            children: []
          },
          {
            id: 'lotissement',
            title: 'Lotissements',
            type: 'collapse',
            url: '#',
            breadcrumbs: true,
            children: []
          },
          {
            id: 'promotion',
            title: 'Configurations Programmes',
            type: 'item',
            url: '/admin/promotion',
            breadcrumbs: true
          },
          {
            id: 'lotissement',
            title: 'Configurations Lotissements',
            type: 'item',
            url: '/admin/lotissement',
            breadcrumbs: true
          },
        ],
      },
      {
        id: 'cartographies',
        title: 'Cartographies',
        type: 'collapse',
        icon: 'feather icon-map',
        children: [
          {
            id: 'lotissement',
            title: 'Lotissements',
            type: 'item',
            url: '/outils/geo-localisation/null/LOTISSEMENT/LIST',
            breadcrumbs: true,
          },
          {
            id: 'patrimoines',
            title: 'Patrimoines',
            type: 'item',
            url: '/outils/geo-localisation/null/PATRIMOINE/LIST',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'intervention',
        title: 'Moyens généraux',
        type: 'collapse',
        icon: 'fas fa-paint-roller',
        breadcrumbs: true,
        children: [
          {
            id: 'prestataire',
            title: 'Fournisseur et Prestataire',
            type: 'item',
            url: '/admin/prestataire',
            breadcrumbs: true,
          },
          {
            id: 'intervention',
            title: 'Intervention',
            type: 'item',
            url: '/admin/intervention',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'tresorerie',
        title: 'Trésorerie',
        type: 'collapse',
        icon: 'fas fa-piggy-bank',
        breadcrumbs: true,
        children: [
          {
            id: 'tresorerie',
            title: 'Trésorerie ',
            type: 'item',
            url: '/admin/tresorerie',
            breadcrumbs: true,
          },
          {
            id: 'demande',
            title: 'Demandes ',
            type: 'item',
            url: '/admin/demande',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'rapport',
        title: 'Rapport',
        type: 'collapse',
        icon: 'fas fa-file-alt',
        breadcrumbs: true,
        children: [
          {
            id: 'locataire',
            title: 'Locataire',
            type: 'item',
            url: '/admin/rapport/locataire',
            breadcrumbs: true,
          },
          {
            id: 'proprietaire',
            title: 'Bailleurs',
            type: 'item',
            url: '/admin/rapport/proprietaire',
            breadcrumbs: true,
          },
          {
            id: 'client',
            title: 'Clients',
            type: 'item',
            url: '/admin/rapport/client',
            breadcrumbs: true,
          },
          {
            id: 'crm',
            title: 'CRM',
            type: 'item',
            url: '/admin/rapport/crm',
            breadcrumbs: true,
          },
          {
            id: 'depense',
            title: 'Dépenses',
            type: 'item',
            url: '/admin/rapport/depense',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'validation',
        title: 'État financier',
        type: 'item',
        icon: 'feather icon-shuffle',
        url: '/admin/finance/etat-financier',
        breadcrumbs: true,
        classes: 'nav-item',
      },
    ],
  },
  {
    id: 'gestion',
    title: 'MODULE SYNDIC',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'tiers',
        title: 'Syndic',
        type: 'item',
        url: '/admin/syndic',
        icon: 'feather icon-box',
        classes: 'nav-item',
      },
      // {
      //   id: 'crm',
      //   title: 'Copropriétaire',
      //   type: 'item',
      //   url: '/admin/proprietaire',
      //   icon: 'feather icon-users',
      //   classes: 'nav-item',
      // },
      {
        id: 'crm',
        title: 'Budget',
        type: 'item',
        icon: 'feather icon-layers',
        url: '/admin/budget',
        classes: 'nav-item',
      },
      // {
      //   id: 'crm',
      //   title: 'Assemblée générale',
      //   type: 'item',
      //   icon: 'feather icon-compass',
      //   url: '/admin/assemblee',
      //   classes: 'nav-item',
      // },
      {
        id: 'crm',
        title: 'Comptabilité',
        type: 'collapse',
        icon: 'fas fa-receipt',
        children: [
          {
            id: 'ventilation',
            title: 'Ventilation',
            type: 'item',
            url: '/admin/comptabilite/ventilation',
            breadcrumbs: true,
          },
          {
            id: 'achat',
            title: 'Enregistrement compta...',
            type: 'item',
            url: '/admin/comptabilite/achat',
            breadcrumbs: true,
          },
          {
            id: 'journaux',
            title: 'Journaux',
            type: 'item',
            url: '/admin/comptabilite/journaux',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'crm',
        title: 'Trésorerie',
        type: 'item',
        icon: 'feather icon-server',
        url: '/admin/tresorerie',
        classes: 'nav-item',
      },
    ],
  },
  {
    id: 'extra',
    title: 'EXTRA',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'extra',
        title: 'Extra',
        type: 'collapse',
        icon: 'feather icon-map',
        children: [
          {
            id: 'mapp',
            title: 'Géolocalisation des biens',
            type: 'item',
            url: '/admin/extra/maps',
            breadcrumbs: true,
          },
          {
            id: 'calendrier',
            title: 'Calendrier',
            type: 'item',
            url: '/admin/extra/calendrier',
            breadcrumbs: true,
          },
          {
            id: 'send',
            title: 'Envoi Mail/Sms',
            type: 'item',
            url: '/admin/extra/send',
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'ticket',
        title: 'Réclamation',
        type: 'collapse',
        icon: 'fas fa-ticket-alt',
        children: [
          {
            id: 'ticket',
            title: 'Faire une réclamation',
            type: 'item',
            url: '/admin/ticket',
            breadcrumbs: true,
          },
        ],
      },
    ],
  },
  {
    id: 'securite',
    title: 'SECURITE',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'utilisateur',
        title: 'Utilisateur',
        type: 'collapse',
        icon: 'fas fa-user-check',
        children: [
          {
            id: 'user',
            title: 'Utilisateur',
            type: 'item',
            url: '/admin/user',
            breadcrumbs: true,
          },
        ],
      },
    ],
  },
];

@Injectable()
export class NavigationItem {
  token: any
  proRow = []
  lotRow = []
  houRow = []
  constructor(private authService: AuthService) {
    this.token = this.authService.getDataToken() ? this.authService.getDataToken() : null;

    this.proRow = this.token?.promotions?.length > 0 ? this.token?.promotions : []
    this.lotRow = this.token?.lotissements?.length > 0 ? this.token?.lotissements : []
    this.houRow = this.token?.houses?.length > 0 ? this.token?.houses : []
  }

  public get() {
    if (this.token) {
      NavigationItems.forEach((item: any) => {
        if (item?.id === "gestion" && item?.title === "Gestion immobilière") {
          item?.children.forEach((el: any) => {
            if (el?.id === "patrimoines" && el?.title === "Programmes immobiliers") {
              el?.children.forEach((key: any) => {
                if (key?.id === "promotion" && key?.title === "Programmes") {
                  if (this.proRow?.length > 0) {
                    this.proRow?.forEach((pro: any) => {
                      const types: any[] = pro?.types
                      const arry = []
                      if (types?.length > 0) {
                        types?.forEach((typ: any) => {
                          arry.push({
                            id: 'client',
                            title: typ?.libelle,
                            type: 'item',
                            url: '/admin/promotion/landing/' + pro?.uuid + '/' + typ?.uuid,
                            breadcrumbs: true,
                          })
                        });
                      }

                      // Vérifie si un élément avec ce titre existe déjà dans children
                      const exists = key.children.some((child: any) => child?.title === pro?.libelle);
                      if (!exists) {
                        key?.children.push({
                          id: 'client',
                          title: pro?.libelle,
                          type: 'collapse',
                          breadcrumbs: true,
                          children: arry
                        })
                      }
                    });
                  }
                }

                if (key?.id === "lotissement" && key?.title === "Lotissements") {
                  if (this.lotRow?.length > 0) {
                    this.lotRow.forEach(lot => {
                      // Vérifie si un élément avec ce titre existe déjà dans children
                      const exists = key?.children.some((child: any) => child?.title === lot?.libelle);
                      if (!exists) {
                        key?.children.push({
                          id: 'client',
                          title: lot?.libelle,
                          type: 'item',
                          url: '/admin/lotissement/landing/' + lot?.uuid,
                          breadcrumbs: true
                        })
                      }
                    });
                  }
                }
              });
            }
            if (el?.id === "tiers" && el?.title === "Locations et Ventes") {
              el?.children.forEach((key: any) => {
                if (key?.id === "bien" && key?.title === "Biens") {
                  if (this.houRow?.length > 0) {
                    this.houRow.forEach(ho => {
                      // Vérifie si un élément avec ce titre existe déjà dans children
                      const exists = key?.children.some((child: any) => child?.title === ho?.libelle);
                      if (!exists) {
                        key?.children.push({
                          id: 'bien',
                          title: ho?.libelle,
                          type: 'item',
                          url: '/admin/proprietaire/bien/show/' + ho?.uuid,
                          breadcrumbs: true
                        })
                      }
                    });
                  }
                }
              });
            }
          });
        }
      });
    }
    return NavigationItems;
  }
}