import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { environment } from '@env/environment';
import { ApiService } from '@theme/utils/api.service';
import { AuthService } from '@service/auth/auth.service';
import { NoInternetHelper } from '@theme/utils/no-internet-helper';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  user: any;
  public type: string = "";
  public filter: any;
  public result: any;
  private urlBase = environment.publicUrl;
  private namespace = "agency/finance";
  private url = "private/agency/finance";

  constructor(
    private api: ApiService,
    private auth: AuthService,
  ) {
    this.user = this.auth.getDataToken() ? this.auth.getDataToken() : null;
  }

  getPrinter(
    agencyKey: string, userKey: string, type: string, owner: string, tenant: string, customer: string, user: string, house: string,
    promotion: string, subdivision: string, tresorerie: string, dateD: string, dateF: string, min: string, max: string, ordre: string, count: any
  ): void {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return ;
    }

    let url = 'printer/' + this.namespace + '/' + agencyKey + '/' + userKey + '/' + type + '/' + owner + '/' + tenant + '/' + customer + '/' + user + '/' + house
    + '/' + promotion + '/' + subdivision + '/' + tresorerie + '/' + dateD + '/' + dateF + '/' + min + '/' + max + '/' + ordre + '/' + count;
    window.open(`${this.urlBase}/${url}`, '_blank');
  }

  getGenerer(){
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }
    var url = this.urlBase + '/import/agency/model/finance'
    window.open(`${url}`);
  }

  getExport(
    agencyKey: string, userKey: string, type: string, owner: string, tenant: string, customer: string, user: string, house: string,
    promotion: string, subdivision: string, tresorerie: string, dateD: string, dateF: string, min: string, max: string, ordre: string, count: any
  ){
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return;
    }

    let url = 'export/finance/' + agencyKey + '/' + userKey + '/' + type + '/' + owner + '/' + tenant + '/' + customer + '/' + user + '/' + house
    + '/' + promotion + '/' + tresorerie + '/' + dateD + '/' + dateF +'/' + min + '/' + max + '/' + ordre + '/' + count;
    window.open(`${this.urlBase}/${url}`);
  }

  import(data){
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }
    var url = 'private/import/agency'
    return this.api._post(`${url}/finance`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
}
