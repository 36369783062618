<div class="row justify-content-center">
  <!-- WIDGETS -->
  <div class="col-md-12">
    <div class="row">
      <!-- PROPECT -->
      <div class="col-md-3">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
          <div class="row align-items-center">
            <div class="col-8">
              <h4 class="text-c-red"> {{ widget?.nbPr }} </h4>
              <h6 class="text-muted m-b-0"> {{ widget?.nbPr > 1 ? 'Prospects' : 'Prospect' }}</h6>
            </div>
            <div class="col-4 text-right">
              <i class="feather icon-user f-28"></i>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="d-flex justify-content-between align-items-center">
              <p class="text-white m-b-0"><b>{{ widget?.nbPrAc }} </b> {{ widget?.nbPrAc > 1 ? 'Achats' : 'Achat' }} </p>
              <p class="text-white m-b-0"><b>{{ widget?.nbPrLo }} </b>{{ widget?.nbPrLo > 1 ? 'Locations' : 'Location' }} </p>
            </div>
          </div>
        </app-card>
      </div>

      <div class="col-md-3">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
          <div class="row align-items-center">
            <div class="col-8">
              <h4 class="text-c-blue"> {{ widget?.nbRe }} </h4>
              <h6 class="text-muted m-b-0">{{ widget?.nbRe > 1 ? 'Pré-réservations' : 'Pré-réservation' }} </h6>
            </div>
            <div class="col-4 text-right">
              <i class="feather icon-user f-28"></i>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="d-flex justify-content-between align-items-center">
              <p class="text-white m-b-0"><b>{{ widget?.nbReAc }} </b>{{ widget?.nbReAc > 1 ? 'Achats' : 'Achat' }}</p>
              <p class="text-white m-b-0"><b>{{ widget?.nbReLo }} </b> {{ widget?.nbReLo > 1 ? 'Locations' : 'Location' }}</p>
            </div>
          </div>
        </app-card>
      </div>
 
    </div>
  </div>

  <!-- DIAGRAMME DES ACTIONS COMMERCIALES-->
  <div class="col-md-12 mb-4">
    <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barOptions" style="width: 100%; height: 400px; display: block;"></lib-highcharts-angular>
  </div>

  <!--SYNTHESES DES ACTIONS COMMERCIALES PAR PROGRAMME-->
  <div class="col-xl-12 col-md-12">
    <app-card [hidHeader]="true">
      <div class="col-md-12">
        <h5>Synthèses des actions commerciales par programmes</h5>
        <div class="table-responsive mb-0">
          <table datatable [dtOptions]="dtOptions" class="table table-sm table-bordered nowrap table-hover mb-0">
            <thead>
              <tr class="bg-light">
                <th colspan="8" class="text-center">Action commerciale</th>
              </tr>
              <tr>
                <th >Commerciale</th>
                <th >Prospects enregistrés</th>
                <th >Prospects archivés</th>
                <th >Prospects conclus</th>
                <th >Appel téléphonique</th>
                <th >Mail</th>
                <th >Visite des bureaux</th>
                <th >Visite des chantiers</th>
              </tr>
            </thead>

            <tbody>
              <tr >
                <td >SINAFOU KOULIBALI</td>
                <td >BARAKISSA KONE </td>
                <td >OUI</td>
                <td >NON</td>
                <td >0000000</td>
                <td >koneAi@gmail.com</td>
                <td >00000000</td>
                <td >SOZANI</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
    </app-card>
  </div>
  <!-- SYNTHESES DES ACTIONS COMMERCIALES PAR PROGRAMME END -->

  <!--TABLEAU RECAPITULATIF -DES ACTIONS COMMERCIALES -->
  <div class="col-xl-6 col-md-6">
    <app-card [hidHeader]="true">
      <div class="col-md-12">
        <h5>Tableau récapitulatif des actions commerciales </h5>
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" class="table table-sm table-bordered nowrap table-hover">
            <tbody>
              <tr>
                <td >Contacts</td>
                <td >{{ table2?.contacts }} </td>
              </tr>
              <tr>
                <td >Visite des bureaux</td>
                <td >{{ table2?.bureau }} </td>
              </tr>
              <tr>
                <td >Visites des chantiers</td>
                <td >{{ table2?.chantier }} </td>
              </tr>
              <tr>
                <td >Contrats envoyés</td>
                <td >{{ table2?.contrats }}</td>
              </tr>
              <tr>
                <td >Annulations</td>
                <td >{{ table2?.annulations }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-card>
  </div>
  <!-- TABLEAU RECAPITULATIF -DES ACTIONS COMMERCIALES -->

  <!-- DIAGRAMME CIRCULAIRE -->
  <div class="col-md-6">
    <app-card cardTitle="Diagramme des actions commerciales" [options]="false">
      <div #doughnutChart>
        <chart id="doughnut_chart" [type]="'doughnut'" [data]="pieChartData" style="height: 290px;"></chart>
      </div>
    </app-card>
  </div>

  <!-- DIAGRAMME DES ENCAISSEMENTS
  <div class="col-md-12 mb-4">
    <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barOptions2" style="width: 100%; height: 400px; display: block;"></lib-highcharts-angular>
  </div>-->

  <!--SYNTHESES DES VENTE PAR PROGRAMME
  <div class="col-xl-12 col-md-12">
    <app-card [hidHeader]="true">
      <div class="col-md-12">
        <h5>SYNTHESES DES VENTE PAR PROGRAMME et TYPE DE MAISON</h5>
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th colspan="9" class="text-center">PROGRAMME TOGBIN DAHO</th>
              </tr>
              <tr class="bg-success">
                <th >Comerciales</th>
                <th >F3</th>
                <th >F4</th>
                <th >F8</th>
                <th >F0</th>
                <th >V</th>
                <th >Duplex</th>
                <th >TOTAL</th>
                <th >PRC (%)</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td >Maman dibole</td>
                <td >100 </td>
                <td >12</td>
                <td >345</td>
                <td >90</td>
                <td >7</td>
                <td >6</td>
                <td >7</td>
                <td >6</td>
              </tr>
              <tr>
                <td >Maman dibole</td>
                <td >100 </td>
                <td >12</td>
                <td >345</td>
                <td >90</td>
                <td >7</td>
                <td >6</td>
                <td >7</td>
                <td >6</td>
              </tr>
              <tr>
                <td >Maman dibole</td>
                <td >100 </td>
                <td >12</td>
                <td >345</td>
                <td >90</td>
                <td >7</td>
                <td >6</td>
                <td >7</td>
                <td >6</td>
              </tr>
              <tr>
                <td >Maman dibole</td>
                <td >100 </td>
                <td >12</td>
                <td >345</td>
                <td >90</td>
                <td >7</td>
                <td >6</td>
                <td >7</td>
                <td >6</td>
              </tr>
              <tr>
                <td >Maman dibole</td>
                <td >100 </td>
                <td >12</td>
                <td >345</td>
                <td >90</td>
                <td >7</td>
                <td >6</td>
                <td >7</td>
                <td >6</td>
              </tr>
              <tr>
                <td >Maman dibole</td>
                <td >100 </td>
                <td >12</td>
                <td >345</td>
                <td >90</td>
                <td >7</td>
                <td >6</td>
                <td >7</td>
                <td >6</td>
              </tr>
              <tr>
                <td >Maman dibole</td>
                <td >100 </td>
                <td >12</td>
                <td >345</td>
                <td >90  </td>
                <td >7</td>
                <td >6</td>
                <td >7</td>
                <td >6</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-card>
  </div>
  SYNTHESES DES VENTE PAR PROGRAMME -->
</div>