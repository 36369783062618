import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { HouseService } from '@service/house/house.service';
import { House } from '@model/house';
import { Owner} from '@model/owner';
import { RentalService } from '@service/rental/rental.service';
import { Rental } from '@model/rental';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, HostListener, OnInit } from '@angular/core';
import { UploaderService } from '@service/uploader/uploader.service';
import { ToastrService } from 'ngx-toastr';
import { EmitterService } from '@service/emitter/emitter.service';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-rental-add',
  templateUrl: './rental-add.component.html',
  styleUrls: ['./rental-add.component.scss']
})
export class RentalAddComponent implements OnInit {

  ESCAPE_KEYCODE = 27;
  title: string = '';
  form: FormGroup;
  submit: boolean = false;
  edit: boolean = false;
  ownerUuid ?: null;
  isLoadingHouse = false;
  rental: Rental;
  owners: Owner[];
  owner: Owner;
  houses: House[];
  house: House;
  required = Globals.required;
  global = {country: Globals.country, device: Globals.device}
  typeRow = [
    {
      label: 'Habitation',
      type: [
        {label: 'Studio', value: 'STUDIO'},
        {label: 'Appartement', value: 'APPARTEMENT'},
        {label: 'Palier', value: 'PALIER'},
        {label: 'Villa', value: 'VILLA'}
      ]
    },
    {
      label: 'Commercial',
      type: [
        {label: 'Magasin', value: 'MAGASIN'},
        {label: 'Kiosque', value: 'KIOSQUE'},
        {label: 'Bureau', value: 'BUREAU'},
        {label: 'Bureau privé', value: 'BUREAU PRIVE'},
        {label: 'Bureau virtuel', value: 'BUREAU VIRTUEL'},
        {label: 'Open space', value: 'OPEN SPACE'},
        {label: 'Surface', value: 'SURFACE'},
        {label: 'Restaurant', value: 'RESTAURANT'},
        {label: 'Hall', value: 'HALL'},
        {label: 'Salle de conference', value: 'SALLE CONFERENCE'},
        {label: 'Parking', value: 'PARKING'}
      ]
    },
    {
      label: 'Autre',
      type: [
        {label: 'Évènementielle', value: 'EVENEMENTIELLE'},
        {label: 'Salle de réunion', value: 'SALLE REUNION'}
      ]
    }
  ];
  etageRow = [
    { label: 'Rez de chaussée', value: 'Rez de chaussée' },
    { label: '1er étage', value: '1er étage' },
    { label: '2e étage', value: '2e étage' },
    { label: '3e étage', value: '3e étage' },
    { label: '4e étage', value: '4e étage' },
    { label: '5e étage', value: '5e étage' },
    { label: '6e étage', value: '6e étage' },
    { label: '7e étage', value: '7e étage' },
    { label: '8e étage', value: '8e étage' },
    { label: '9e étage', value: '9e étage' },
    { label: '10e étage', value: '10e étage' },
    { label: '11e étage', value: '11e étage' },
    { label: '12e étage', value: '12e étage' }
  ]

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private houseService: HouseService,
    private rentalService: RentalService,
    private uploadService: UploaderService,
    public toastr: ToastrService
  ) {
    this.edit = this.rentalService.edit;
    this.rental = this.rentalService.getRental();
    this.title = (!this.edit) ? 'Ajouter une locative' : 'Modifier la locative ' + this.rental?.libelle;
    this.newForm();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      ownerUuid: [null],
      folderUuid: [null],
      house: [null, [Validators.required]],
      montant: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      charge: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      total: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      porte: [null, [Validators.required]],
      etage: ['Rez de chaussée'],
      type: ['STUDIO', [Validators.required]],
      piece: [1, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      superficie: [null, [Validators.pattern(ValidatorsEnums.number)]],
      picture: [null],
      compteur: [null],
      files: this.formBuild.array([]),
      folders: this.formBuild.array([]),
    });
  }
  editForm() {
    if (this.edit) {
      const data = {...this.rentalService.getRental()};
      this.form.patchValue(data);
      this.f.folderUuid.setValue(data?.folder ? data?.folder?.uuid : null);
    }
  }
  onSubmit() {
    this.submit = true;
    this.emitter.loading();
    if (this.form.valid) {
      this.rentalService.add(this.form.value).subscribe(
        res => {
          if (res?.status === 'success') {
            this.modal.dismiss();
            this.modal.close('ferme');
            this.emitter.emit({action: this.edit ? 'RENTAL_UPDATED' : 'RENTAL_ADD', payload: res?.data});
          }
          this.emitter.stopLoading();
        },
        error => { });
    } else {
      this.toast('Votre formualire n\'est pas valide.', 'Attention !', 'warning');
      return;
    }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  setOwnerUuid(uuid) {
    this.f.ownerUuid.setValue(uuid);
    this.loadHouses();
  }
  loadHouses() {
    this.isLoadingHouse = true;
    if (!this.f.ownerUuid.value) {
      this.isLoadingHouse = false;
      this.houses = [];
      return;
    }
    this.houseService.getList(this.f.ownerUuid.value, 'LOCATION').subscribe(res => {
      this.isLoadingHouse = false;
      return this.houses = res;
    }, error => {
      this.isLoadingHouse = false;
    });
  }
  selectHouse(event) {
    this.house = this.houses.find(
      item => {
        if (item.uuid === event.target.value) { return item; }
      }
    );
    console.log(this.house);
    this.form.get('house').setValue(this.house ? this.house.uuid : null);
  }
  onChangeTotal() {
    if (this.f.montant.value >= 0 || this.f.charge.value >= 0) {
      if (this.f.montant.value >= 0 && this.f.charge.value < 0) {
        return this.f.total.setValue(this.f.montant.value);
      } else if (this.f.montant.value < 0 && this.f.charge.value >= 0) {
        return this.f.total.setValue(this.f.charge.value);
      } else if (this.f.montant.value >= 0 && this.f.charge.value >= 0) {
        return this.f.total.setValue(this.f.montant.value + this.f.charge.value);
      } else {
        return this.f.total.setValue(0);
      }
    }
  }
  loadfile(data) {
    if(data && data !== null){
      const file = data.todo.file
      this.file.push(
        this.formBuild.group({
          uniqId: [data.todo.uniqId, [Validators.required]],
          fileName: [file.name, [Validators.required]],
          fileSize: [file.size, [Validators.required]],
          fileType: [file.type, [Validators.required]],
          loaded: [data.todo.loaded, [Validators.required]],
          chunk: [data.chunk, [Validators.required]],
        })
      );
    }
  }
  files(data) {
    if(data && data !== null){
      data.forEach(item => {
        this.folder.push(
          this.formBuild.group({
            uuid: [item?.uuid, [Validators.required]],
            name: [item?.name],
            path: [item?.path]
          })
        );
      });
    }
  }
  upload(files) {
    for (const file of files) {
      this.uploadService.upload(file);
    }
  }
  setParam(property, value) {
    if (value) {
      if (this.form.value.hasOwnProperty(property)) {
        Object.assign(this.form.value, {[property]: value});
      }
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].setValue(value);
      }
    }
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      this.onClose()
    }
  }
  onClose(){
    if (!this.edit && this.form.value.folderUuid) {
      var data = {uuid: this.form.value.folderUuid, path: 'locative'}
      this.uploadService.getDelete(data).subscribe((res: any) => {
        if (res) {
          if (res?.status === 'success') {
            this.form.reset()
            this.modal.close('ferme');
          }
        }
        return res
      });
    }else{
      this.form.reset()
      this.modal.close('ferme');
    }
  }
  onReset(){
    if (this.form.value.folderUuid) {
      this.toast('Impossible de de vider le formulaire lorsque un upload a été éffectué', 'Une erreur a été rencontrée', 'warning');
    }else{
      this.form.reset()
      this.form.controls['folderUuid'].setValue(null);
    }
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls; }
  get file() { return this.form.get('files') as FormArray; }
  get folder() { return this.form.get('folders') as FormArray; }
}
